import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class Footer_v1 extends Component {

	componentDidMount() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);
	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		// let imgattr = "Footer logo"

		return (
			<footer className="footer-area" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/footer.png)', marginTop: 130 }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="footer-widget widget">
								<div className="about_us_widget">
									<Link to="/" className="footer-logo">
										<img src={publicUrl + "assets/img/logo.png"} alt="footer logo" />
									</Link>
									<p>{this.props.t('This is the official website of the Ministry of Tourism and Sports Thailand. The contents listed on this website are intended for informational purposes rather than commercial. And displayed sale is meant as a token of partnership and will always redirect you to our partners sites.')}</p>
									{/* <ul className="social-icon">
										<li>
											<a className="facebook" href="https://www.facebook.com/codingeek.net/" target="_blank"><i className="fa fa-facebook  " /></a>
										</li>
										<li>
											<a className="twitter" href="https://twitter.com/codingeeknet" target="_blank"><i className="fa fa-twitter  " /></a>
										</li>
										<li>
											<a className="pinterest" href="https://www.instagram.com/codingeeknet/" target="_blank"><i className="fa fa-instagram" /></a>
										</li>
									</ul> */}
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="footer-widget widget ">
								<div className="widget-contact">
									<h4 className="widget-title">{this.props.t('Contact us')}</h4>
									<p>
										<i className="fa fa-map-marker" />
										<span>{this.props.t('4 Ratchadamnoen nok Road, Watsomanas, Pom Prap Sattru Phai, Bangkok 10100')}</span>
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="footer-widget widget ">
								<div className="widget-contact">
									{/* <h4 className="widget-title">Contact us</h4>
									<p>
										<i className="fa fa-map-marker" />
										<span>เลขที่ 4 ถนนราชดำเนินนอก แขวงวัดโสมนัส เขตป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100</span>
									</p> */}
									{/* <p className="location">
										<i className="fa fa-envelope-o" />
										<span>travelpoint@gmail.com</span>
									</p>
									<p className="telephone">
										<i className="fa fa-phone base-color" />
										<span>
											+088 012121240
				              </span>
									</p> */}
								</div>
							</div>
						</div>
						{/* <div className="col-lg-2 col-md-6">
							<div className="footer-widget widget">
								<h4 className="widget-title">Quick Link</h4>
								<ul className="widget_nav_menu  viaje-go-top">
									<li><Link to="/">หน้าหลัก</Link></li>
									<li><Link to="/about">About Us</Link></li>
				            <li><Link to="/destination-list">Destination</Link></li>
				            <li><Link to="/tour-details">Tours</Link></li>
				            <li><Link to="/blog">Blog</Link></li>
				            <li><Link to="/contact">Contact</Link></li> 
								</ul>
							</div>
						</div>*/}
						{/* <div className="col-lg-3 col-md-6">
							<div className="footer-widget widget">
								<h4 className="widget-title">Instagram Gallery</h4>
								<ul className="widget-instagram-feed">
									<li><a href="/"><img src={publicUrl + "assets/img/instagram/1.png"} /></a></li>
									<li><a href="/"><img src={publicUrl + "assets/img/instagram/2.png"} /></a></li>
									<li><a href="/"><img src={publicUrl + "assets/img/instagram/3.png"} /></a></li>
									<li><a href="/"><img src={publicUrl + "assets/img/instagram/4.png"} /></a></li>
									<li><a href="/"><img src={publicUrl + "assets/img/instagram/5.png"} /></a></li>
									<li><a href="/"><img src={publicUrl + "assets/img/instagram/6.png"} /></a></li>
								</ul>
							</div>
						</div> */}
					</div>
				</div>
				<div className="copyright-inner">
					<div className="copyright-text">
						<i className="fa fa-copyright"></i> {this.props.t('Ministry Of Tourism & Sports')}
					</div>
				</div>
			</footer >


		)
	}
}


export default withTranslation()(Footer_v1);