import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';

class Searach extends Component {
	constructor(props) {
		super(props)
		this.state = {
			search: "",
			page: "1"
		}
	}

	handleChange = (event) => {
		if (event.target.value === "10") {
			document.getElementById('hide').style.visibility = 'hidden';
		} else {
			document.getElementById('hide').style.visibility = 'visible';
		}
		this.setState({ page: event.target.value });
		console.log(event.target.value)
	};

	render() {

		// let publicUrl = process.env.PUBLIC_URL + '/'
		// let imagealt = 'image'

		return <div className="search-area tp-main-search-area viaje-go-top">
			<div className="container">
				<div className="tp-main-search">
					<div className="row">
						<div className="col-xl-7 col-lg-6 col-md-6 order-8">
							<div id="hide" className="tp-search-single-wrap float-left">
								<input className="w-100" type="text" placeholder={this.props.t('Search for attractions based on your search terms')} onChange={(e) => { this.setState({ search: e.target.value }) }} />
								<i className="ti-location-pin" />
							</div>
						</div>

						<div className="col-xl-3 col-lg-3 col-md-6 order-9">
							<div className="tp-search-single-wrap float-left w-100">
								<select className="nice-select w-100" value={this.state.page} onChange={this.handleChange} >
									<option value="1">{this.props.t('Attraction')}</option>
									<option value="100">{this.props.t('Agricultural Attraction')}</option>
									<option value="4">{this.props.t('Accommodation')}</option>
									<option value='5'>{this.props.t('Spa')}</option>
									<option value='6'>{this.props.t('Restaurant')}</option>
									<option value='7'>{this.props.t('Stores')}</option>
									<option value='2'>{this.props.t('Thing to do')}</option>
									<option value='9'>{this.props.t('Tourist Routes')}</option>
									<option value='3'>{this.props.t('Tour Operators')}</option>
									<option value='10'>{this.props.t('Community-Based Tourism Routes')}</option>
								</select>
								<i className="fa fa-plus-circle" />
							</div>
						</div>
						{/* <div className="col-lg-3 col-md-4">
							<div className="tp-search-single-wrap">
								<input className="w-100" type="text" placeholder="กรอกคำค้นหาที่ท่านต้องการ" />
								<i className="fa fa-dot-circle-o" />
							</div>
						</div> */}
						{/* <div className="col-lg-2 col-md-4 order-lg-9">
							<div className="tp-search-single-wrap float-left w-100">
								<select className="select w-100">
									<option value={1}>เลือกจังหวัด</option>
									<option value={2}>Event Travel</option>
									<option value={3}>Weekend Break</option>
									<option value={4}>Package Holiday</option>
									<option value={5}>Business Travel</option>
								</select>
								<i className="fa fa-plus-circle" />
							</div>
						</div>
						<div className="col-lg-2 col-md-4 order-lg-9">
							<div className="tp-search-single-wrap float-left w-100">
								<select className="select w-100">
									<option value={1}>ประเภทของสถานที่</option>
									<option value={2}>Event Travel</option>
									<option value={3}>Weekend Break</option>
									<option value={4}>Package Holiday</option>
									<option value={5}>Business Travel</option>
								</select>
								<i className="fa fa-plus-circle" />
							</div>
						</div> */}
						{/* <div className="col-lg-2 col-md-8 order-lg-6">
							<div className="tp-search-single-wrap float-left">
								<div className="tp-search-date tp-departing-date-wrap w-50 float-left">
									<input type="text" className="departing-date" placeholder="Departing" />
									<i className="fa fa-calendar-minus-o" />
								</div>
								<div className="tp-search-date tp-returning-date-wrap w-50 float-left">
									<input type="text" className="returning-date" placeholder="Returning" />
									<img src={publicUrl + "assets/img/icons/2.png"} alt="icons" />
								</div>
							</div>
						</div> */}

						<div className="col-xl-2 col-lg-3 col-md-6 order-12 margin-left-165">
							<Link to="#" className="btn btn-yellow" onClick={() => { this.props.search(true, this.state.search, this.state.page) }}><i className="ti-search" /> {this.props.t('Search')}</Link>
						</div>
					</div>
				</div>
			</div>
		</div >

	}
}

export default withTranslation()(Searach);