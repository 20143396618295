import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LanguageDropdown from "../TopbarDropdown/LanguageDropdown";
import { withTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
// import * as loadingData from "../../loadingttd.json";
// import login from '../../assets/images/icon/logologin.svg'
import { api_endpoint } from '../../global-config'
import checktrue from "../../assets/images/icon/checktrue.svg"
// import email_image from "../../assets/images/icon/email-new.png"

// const defaultOptions = {
// 	loop: true,
// 	autoplay: true,
// 	animationData: loadingData.default,
// 	rendererSettings: {
// 		preserveAspectRatio: "xMidYMid slice"
// 	}
// };

class Navbar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			lang: this.props.i18n.language,
			modal_xlarge: false,
			modal_center: false,
			modal_xlarge_register: false,
			modal_center_register: false,
			username: '',
			password: '',
			username_register: "",
			password_register: "",
			ConfirmPassword_password_register: "",
			email_register: "",
			register_complete: false,
			login: false,
			changePassword: false,
			reset_username: ""

		}
		this.tog_xlarge = this.tog_xlarge.bind(this);
		this.tog_center = this.tog_center.bind(this);
		this.registermodal = this.registermodal.bind(this);
		this.register = this.register.bind(this);
		this.loginagain = this.loginagain.bind(this);
		this.changePasswordmodal = this.changePasswordmodal.bind(this);


		this.tog_xlarge_register = this.tog_xlarge_register.bind(this);
		this.tog_center_register = this.tog_center_register.bind(this);
		console.log(props)
	}
	// removeBodyCss() {
	// 	document.body.classList.add("no_padding");
	// }
	tog_xlarge() {
		this.setState(prevState => ({
			modal_xlarge: !prevState.modal_xlarge
		}));
		// this.removeBodyCss();
	}
	tog_center() {
		this.setState(prevState => ({
			modal_center: !prevState.modal_center
		}));
		// this.removeBodyCss();
	}
	tog_xlarge_register() {
		this.setState(prevState => ({
			modal_xlarge_register: !prevState.modal_xlarge_register
		}));
		// this.removeBodyCss();
	}
	tog_center_register() {
		this.setState(prevState => ({
			modal_center_register: !prevState.modal_center_register
		}));
		// this.removeBodyCss();
	}


	change_lang(value) {
		this.setState({
			lang: value
		})
	}

	changePasswordmodal() {
		console.log('Change Password')
		this.setState({
			changePassword: true
		})
	}
	registermodal() {
		console.log('register')
		this.setState({
			login: true
		})
	}

	loginagain() {
		console.log('loginagain')
		this.setState({
			login: false
		})
	}
	backtoLogin() {
		console.log('backtoLogin')
		this.setState({
			changePassword: false
		})
	}


	username_handle(e) {
		this.setState({
			username: e.target.value
		}, () => {
			console.log(this.state.username)
		})
	}

	password_handle(e) {
		this.setState({
			password: e.target.value
		}, () => {
			console.log(this.state.password)
		})
	}

	async login() {
		const response = await fetch(
			api_endpoint + `/auth/login`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					Username: this.state.username_register !== '' ? this.state.username_register : this.state.username,
					Password: this.state.password_register !== '' ? this.state.password_register : this.state.password,
				}), // body data type must match "Content-Type" header
			}
		);
		var _result = await response.json();
		console.log(_result)
		if (_result.status === 'ok') {
			localStorage.setItem("user_data", JSON.stringify(_result))
			console.log(localStorage.getItem('user_data'))
			this.setState({
				modal_xlarge: false,
				modal_center: false,
				username_register: '',
				password_register: ''
			})
			window.location.reload();
		}
		else {
			alert(_result.message)
			return
		}
	}

	async logout() {
		console.log(JSON.parse(localStorage.getItem("user_data")))
		const response = await fetch(
			api_endpoint + `/auth/logout`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + JSON.parse(localStorage.getItem("user_data")).token
				},
				body: JSON.stringify({}), // body data type must match "Content-Type" header
			}
		);
		var _result = await response.json();
		console.log(_result)
		if (_result.status === 'ok' && _result.success === true) {
			localStorage.removeItem("user_data")
			this.setState({
				modal_xlarge: false,
				modal_center: false,
			})
			window.location.replace("/")
			return
		}
		else {
			console.log('error')
			return
		}
	}

	register_handle(target, value) {
		this.setState({
			[target]: value
		})
	}

	async register() {
		const response = await fetch(
			api_endpoint + `/auth/register`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					Username: this.state.username_register,
					Password: this.state.password_register,
					UserType: "E",
					UserSubType: null,
					UserGroupID: null,
					DepartmentID: null,
					TitleName: "",
					FirstName: "",
					LastName: "",
					Email: this.state.email_register
				}), // body data type must match "Content-Type" header
			}
		);
		var _result = await response.json();
		if (_result.status === 'ok' && _result.success === true) {
			this.setState({
				register_complete: true
			})
		}
		else {
			alert('error')
		}
	}

	async reset_password() {
		if (this.state.reset_username === "") {
			alert('กรุณากรอกชื่อผู้ใช้งานก่อน')
			return
		}
		const response = await fetch(
			api_endpoint + `/auth/reset/password/request`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					username: this.state.reset_username
				}), // body data type must match "Content-Type" header
			}
		);
		var _result = await response.json();
		console.log(_result)
		// if (_result.status === 'ok') {
		// 	console.log(_result.status)
		// 	alert('กรุณารอรับการแจ้งรีเซ็ตรหัสผ่านทางอีเมล์')
		// 	// e.preventDefault()
		// 	// setTimeout(() => {
		// 	// 	alert('กรุณารอรับการแจ้งรีเซ็ตรหัสผ่านทางอีเมล์');
		// 	// 	window.location.reload(1);
		// 	// }, 100);
		// 	this.setState({
		// 		modal_xlarge: false,
		// 		modal_center: false,
		// 	})
		// } else {
		// 	console.log('error')
		// }
	}


	reset_password_handle(e) {
		this.setState({
			reset_username: e.target.value
		}, () => {
			console.log(this.state.reset_username)
		})
	}


	componentDidMount() {
		let user_data = localStorage.getItem('user_data')
		console.log(JSON.parse(user_data))
	}

	componentWillReceiveProps(nextProps) {
		console.log(nextProps)
		this.setState({
			modal_xlarge: nextProps.modal_control,
			modal_center: nextProps.modal_control,
		})
	}

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imgattr = 'logo'
		// let anchor = '#'
		return (
			<nav className="navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top">
				<div className="container nav-container">
					<div className="responsive-mobile-menu">
						<div className="mobile-logo">
							<Link hrefLang={this.state.lang} to={"/" + this.state.lang}>
								<img src={publicUrl + "assets/img/sticky-logo.png"} alt={imgattr} />
							</Link>
						</div>
						<button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#tp_main_menu" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggle-icon">
								<span className="line" />
								<span className="line" />
								<span className="line" />
							</span>
						</button>
						<div className="nav-right-content">
							{JSON.parse(localStorage.getItem('user_data')) === null ?
								<ul className="pl-0" style={{ marginTop: 7 }}>
									<LanguageDropdown />
									<li className="style-navbar-bf">
										<div className="signUp-btn" onClick={this.tog_xlarge}>
											<i className="fa fa-user-o" />
										</div>
									</li>
								</ul>
								:
								<ul className="mg-dropdown">
									<LanguageDropdown />
									<Link hrefLang={this.state.lang} to="/user">
										<div className="login-preview" >
											{/* <div id="" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/icons/img-profile.png)', marginTop: 5 }}>
												<img className="login-facebook" src={publicUrl + "assets/img/icons/img-facebook.png"} alt="facebook" />
											</div> */}
											<div style={{ textAlign: 'center' }}>
												{JSON.parse(localStorage.getItem('user_data')).FirstName === null ?
													<span style={{ fontSize: 18, padding: '1px 10px 1px 10px', backgroundColor: 'orange', borderRadius: 100 }}>{JSON.parse(localStorage.getItem('user_data')).Username.slice(0, 1).toUpperCase()}</span>
													:
													<span style={{ fontSize: 18, padding: '1px 10px 1px 10px', backgroundColor: 'orange', borderRadius: 100 }}>{JSON.parse(localStorage.getItem('user_data')).FirstName.slice(0, 1).toUpperCase()}</span>
												}
											</div>
										</div>
									</Link>
								</ul>
							}
						</div>
						{/* <div className="nav-right-content">
						<ul className="pl-0"> */}
						{/* <li className="top-bar-btn-booking">
									<Link className="btn btn-yellow" to="/tour-details">Book Now <i className="fa fa-paper-plane" /></Link>
								</li> */}
						{/* <li className="tp-lang">
									<div className="tp-lang-wrap"> */}
						{/* <select className="select single-select">
											<option value={1}>ENG</option>
											<option value={2}>BAN</option>
											<option value={3}>Chinese</option>
											<option value={3}>Spanish</option>
										</select> */}
						{/* </div>
								</li> */}
						{/* <li className="search">
									<i className="ti-search" />
								</li> */}
						{/* <LanguageDropdown />
									<li className="style-navbar-bf">
										<a className="signUp-btn" href="#" onClick={this.tog_xlarge}>
											<i className="fa fa-user-o" />
										</a>
									</li>
									:
									<div className="login-preview" >
												<div id="" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/icons/img-profile.png)', marginTop: 9 }}>
													<img className="login-facebook" src={publicUrl + "assets/img/icons/img-facebook.png"} alt="facebook" />
												</div>
											</div>
								</ul>														
						</div> */}
					</div>
					<div className="collapse navbar-collapse" id="tp_main_menu">
						<div className="logo-wrapper desktop-logo">
							<Link hrefLang={this.state.lang} to={"/" + this.state.lang} className="main-logo">
								<img src={publicUrl + "assets/img/logo.png"} alt="" />
							</Link>
							<Link hrefLang={this.state.lang} to={"/" + this.state.lang} className="sticky-logo">
								<img src={publicUrl + "assets/img/sticky-logo.png"} alt="" />
							</Link>
						</div>
						<ul className="navbar-nav">
							<li>
								<Link hrefLang={this.state.lang} to={"/" + this.state.lang}>{this.props.t('Home')}</Link>
							</li>
							{/* <li>
								<Link hrefLang={this.state.lang} to="/attractions" >{this.props.t('Attraction')}</Link>
							</li> */}
							<li className="menu-item-has-children">
								<Link hrefLang={this.state.lang} to="">{this.props.t('Attraction')}</Link>
								<ul className="sub-menu">
									<li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/attractions"}>{this.props.t('Attraction')}</Link></li>
									<li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/attractions/agriculture"}>{this.props.t('Agricultural Attraction')}</Link></li>
									<li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/accommodations"}>{this.props.t('Accommodation')}</Link></li>
									<li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/spas"}>{this.props.t('Spa')}</Link></li>
									<li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/restaurants"}>{this.props.t('Restaurant')}</Link></li>
									<li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/stores"}>{this.props.t('Stores')}</Link></li>
									{/* <li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/touroperators"}>{this.props.t('Tour Operators')}</Link></li> */}
									{/* <li><Link hrefLang={this.state.lang} to="/all-content-story">บทความ</Link></li>
									<li><Link hrefLang={this.state.lang} to="/all-content-page">บทความ1</Link></li> */}
								</ul>
							</li>
							<li>
								<Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/activities"}>{this.props.t('Thing to do')}</Link>
							</li>
							{/* <li className="menu-item-has-children">
								<Link hrefLang={this.state.lang} to="">{this.props.t('Tourist Routes')}</Link>
								<ul className="sub-menu">
									<li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/routes"}>{this.props.t('Tourist Routes')}</Link></li>
									<li><Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/community_routes"}>เส้นทางท่องเที่ยวโดยชุมชน</Link></li>
								</ul>
							</li> */}
							<li>
								<Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/routes"}>{this.props.t('Tourist Routes')}</Link>
							</li>
							{/* <li>
								<Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/touroperators"}>{this.props.t('Tour Operators')}</Link>
							</li> */}
							<li>
								<Link hrefLang={this.state.lang} to={"/" + this.state.lang + "/community_routes"}>{this.props.t('Community-Based Tourism Routes')}</Link>
							</li>
							{/* <li>
								<Link hrefLang={this.state.lang} to="/guide">{this.props.t('มัคคุเทศก์')}</Link>
							</li> */}
							{/* <ul>
								<LanguageDropdown />
							</ul> */}
						</ul>
					</div>
					<div className="nav-right-content">
						{JSON.parse(localStorage.getItem('user_data')) === null ?
							<ul className="style-flex-login-bf">
								<LanguageDropdown />
								<li className="style-navbar-bf mr-login" onClick={this.tog_xlarge}>
									<div className="icon-login-white">
										<img src={publicUrl + "assets/img/icons/icon-login-white.svg"} style={{ paddingRight: 10, height: '20px' }} alt="" /><span>{this.props.t('Sign Up')}</span> <span>/ {this.props.t('Login')}</span>
									</div>
									<div className="icon-login-black">
										<img src={publicUrl + "assets/img/icons/icon-login-black.svg"} style={{ paddingRight: 10, height: '20px' }} alt="" /><span>{this.props.t('Sign Up')}</span> <span>/ {this.props.t('Login')}</span>
									</div>
								</li>
							</ul>
							:
							<ul className="style-flex-login-af mg-dropdown">
								<LanguageDropdown />
								<div className="collapse navbar-collapse" id="tp_main_menu" style={{ paddingTop: 4 }}>
									<ul className="navbar-nav">
										<li className="menu-item-has-children style-navbar-af" >
											<div className="login-preview" >
												{/* <div id="" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/icons/img-profile.png)', marginTop: 9 }}>
													<img className="login-facebook" src={publicUrl + "assets/img/icons/img-facebook.png"} alt="facebook" />
												</div> */}
												<div >
													{JSON.parse(localStorage.getItem('user_data')).FirstName === null ?
														<div style={{ textAlign: 'center', fontSize: 20, width: 40, height: 40, backgroundColor: 'orange', borderRadius: 100 }}>
															<label style={{ position: 'relative', top: -4, left: 0.5 }}>{JSON.parse(localStorage.getItem('user_data')).Username.slice(0, 1).toUpperCase()}</label>
														</div>
														:
														<div style={{ textAlign: 'center', fontSize: 20, width: 40, height: 40, backgroundColor: 'orange', borderRadius: 100 }}>
															<label style={{ position: 'relative', top: -4, left: 0.5 }}>{JSON.parse(localStorage.getItem('user_data')).FirstName.slice(0, 1).toUpperCase()}</label>
														</div>
													}
												</div>
											</div>
											<div className="style-login-user">
												<span >
													{
														JSON.parse(localStorage.getItem('user_data')).FirstName === null ? JSON.parse(localStorage.getItem('user_data')).Username : JSON.parse(localStorage.getItem('user_data')).FirstName
													}
												</span>
												{/* <span style={{ marginLeft: 10 }}>
													{
														JSON.parse(localStorage.getItem('user_data')).FirstName
													}
												</span> */}
											</div>
											<ul className="sub-menu">
												<li><Link hrefLang={this.state.lang} to="/user">{this.props.t('Profile')}</Link></li>
												<li onClick={() => { this.logout() }}><Link to="#">{this.props.t('Logout')}</Link></li>
											</ul>
										</li>
									</ul>
								</div>
							</ul>
						}
					</div>
					{/* Login */}
					<Modal
						size="lg" style={{ maxWidth: '1600px', width: '80%', textAlign: '-webkit-center' }}
						className="modal-login"
						isOpen={this.state.modal_xlarge}
						toggle={this.tog_xlarge}
					>
						{this.state.register_complete === true ?
							<div className="modal-bg" style={{ textAlign: 'start' }}>
								<div className="login-flex-1">
									<div className="hidden-text">
										<p className="large-text-36" style={{ color: 'white' }}>Welcome To</p>
										<p className="large-text-36" style={{ color: 'white' }}>Thailand Digital Tourism</p>
										<p className="large-text" style={{ color: 'white' }}>มาตรฐานใหม่ข้อมูลการท่องเที่ยวของประเทศไทย</p>
									</div>
								</div>
								<div className="login-flex-2">
									<div className="card-register">
										<div style={{ justifySelf: 'center', paddingTop: 75 }}>
											<img src={checktrue} alt=""></img>
										</div>
										<div style={{ textAlign: 'center', paddingTop: 50 }}>
											<p style={{ color: '#41AB00', fontSize: 24, fontWeight: 'bold' }}>{this.props.t('Successfully Registered')}</p>
										</div>
										<div style={{ textAlign: 'center', paddingTop: 14 }}>
											<p className="normal-text-14" style={{ color: '#B9B9B9' }}>{this.props.t('The system will take you to the login page')} </p>
											<p className="normal-text-14" style={{ color: '#B9B9B9' }}>{this.props.t('Automatic, if there is no response, can be clicked from below.')}</p>
										</div>
										<div style={{ paddingTop: 75 }}>
											<hr></hr>
										</div>
										<div style={{ textAlign: 'center' }}>
											<p type='submit' onClick={() => {
												this.setState({
													register_complete: false,
												}, () => {
													this.login()
												})
											}} style={{ cursor: 'pointer' }}>{this.props.t('Go to the login page')}</p>
										</div>
										{/* <span className="card-login-text" >สมัครสมาชิก</span>
										<div className="style-card-login">
											<div className="form-group">
												<label className="normal-text-16" htmlFor="username" style={{ color: 'black' }}>เสร็จ</label>
											</div>
											<button type='submit' className="bt-register " onClick={() => {
												this.setState({
													register_complete: false,
													login: false
												})
											}}>กลับหน้าหลัก</button>
										</div> */}
									</div>
									<hr></hr>
								</div>
							</div>
							:
							<div>
								{this.state.login === true &&
									<div className="modal-bg" style={{ textAlign: 'start' }}>
										<div className="login-flex-1">
											<div className="hidden-text">
												<p className="large-text-36" style={{ color: 'white' }}>Welcome To</p>
												<p className="large-text-36" style={{ color: 'white' }}>Thailand Digital Tourism</p>
												<p className="large-text" style={{ color: 'white' }}>มาตรฐานใหม่ข้อมูลการท่องเที่ยวของประเทศไทย</p>
											</div>
										</div>
										<div className="login-flex-2">
											<div className="card-register" >
												<span className="card-login-text" >{this.props.t('Register')}</span>
												<div className="style-card-login">
													<form onSubmit={() => { this.register() }}>
														<div className="form-group">
															<label className="normal-text-16" htmlFor="username" style={{ color: 'black' }}>{this.props.t('Username')}</label>
															<input required type="input" className="form-control" id="username" aria-describedby="UsernameHelp" placeholder={this.props.t('Enter Username')} onChange={(e) => { this.register_handle('username_register', e.target.value) }}></input>
														</div>
														<div className="form-group">
															<label className="normal-text-16" htmlFor="email" style={{ color: 'black' }}>{this.props.t('Email')}</label>
															<input required type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder={this.props.t('Email')} onChange={(e) => { this.register_handle('email_register', e.target.value) }}></input>
														</div>
														<div className="form-group">
															<label className="normal-text-16" htmlFor="password " style={{ color: 'black' }}>{this.props.t('Password')}</label>
															<input required type="password" className="form-control" id="password" placeholder={this.props.t('Enter Password')} onChange={(e) => { this.register_handle('password_register', e.target.value) }}></input>
														</div>
														<div className="form-group">
															<label className="normal-text-16" htmlFor="ConfirmPassword" style={{ color: 'black' }}>{this.props.t('Confirm Password')}</label>
															{(this.state.ConfirmPassword_password_register === this.state.password_register) && this.state.ConfirmPassword_password_register !== "" &&
																<label className="normal-text-16" htmlFor="ConfirmPassword" style={{ color: 'green', marginLeft: 10 }}>
																	<span className="cl-black">(</span>{this.props.t('Correct')}<span className="cl-black">)</span></label>}
															{(this.state.ConfirmPassword_password_register !== this.state.password_register) && this.state.ConfirmPassword_password_register !== "" && <label className="normal-text-16" htmlFor="ConfirmPassword" style={{ color: 'red', marginLeft: 10 }}><span className="cl-black">(</span>{this.props.t('Incorrect')}<span className="cl-black">)</span></label>}
															<input required type="password" className="form-control" id="ConfirmPassword" placeholder={this.props.t('Confirm Enter Password')} onChange={(e) => { this.register_handle('ConfirmPassword_password_register', e.target.value) }}></input>
														</div>
														{/* <p className="alert-text">ไม่พบข้อมูลของคุณในระบบ</p> */}
														{(this.state.password_register === this.state.ConfirmPassword_password_register) && this.state.password_register !== "" && this.state.email_register !== "" && this.state.username_register !== "" ?
															<button type='submit' className="bt-register " >{this.props.t('Register')}</button>
															:
															<button disabled className="bt-register " style={{ backgroundColor: 'gray', borderColor: 'gray', color: 'white' }}>{this.props.t('Register')}</button>
														}
													</form>
													<div className="row" style={{ justifyContent: 'center', marginTop: 16 }}>
														<label className="normal-text" style={{ color: 'black' }}>{this.props.t('If you are already a member')} <span style={{ color: '#173865', cursor: 'pointer' }} onClick={() => { this.loginagain() }}>{this.props.t('Login')}</span> <span>{this.props.t('Here')}</span></label>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
								{(this.state.login === false && this.state.changePassword === false) &&
									<div className="modal-bg" style={{ textAlign: 'start' }}>
										<div className="login-flex-1">
											<div className="hidden-text">
												<p className="large-text-36" style={{ color: 'white' }}>Welcome To</p>
												<p className="large-text-36" style={{ color: 'white' }}>Thailand Digital Tourism</p>
												<p className="large-text" style={{ color: 'white' }}>มาตรฐานใหม่ข้อมูลการท่องเที่ยวของประเทศไทย</p>
											</div>
										</div>
										<div className="login-flex-2">
											<div className="card-login" >
												<span className="card-login-text" >{this.props.t('Login')}</span>
												<div className="style-card-login">
													<div className="form-group">
														<label className="normal-text-16" htmlFor="exampleInputEmail1" style={{ color: 'black' }}>{this.props.t('Username')}</label>
														<input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={this.props.t('Enter Username')} onChange={(e) => { this.username_handle(e) }}></input>
													</div>
													<div className="form-group">
														<label className="normal-text-16" htmlFor="exampleInputPassword1 " style={{ color: 'black' }}>{this.props.t('Password')}</label>
														<input type="password" className="form-control" id="exampleInputPassword1" placeholder={this.props.t('Enter Password')} onChange={(e) => { this.password_handle(e) }}></input>
													</div>
													<div className="form-check">
														<div className="row">
															<div className="col-lg-6 col-6">
																<input type="checkbox" className="form-check-input" id="exampleCheck1"></input>
																<label className="form-check-label normal-text-16" htmlFor="exampleCheck1" style={{ color: 'black' }}>{this.props.t('Remember my account')}</label>
															</div>
															<div className="col-lg-6 col-6" style={{ textAlign: 'right' }}>
																<label className="normal-text-16" style={{ color: 'black', cursor: 'pointer' }} onClick={() => { this.changePasswordmodal() }}>{this.props.t('Forgot your password ?')}</label>
															</div>
														</div>
													</div>
													{/* <p className="alert-text">ไม่พบข้อมูลของคุณในระบบ</p> */}
													<button className="bt-login normal-text" type="submit" style={{ marginTop: 60 }} onClick={() => { this.login() }}>{this.props.t('Login')}</button>
													{/* <div className="border-left-right" style={{ marginTop: 12 }}>
														<span className="normal-text-16" >{this.props.t('Login with')}</span>
													</div>
													<div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
														<img src={publicUrl + "assets/img/icons/Facebook.png"} alt="Facebook" />
														<img style={{ paddingLeft: 12 }} src={publicUrl + "assets/img/icons/Google.png"} alt="Google" />
													</div> */}
													<div className="row" style={{ justifyContent: 'center', marginTop: 16, textAlign: 'center' }}>
														<label className="normal-text text-align" style={{ color: 'black' }}>{this.props.t('If you are not a member yet')} <span style={{ color: '#FFBF00', cursor: 'pointer' }} onClick={() => { this.registermodal() }}>{this.props.t('Register')}</span> <span>{this.props.t('Here')}</span></label>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
								{this.state.changePassword === true &&
									// <div className="modal-bg" style={{ textAlign: 'start' }}>
									// 	<div className="login-flex-1">
									// 		<div className="hidden-text">
									// 			<p className="large-text-36" style={{ color: 'white' }}>Welcome To</p>
									// 			<p className="large-text-36" style={{ color: 'white' }}>Thailand Digital Tourism</p>
									// 			<p className="large-text" style={{ color: 'white' }}>มาตรฐานใหม่ข้อมูลการท่องเที่ยวของประเทศไทย</p>
									// 		</div>
									// 	</div>
									// 	<div className="login-flex-2">
									// 		<div className="card-checktrue-email">
									// 			<div style={{ justifySelf: 'center', paddingTop: 75 }}>
									// 				<img src={email_image} alt=""></img>
									// 			</div>
									// 			<div style={{ textAlign: 'center', paddingTop: 50 }}>
									// 				<p style={{ color: '#41AB00', fontSize: 24, fontWeight: 'bold' }}>{this.props.t('ส่งคำร้องในการเปลี่ยนรหัสผ่านสำเร็จแล้ว')}</p>
									// 			</div>
									// 			<div style={{ textAlign: 'center', paddingTop: 14 }}>
									// 				<p className="normal-text-14" style={{ color: '#B9B9B9' }}>{this.props.t('หากไม่พบอีเมลในกล่องจดหมายเข้ากรุณา')} </p>
									// 				<p className="normal-text-14" style={{ color: '#B9B9B9' }}>{this.props.t('ตรวจสอบโฟลเดอร์ต่างๆ ของคุณ')}</p>
									// 			</div>
									// 			<div style={{ paddingTop: 75 }}>
									// 				<hr></hr>
									// 			</div>
									// 			<div style={{ textAlign: 'center' }}>
									// 				<p className="normal-text-16 cl-black" style={{ cursor: 'pointer' }}>{this.props.t('Go to the login page')}</p>
									// 			</div>
									// 		</div>
									// 	</div>
									// </div>
									<div className="modal-bg" style={{ textAlign: 'start' }}>
										<div className="login-flex-1">
											<div className="hidden-text">
												<p className="large-text-36" style={{ color: 'white' }}>Welcome To</p>
												<p className="large-text-36" style={{ color: 'white' }}>Thailand Digital Tourism</p>
												<p className="large-text" style={{ color: 'white' }}>มาตรฐานใหม่ข้อมูลการท่องเที่ยวของประเทศไทย</p>
											</div>
										</div>
										<div className="login-flex-2">
											<div className="card-register" >
												<span className="card-login-text" >{this.props.t('เปลี่ยนรหัสผ่าน')}</span>
												<span className="normal-text-16 text-center" >{this.props.t('กรอกชื่อผู้ใช้งานของคุณเพื่อใช้ในการเปลี่ยนรหัสผ่านในการเข้าสู่ระบบ')}</span>
												<div className="style-card-login">
													<div className="form-group" style={{ marginTop: 10, marginBottom: '10rem' }}>
														<label className="normal-text-16" htmlFor="username" style={{ color: 'black' }}>{this.props.t('Username')}</label>
														<input required type="input" className="form-control" placeholder={this.props.t('Enter Your Username')} value={this.state.reset_username} onChange={(e) => { this.reset_password_handle(e) }}></input>
													</div>
													<button className="bt-login normal-text" onClick={() => { this.reset_password() }}>{this.props.t('ยืนยัน')}</button>
													<div className="row" style={{ justifyContent: 'center', marginTop: 16 }}>
														<label className="normal-text" style={{ color: 'black' }}>{this.props.t('If you are already a member')} <span style={{ color: '#173865', cursor: 'pointer' }} onClick={() => { this.backtoLogin() }}>{this.props.t('Login')}</span> <span>{this.props.t('Here')}</span></label>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
							</div>
						}
					</Modal>
				</div>
			</nav >
			// 	<div className="col-lg-6" style={{ marginTop: '20rem', marginLeft: '5rem' }}>
			// 	<div>
			// 		<p className="large-text-36" style={{ color: 'white' }}>Welcome To</p>
			// 		<p className="large-text-36" style={{ color: 'white' }}>Thailand Digital Tourism</p>
			// 		<p className="large-text" style={{ color: 'white' }}>มาตรฐานใหม่ข้อมูลการท่องเที่ยวของประเทศไทย</p>
			// 	</div>
			// </div>
			// <div className="col-lg-4" style={{ alignSelf: 'flex-end' }}>
			// 	<div className="row" style={{ justifyContent: 'center', alignContent: 'center' }}>
			// 		<Card style={{ marginTop: '10rem', width: '100%', marginBottom: '10rem' }}>
			// 			<CardBody>
			// 				<div className="row" style={{ justifyContent: 'center' }}>
			// 					<label className="normal-text" style={{ fontSize: 24, color: 'black' }}>เข้าสู่ระบบ</label>
			// 				</div>
			// 				<div className="form-group">
			// 					<label htmlFor="exampleInputEmail1" style={{ color: 'black' }}>Username</label>
			// 					<input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => { this.username_handle(e) }}></input>
			// 				</div>
			// 				<div className="form-group">
			// 					<label htmlFor="exampleInputPassword1" style={{ color: 'black' }}>Password</label>
			// 					<input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" onChange={(e) => { this.password_handle(e) }}></input>
			// 				</div>
			// <div className="form-check">
			// 	<div className="row">
			// 		<div className="col-lg-6">
			// 			<input type="checkbox" className="form-check-input" id="exampleCheck1"></input>
			// 			<label className="form-check-label" htmlFor="exampleCheck1" style={{ color: 'black' }}>จดจำชื่อฉัน</label>
			// 		</div>
			// 		<div className="col-lg-6" style={{ textAlign: 'right' }}>
			// 			<label >ลืมรหัสผ่าน ?</label>
			// 		</div>
			// 	</div>
			// </div>
			// 				<button className="bt-login normal-text" onClick={() => { this.login() }}>เข้าสู่ระบบ</button>
			// <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
			// 	<div className="col-lg-3">
			// 		<hr style={{ borderTop: '2px solid #E5E5E5', width: 170 }}></hr>
			// 	</div>
			// 	<div className="col-lg-6 normal-text-16" style={{ textAlign: 'center' }}>
			// 		เข้าสู่ระบบด้วย
			// </div>
			// 	<div className="col-lg-3" style={{ left: -73 }}>
			// 		<hr style={{ borderTop: '2px solid #E5E5E5', width: 170 }}></hr>
			// 	</div>
			// </div>
			// <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
			// 	<img src={publicUrl + "assets/img/icons/Facebook.png"} alt="Facebook" />
			// 	<img style={{ paddingLeft: 12 }} src={publicUrl + "assets/img/icons/Google.png"} alt="Google" />
			// </div>
			// <div className="row" style={{ justifyContent: 'center', marginTop: 16 }}>
			// 	<label className="normal-text" style={{ color: 'black' }}>หากคุณยังไม่ได้เป็นสมาชิก <span style={{ color: '#FFBF00' }}>สมัครสมาชิก</span> ได้ที่นี่</label>
			// </div>
			// 			</CardBody>
			// 		</Card>
			// 	</div>
			// </div>
			// <nav className="navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top">

			// 	<div className="container nav-container">
			// 		<div className="responsive-mobile-menu" style={{ margin: 0 }}>
			// 			<div className="mobile-logo">
			// 				<Link to="/">
			// 					<img src={publicUrl + "assets/img/sticky-logo.png"} alt={imgattr} />
			// 				</Link>
			// 			</div>
			// 			<button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#tp_main_menu" aria-expanded="false" aria-label="Toggle navigation">
			// 				<span className="navbar-toggle-icon">
			// 					<span className="line" />
			// 					<span className="line" />
			// 					<span className="line" />
			// 				</span>
			// 			</button>

			// 		</div>
			// 		<div className="collapse navbar-collapse" id="tp_main_menu" >
			// 			<div className="logo-wrapper desktop-logo" style={{ margin: 0 }}>
			// 				<Link to="/" className="main-logo">
			// 					<img src={publicUrl + "assets/img/logo.png"} />
			// 				</Link>
			// 				<Link to="/" className="sticky-logo">
			// 					<img src={publicUrl + "assets/img/sticky-logo.png"} />
			// 				</Link>
			// 			</div>
			// 			<ul className="navbar-nav" style={{ marginLeft: 40 }} >
			// 				<li>
			// 					<Link to="/">{this.props.t('Main page')}</Link>
			// 				</li>
			// 				<li className="menu-item-has-children">
			// 					<Link to="">สถานที่ท่องเที่ยว</Link>
			// 					<ul className="sub-menu">
			// 						<li><Link to="/places">{this.props.t('tourism location')}</Link></li>
			// 						<li><Link to="/accommodations">{this.props.t('accommodation')}</Link></li>
			// 						<li><Link to="/spas">{this.props.t('Spa')}</Link></li>
			// 						<li><Link to="/restaurants">{this.props.t('Restaurant')}</Link></li>
			// 						<li><Link to="/stores">{this.props.t('Souvenir shop')}</Link></li>
			// 					</ul>
			// 				</li>
			// 				<li>
			// 					<Link to="/activitys">{this.props.t('Thing to do')}</Link>
			// 				</li>
			// 				<li>
			// 					<Link to="/travel">{this.props.t('Travel Routes')}</Link>
			// 				</li>
			// 				<li>
			// 					<Link to="/touroperator">{this.props.t('Travel Agency')}</Link>
			// 				</li>
			// 			</ul>
			// 		</div>
			// 	</div>


			// 	<div className="nav-right-content" style={{ padding: 0 }}>
			// 		<div className="row" style={{ marginRight: 18 }}>

			// 			<LanguageDropdown />
			// 			<div style={{ padding: 15, color: '#FFBF00', borderRight: '2px solid #FFBF00' }}></div>
			// 			<span className="align-middle" style={{ color: '#FFBF00', marginLeft: 10, cursor: 'pointer', alignSelf: 'center', paddingLeft: 10, paddingBottom: 2 }} onClick={this.tog_xlarge}>
			// 				เข้าสู่ระบบ
			// 			</span>
			// 		</div>
			// 	</div>

			// 	<Modal
			// 		size="lg" style={{ maxWidth: '1600px', width: '80%' }}
			// 		// className="special_modal"
			// 		// size="lg"
			// 		isOpen={this.state.modal_xlarge}
			// 		toggle={this.tog_xlarge}
			// 	>
			// 		<div className="modal-bg" >
			// 			<div className="row">
			// 				<div className="col-lg-6" style={{ marginTop: '20rem', marginLeft: '5rem' }}>
			// 					<div>
			// 						<p className="large-text" style={{ color: 'white' }}>Welcome To</p>
			// 						<p className="large-text" style={{ color: 'white' }}>Thailand Digital Tourism</p>
			// 						<p className="normal-text" style={{ fontSize: 24, color: 'white' }}>มาตรฐานใหม่ข้อมูลการท่องเที่ยวของประเทศไทย</p>
			// 					</div>
			// 				</div>
			// 				<div className="col-lg-4" style={{ alignSelf: 'flex-end' }}>
			// 					<div className="row" style={{ justifyContent: 'center', alignContent: 'center' }}>
			// 						<Card style={{ marginTop: '10rem', width: '100%', marginBottom: '10rem' }}>
			// 							<CardBody>
			// 								<div className="row" style={{ justifyContent: 'center' }}>
			// 									<label className="normal-text" style={{ fontSize: 24, color: 'black' }}>เข้าสู่ระบบ</label>
			// 								</div>
			// 								<div className="form-group">
			// 									<label htmlFor="exampleInputEmail1" style={{ color: 'black' }}>Username</label>
			// 									<input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"></input>
			// 								</div>
			// 								<div className="form-group">
			// 									<label htmlFor="exampleInputPassword1" style={{ color: 'black' }}>Password</label>
			// 									<input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"></input>
			// 								</div>
			// 								<div className="form-check">
			// 									<div className="row">
			// 										<div className="col-lg-6">
			// 											<input type="checkbox" className="form-check-input" id="exampleCheck1"></input>
			// 											<label className="form-check-label" htmlFor="exampleCheck1" style={{ color: 'black' }}>จดจำชื่อฉัน</label>
			// 										</div>
			// 										<div className="col-lg-6" style={{ textAlign: 'right' }}>
			// 											<label >ลืมรหัสผ่าน ?</label>
			// 										</div>
			// 									</div>
			// 								</div>
			// 								<button className="bt-login normal-text">เข้าสู่ระบบ</button>
			// 								<div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
			// 									<div className="col-lg-3">
			// 										<hr style={{ borderTop: '5px solid #E5E5E5' }}></hr>
			// 									</div>
			// 									<div className="col-lg-6" style={{ textAlign: 'center' }}>
			// 										เข้าสู่ระบบด้วย
			// 									</div>
			// 									<div className="col-lg-3">
			// 										<hr style={{ borderTop: '5px solid #E5E5E5' }}></hr>
			// 									</div>
			// 								</div>
			// 								<div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
			// 									<img src={publicUrl + "assets/img/icons/Facebook.png"} alt="Facebook" />
			// 									<img style={{ paddingLeft: 12 }} src={publicUrl + "assets/img/icons/Google.png"} alt="Google" />
			// 								</div>
			// 								<div className="row" style={{ justifyContent: 'center', marginTop: 16 }}>
			// 									<label className="normal-text" style={{ color: 'black' }}>หากคุณยังไม่ได้เป็นสมาชิก <span style={{ color: '#FFBF00' }}>สมัครสมาชิก</span> ได้ที่นี่</label>
			// 								</div>
			// 							</CardBody>
			// 						</Card>
			// 					</div>
			// 				</div>

			// 			</div>
			// 		</div>

			// 	</Modal>
			// </nav>

		)

	}
}
export default withTranslation()(Navbar);




