import React, { Component, } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';

class ModalComment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lang: this.props.i18n.language,
            modal_comment: false,
        }
        this.toggle_modal = this.toggle_modal.bind(this);
    }
    toggle_modal() {
        this.setState(prevState => ({
            modal_comment: !prevState.modal_comment
        }));
        // this.removeBodyCss();
    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        this.setState({
            modal_comment: nextProps.modal_comment,
        })
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <Modal
                size="lg" style={{ maxWidth: '1600px', width: '100%', textAlign: '-webkit-center' }}
                className="modal-comment"
                isOpen={this.state.modal_comment}
                toggle={this.toggle_modal}
            >
                <div className="modal-style-comment">
                    <div style={{ justifySelf: 'center', paddingTop: 50 }}>
                        <img src={publicUrl + "assets/img/thank.svg"} style={{ width: 150, height: 150 }} alt=""></img>
                    </div>
                    <div style={{ textAlign: 'center', paddingTop: 40 }}>
                        <p className="large-text cl-black">{this.props.t('Thank you for commenting.')}</p>
                        <p className="large-text-16 cl-gray">{this.props.t('Your comments will appear soon.')}</p>
                    </div>
                </div>

            </Modal>
        )

    }
}

export default withTranslation()(ModalComment);