import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
// import { api_endpoint } from '../../global-config'
// import * as loadingData from "./loandtdd.json";
// import Lottie from "react-lottie";
// import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';


class tourist extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}

	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		// let imagealt = 'image'

		return <div className="offer-area pd-top-70 bg-tourist">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8 col-lg-8">
						<div className="section-title text-center">
							<h2 className="title large-text-48 cl-black">{this.props.t('Hilight Visit our popular attractions')}</h2>
							<p className="content large-text light-gray">{this.props.t('Waiting for you to experience the real')}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="destinations-list-slider-bg">
				<div className="container">
					<div className="row">
						<div className="col-xl-9 col-lg-10 offset-xl-1 order-lg-12">
							<div className="destinations-list-slider">
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/attraction/" + this.props.place[0].ID, params: this.props.place[0] !== undefined ? this.props.place[0].ID : null }}>
									<div className="d-list-slider-item" >
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<img className="province-img" src={this.props.place.length < 1 ? null : this.props.place[0].IntroImage} alt="list" />
											</div>
											<div className="details height-details">
												<h4 className="title normal-text-18">{this.props.place.length < 1 ? null : this.props.place[0].Name[this.props.i18n.language]}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.place.length < 1 ? null : this.props.place[0].Province[this.props.i18n.language]}</span></p>
												{this.props.i18n.language === 'zh' ?
													<p className="content" style={{ textAlign: 'left' }}>{this.props.place.length < 1 ? null : this.props.place[0].Detail[this.props.i18n.language].slice(0, 60) + "..."}</p>
													:
													<p className="content" style={{ textAlign: 'left' }}>{this.props.place.length < 1 ? null : this.props.place[0].Detail[this.props.i18n.language].slice(0, 100) + "..."}</p>
												}
											</div>
										</div>
									</div>
								</Link>
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/attraction/" + this.props.place[1].ID, params: this.props.place[1] !== undefined ? this.props.place[1].ID : null }}>
									<div className="d-list-slider-item">
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<img className="province-img" src={this.props.place.length < 1 ? null : this.props.place[1].IntroImage} alt="list" />
											</div>
											<div className="details height-details">
												<h4 className="title normal-text-18">{this.props.place.length < 1 ? null : this.props.place[1].Name[this.props.i18n.language]}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.place.length < 1 ? null : this.props.place[1].Province[this.props.i18n.language]}</span></p>
												{this.props.i18n.language === 'zh' ?
													<p className="content" style={{ textAlign: 'left' }}>{this.props.place.length < 1 ? null : this.props.place[1].Detail[this.props.i18n.language].slice(0, 60) + "..."}</p>
													:
													<p className="content" style={{ textAlign: 'left' }}>{this.props.place.length < 1 ? null : this.props.place[1].Detail[this.props.i18n.language].slice(0, 100) + "..."}</p>
												}
											</div>
										</div>
									</div>
								</Link>
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/attraction/" + this.props.place[2].ID, params: this.props.place[2] !== undefined ? this.props.place[2].ID : null }}>
									<div className="d-list-slider-item">
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<img className="province-img" src={this.props.place.length < 1 ? null : this.props.place[2].IntroImage} alt="list" />
											</div>
											<div className="details height-details">
												<h4 className="title normal-text-18">{this.props.place.length < 1 ? null : this.props.place[2].Name[this.props.i18n.language].slice(0, 20) + "..."}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.place.length < 1 ? null : this.props.place[2].Province[this.props.i18n.language]}</span></p>
												{this.props.i18n.language === 'zh' ?
													<p className="content" style={{ textAlign: 'left' }}>{this.props.place.length < 1 ? null : this.props.place[2].Detail[this.props.i18n.language].slice(0, 60) + "..."}</p>
													:
													<p className="content" style={{ textAlign: 'left' }}>{this.props.place.length < 1 ? null : this.props.place[2].Detail[this.props.i18n.language].slice(0, 80) + "..."}</p>
												}
											</div>
										</div>
									</div>
								</Link>
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/attraction/" + this.props.place[3].ID, params: this.props.place[3] !== undefined ? this.props.place[3].ID : null }}>
									<div className="d-list-slider-item">
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<img className="province-img" src={this.props.place.length < 1 ? null : this.props.place[3].IntroImage} alt="list" />
											</div>
											<div className="details height-details">
												<h4 className="title normal-text-18">{this.props.place.length < 1 ? null : this.props.place[3].Name[this.props.i18n.language].slice(0, 30) + "..."}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.place.length < 1 ? null : this.props.place[3].Province[this.props.i18n.language]}</span></p>
												{this.props.i18n.language === 'zh' ?
													<p className="content" style={{ textAlign: 'left' }}>{this.props.place.length < 1 ? null : this.props.place[3].Detail[this.props.i18n.language].slice(0, 60) + "..."}</p>
													:
													<p className="content" style={{ textAlign: 'left' }}>{this.props.place.length < 1 ? null : this.props.place[3].Detail[this.props.i18n.language].slice(0, 100) + "..."}</p>
												}
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						<div className="col-lg-2 align-self-center order-lg-11">
							<div className="container">
								<div className="destinations-slider-controls">
									<div className="slider-nav tp-control-nav" />
									{/*slider-nav*/}
									<div className="tp-slider-extra slider-extra">
										<div className="text">
											<span className="first">01 </span>
											<span className="devider">/</span>
											<span className="last" />
										</div>
										{/*text*/}
										<div className="d-list-progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
											<span className="slider__label sr-only" />
										</div>
									</div>
									{/*slider-extra*/}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*<div className="d-list-slider-item">
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<span className="d-list-tag">Special Offer</span> 
												<img className="province-img" src={this.props.place.length < 1 ? null : this.props.place[3].IntroImage} alt="list" />
												 <div className="d-list-btn-wrap">
													<div className="d-list-btn viaje-go-top">
														<Link className="btn btn-yellow" to="/contact">Book Now <i className="fa fa-paper-plane" /></Link>
													</div>
												</div> 
											</div>
											<div className="details">
												<h4 className="title normal-text-18" style={{ color: 'black', fontSize: 18 }}>{this.props.place.length < 1 ? null : this.props.place[3].Name.th.slice(0, 30) + "..."}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.place.length < 1 ? null : this.props.place[3].Province.th}</span></p>
												<p className="content">Atmosphere of the sunny country</p>
												 <ul className="tp-list-meta border-bt-dot">
													<li><i className="fa fa-calendar-o" /> 8oct</li>
													<li><i className="fa fa-clock-o" /> 4 days</li>
													<li><i className="fa fa-star" /> 4.3</li>
												</ul>
												<div className="tp-price-meta tp-price-meta-cl">
													<p>Price</p>
													<h2>620 <small>$</small></h2>
													<del>620<span>$</span></del>
												</div>
											</div>
										</div>
									</div> */}
		</div >



	}
}

export default withTranslation()(tourist);