import React, { Component } from 'react';

class Ads extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		const path = "www.thailandmedicalhub.net"

		return <div className="ads-area pd-top-90 viaje-go-top">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8 col-lg-9">
						<a href='!#' onClick={() => { window.open(`https://${path}`) }} className="ads-thumb" >
							<img src={publicUrl + "assets/img/banner/ads.png"} alt="ads" style={{ cursor: 'pointer' }} />
						</a>
					</div>
				</div>
			</div>
		</div>

	}
}

export default Ads