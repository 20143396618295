import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import PageHeaderTravelRoute from './global-components/page-header-travel-route';
import Footer from './global-components/footer';
import { api_endpoint } from '../global-config'
import { withTranslation } from 'react-i18next';
import '../components/cssnew.css'
import './cssnew.css'
import FadeIn from "react-fade-in";
import "react-image-gallery/styles/css/image-gallery.css";
import * as loadingData from "../loadingttd.json";
import Lottie from "react-lottie";
import { GoogleApiWrapper } from "google-maps-react";
import ReactGA from 'react-ga4';
// import { Helmet } from "react-helmet";
import ImageGallery from 'react-image-gallery';
import clock from "../assets/images/icon/bi_clock.svg"
import vecterlocation from '../assets/images/icon/location.svg'
import locations from "../assets/images/icon/mark.svg"
import phone from "../assets/images/icon/telephone.svg"
import website from "../assets/images/icon/website.svg"
import ic_detail from "../assets/images/icon/ic_detail_activity.png"

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
class TravelRouteAttractionDetailsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            icon_facility: [
                { id: 1, text: this.props.t('Fan'), icon: "assets/img/icons/fan.png" },
                { id: 2, text: this.props.t('Toilet'), icon: "assets/img/icons/toilet.png" },
                { id: 3, text: this.props.t('Elderly Toilet'), icon: "assets/img/icons/eldtoilet.png" },
                { id: 4, text: this.props.t('Wheelchair Service'), icon: "assets/img/icons/wheelchair.png" },
                { id: 5, text: this.props.t('Parking Lot'), icon: "assets/img/icons/carpark.png" },
                { id: 6, text: this.props.t('Smoking Area'), icon: "assets/img/icons/smorkarea.png" },
                { id: 7, text: this.props.t('Pets Allowed'), icon: "assets/img/icons/pet.png" },
                { id: 8, text: this.props.t('Internet Wifi'), icon: "assets/img/icons/toilet.png" },
                { id: 9, text: this.props.t('ATM'), icon: "assets/img/icons/atm.png" },
            ],
            icon_style: "",
            attractionId: props.match.params.id,
            place_details: {},
            original: [],
            isLoading: true,
            lang: this.props.i18n.language,
            modal_control: false,
            matches: window.matchMedia("(min-width: 992px)").matches,
            isLogin: localStorage.getItem('user_data'),
        }
        console.log(this.props)
    }

    modal_control() {
        this.setState({
            modal_control: true
        }, () => {
            console.log(this.state.modal_control)
            return this.state.modal_control
        })
    }

    login_check() {
        this.setState({
            isLogin: localStorage.getItem("user_data")
        })
    }

    //เรียกข้อมูลสถานที่
    async get_place_detail() {
        const response = await fetch(
            api_endpoint + `/cbt/attraction_getdetails`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    attractionId: this.state.attractionId,
                    language: this.state.lang
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        let arr = []
        console.log(_result.result.image)
        if (_result.result.image === "" || _result.result.image === null) {
            arr.push({ original: "https://thailandtourismdirectory.go.th/assets/upload/icons/no-image.jpg", originalAlt: "" })
        } else {
            _result.result.image.map((e, i) => {
                return arr.push({ original: e, originalAlt: "" })
            })
        }

        this.setState({
            place_details: _result.result,
            original: arr,
            isLoading: false,
        })
    }

    componentDidMount() {
        if (this.state.attractionId === undefined) {
            this.props.history.goBack()
        }
        else {
            const language = localStorage.getItem("I18N_LANGUAGE")
            if (!language) {
                localStorage.setItem("I18N_LANGUAGE", "th")
            }
            this.get_place_detail()
            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        }
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        this.setState({
            isLoading: true,
            lang: nextProps.i18n.language
        }, () => {
            this.get_place_detail()
            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        })
    }

    render() {
        if (this.state.isLoading === true) {
            return (
                <div style={{ marginTop: 200 }} className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div className="card">
                                <div className="cardbody">
                                    <Lottie options={defaultOptions} height={300} width={300} />
                                    <div>
                                        <h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            )
        }
        else {
            let publicUrl = process.env.PUBLIC_URL + '/'
            // console.log(this.state.place_details)
            const images = this.state.original;
            const timePeriod = this.state.place_details.information.timeperiod
            const fee = this.state.place_details.information.fee
            const landmark = this.state.place_details.information.landmark
            return (
                <div>
                    <div className="menu-back-page animated fadeIn">
                        <span className="back-top">
                            <a hrefLang={this.state.lang} href={"/" + this.state.lang + "/community_routes"} className="normal-text-18-detail"><i className="fa fa-angle-left" style={{ fontSize: 22 }}><span className="large-text-20" style={{ padding: "0px 0px 0px 10px" }}>{this.props.t('Back')}</span></i></a>
                        </span>
                    </div>
                    <Navbar modal_control={this.state.modal_control} login_check={this.login_check} />
                    <PageHeaderTravelRoute headertitle={this.state.place_details.nameOfAttraction} />
                    <div>
                        {/* แถบตอนย่อจอ */}
                        {!this.state.matches && (
                            <div style={{ padding: 20 }}>
                                <div>
                                    <ImageGallery items={images}
                                        showThumbnails={false}
                                        infinite={true}
                                        showIndex={true}
                                    />
                                </div>
                                {/* ชื่อสถานที่และที่ตั้ง */}
                                <div style={{ marginTop: 26 }}>
                                    <p className="large-text-36 cl-black dp">{this.state.place_details.nameOfAttraction}</p>
                                    <div className="row" style={{ margin: 0, marginTop: 10 }}>
                                        <div className="pd-r-15" style={{ display: 'flex' }}><img src={vecterlocation} style={{ paddingRight: 10, height: '25px' }} alt="" /><p className="normal-text-18-detail cl-blue">{this.props.t('SubDistrict')}: {this.state.place_details.location.subDistrict}</p></div>
                                        <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.props.t('District')}: {this.state.place_details.location.district}</p></div>
                                        <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.props.t('Province')}: {this.state.place_details.location.province}</p></div>
                                        <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.state.place_details.location.postCode}</p></div>
                                    </div>
                                </div>
                                <div className="card card-color" style={{ borderRadius: 15 }}>
                                    <div className="card-body">
                                        <div className="pd-bt-30" style={{ marginTop: 64 }}>
                                            <h5 className="mg-top-card open-text cl-green"><img src={clock} style={{ paddingRight: 10, height: '30px' }} alt="" /><span data-nosnippet>{this.props.t('Opening hours')}</span></h5>
                                        </div>
                                        {/* วันเวลาที่ทำการ */}
                                        {this.state.place_details.openday === undefined ?
                                            <div></div>
                                            :
                                            <div>
                                                {this.state.place_details.openday.map((e, i) => {
                                                    console.log(e)
                                                    return (
                                                        <div key={i} className="row" style={{ width: "100%" }}>
                                                            <div className="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5">
                                                                <div className="normal-text-day pd-bt-15 " > <span className="card-title" >{e.day}</span></div>
                                                            </div>
                                                            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-7 col-7">
                                                                <div className="row" style={{ justifyContent: 'flex-end' }}>
                                                                    <div className="normal-text-day pd-bt-15" ><span className="card-title" >{this.state.lang === 'th' ? e.opentime + ' น.' : e.opentime + ' AM'} - </span></div>
                                                                    <div className="normal-text-day" style={{ paddingLeft: 5 }}><span className="card-title">{this.state.lang === 'th' ? e.closetime + ' น.' : e.closetime + ' PM'}</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                        <hr className="pd-bt-15"></hr>
                                        <h6 className="card-subtitle topic-text pd-bt-15">{this.props.t("Attraction type")}</h6>
                                        {/* ประเภทสถานที่ */}
                                        {this.state.place_details.attractionType.description !== null || this.state.place_details.attractionType.description !== "" ?
                                            <div>
                                                <p className="normal-text pd-bt-15">
                                                    <img src={ic_detail} style={{ paddingRight: 17, height: 26 }} alt="" />
                                                    {this.state.place_details.attractionType.description}
                                                </p>
                                            </div>
                                            :
                                            <div>
                                                <p className="normal-text pd-bt-15">
                                                    <img src={ic_detail} style={{ paddingRight: 17, height: 26 }} alt="" />
                                                    -
                                                </p>
                                            </div>
                                        }
                                        {this.state.place_details.nameOfCommunity !== null || this.state.place_details.nameOfCommunity !== "" ?
                                            <div>
                                                <p className="normal-text pd-bt-15">
                                                    <img src={locations} style={{ paddingRight: 20, height: 27 }} alt="" />
                                                    {this.state.place_details.nameOfCommunity}
                                                </p>
                                            </div>
                                            :
                                            <div>
                                                <p className="normal-text pd-bt-15">
                                                    <img src={locations} style={{ paddingRight: 20, height: 27 }} alt="" />
                                                    -
                                                </p>
                                            </div>
                                        }

                                        <hr className=""></hr>
                                        {/* ข้อมูลติดต่อ */}
                                        <div style={{ marginTop: 20 }}>
                                            {/* ข้อมูลติดต่อ */}
                                            <h6 className="card-subtitle topic-text pd-bt-15" >{this.props.t('Contact us')}</h6>
                                            {this.state.place_details.information.tel === null || this.state.place_details.information.tel === "" ?
                                                <div>
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={phone} style={{ paddingRight: 18, height: 19 }} alt="" />
                                                        -
                                                    </p>
                                                </div>
                                                :
                                                <div>
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={phone} style={{ paddingRight: 18, height: 19 }} alt="" />
                                                        {this.state.place_details.information.tel}
                                                    </p>
                                                </div>
                                            }
                                            {this.state.place_details.information.url === null || this.state.place_details.information.url === "" ?
                                                <div>
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={website} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                        -
                                                    </p>
                                                </div>
                                                :
                                                <div>
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={website} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                        {this.state.place_details.information.url}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                        <hr className=""></hr>
                                        <div style={{ marginTop: 20 }}>
                                            <h6 className="card-subtitle topic-text pd-bt-15">{this.props.t('Map')}</h6>
                                            <div className="map-style">
                                                <img className="map" src={publicUrl + "assets/img/map2.png"} alt=""
                                                    onClick={() => { window.open("https://maps.google.com/?q=" + this.state.place_details.latitude + "," + this.state.place_details.longitude) }}>
                                                </img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ข้อมูลสถานที่ */}
                                {this.state.place_details.information.description !== "" ?
                                    <div style={{ marginTop: 20 }}>
                                        <h5 className="topic-text pd-bt-15">{this.props.t('Activity details')}</h5>
                                        <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15, whiteSpace: 'pre-line' }}>{this.state.place_details.information.description}</p>
                                        <hr></hr>
                                    </div>
                                    :
                                    <div></div>
                                }
                                {/* ข้อมูลแนะนำ */}
                                <div>
                                    <h5 className="topic-text pd-bt-15" >{this.props.t('Information')}</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row" style={{ paddingLeft: 16 }}>
                                            <p className="normal-text-18-bold cl-black dp">{this.props.t('Notice point')}</p>
                                        </div>
                                        <div className="normal-text pd-bt-15">- {landmark === null ? <></> : landmark}</div>
                                        <div className="row" style={{ paddingLeft: 16 }}>
                                            <p className="normal-text-18-bold cl-black dp">{this.props.t('The cost of activities')}</p>
                                        </div>
                                        <p className="normal-text pd-bt-15">- {fee === null ? <></> : fee + this.props.t('baht/person')}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row" style={{ paddingLeft: 16 }}>
                                            <p className="normal-text-18-bold cl-black dp">{this.props.t('Activity duration')}</p>
                                        </div>
                                        <p className="normal-text pd-bt-15">- {timePeriod === null ? <></> : timePeriod + this.props.t('Minutes')}</p>
                                    </div>
                                </div>
                                <hr></hr>
                                {/* สิ่งอำนวยความสะดวก */}
                                {this.state.place_details.facility < 1 ?
                                    <div>

                                    </div>
                                    :
                                    <div>
                                        <h5 className="topic-text pd-bt-15" >{this.props.t('Facilities')}</h5>
                                        <div className="row" style={{ paddingLeft: 12 }}>
                                            {this.state.place_details.facility.map((e, i) => {
                                                let facilitys = e.facilityName + " " + e.amount + " " + e.amountUnitType

                                                if (e.amount === 0 || e.amount === null) {
                                                    facilitys = e.facilityName
                                                }
                                                // เปรียบเทียบ icon กับ facilityName
                                                this.state.icon_facility.map((j) => {
                                                    if (e.facilityName === j.text) {
                                                        this.state.icon_style = j.icon
                                                    }
                                                })
                                                return (
                                                    <div key={i} className="col-md-6">
                                                        <div className="row" >
                                                            <p key={i} className="normal-text pd-bt-15"><img src={publicUrl + this.state.icon_style} style={{ paddingRight: 10, height: '30px' }} alt="" />{facilitys}</p>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                <hr></hr>
                                {/* เว็บไซด์ */}
                                <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: 12 }}>
                                    <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                    <div className="border-yellow"></div>
                                </div>
                                <div className="style-img-website">
                                    <img className="img-website" src={publicUrl + "assets/img/img_cover.png"} alt="" />
                                    <div className="block-cbt">
                                        <img className="img-logo-cbt" src={publicUrl + "assets/img/DASTA_LOGO.png"} alt="" />
                                        <div className="" style={{ display: 'block' }}>
                                            <div className="normal-text-style-18 cl-white text-center">องค์การบริหารการพัฒนาพื้นที่พิเศษเพื่อการท่องเที่ยวอย่างยั่งยืน(องค์การมหาชน)</div>
                                            {/* <div className="normal-text-18 cl-white text-center"> </div> */}
                                        </div>
                                    </div>
                                    <div className="row" style={{ justifyContent: 'center' }}>
                                        <a className="btn btn-website-green mb-60" href="https://www.dasta.or.th/th/" style={{ width: '40%' }}><span className="normal-text-18">{this.props.t('Visit Website')}</span></a>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="destinations-details-page mg-top--70s" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 col-12">
                                        {/* ปกติ */}
                                        {this.state.matches && (
                                            <div>
                                                <div className="card card-color" style={{ borderRadius: 15 }}>
                                                    <div className="card-body">
                                                        <div className="pd-bt-30" >
                                                            <h5 className="mg-top-card open-text cl-green"><img src={clock} style={{ paddingRight: 10, height: '30px' }} alt="" /><span data-nosnippet>{this.props.t('Opening hours')}</span></h5>
                                                        </div>
                                                        {/* วันเวลาที่ทำการ */}
                                                        {this.state.place_details.openday.length === 0 ?
                                                            <div></div>
                                                            :
                                                            <div>
                                                                {this.state.place_details.openday.map((e, i) => {
                                                                    // console.log(e)
                                                                    return (
                                                                        <div key={i} className="row" style={{ width: "100%" }}>
                                                                            <div className="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5">
                                                                                <div className="normal-text-day pd-bt-15"> <span className="card-title" >{e.day}</span></div>
                                                                            </div>
                                                                            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-7 col-7">
                                                                                <div className="row" style={{ justifyContent: 'flex-end' }}>
                                                                                    <div className="normal-text-day pd-bt-15"><span className="card-title" >{this.state.lang === 'th' ? e.opentime + ' น.' : e.opentime + ' AM'} - </span></div>
                                                                                    <div className="normal-text-day" style={{ paddingLeft: 5 }}><span className="card-title">{this.state.lang === 'th' ? e.closetime + ' น.' : e.closetime + ' PM'}</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                <hr className="pd-bt-15"></hr>
                                                            </div>
                                                        }
                                                        <h6 className="card-subtitle topic-text pd-bt-15">{this.props.t("Attraction type")}</h6>
                                                        {/* ประเภทสถานที่ */}
                                                        {this.state.place_details.attractionType.description !== null || this.state.place_details.attractionType.description !== "" ?
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={ic_detail} style={{ paddingRight: 17, height: 26 }} alt="" />
                                                                    {this.state.place_details.attractionType.description}
                                                                </p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={ic_detail} style={{ paddingRight: 17, height: 26 }} alt="" />
                                                                    -
                                                                </p>
                                                            </div>
                                                        }
                                                        {this.state.place_details.nameOfCommunity !== null || this.state.place_details.nameOfCommunity !== "" ?
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={locations} style={{ paddingRight: 20, height: 27 }} alt="" />
                                                                    {this.state.place_details.nameOfCommunity}
                                                                </p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={locations} style={{ paddingRight: 20, height: 27 }} alt="" />
                                                                    -
                                                                </p>
                                                            </div>
                                                        }
                                                        <hr className="pd-bt-15"></hr>
                                                        {/* ข้อมูลติดต่อ */}
                                                        <h6 className="card-subtitle topic-text pd-bt-15" >{this.props.t('Contact us')}</h6>
                                                        {this.state.place_details.information.tel === null || this.state.place_details.information.tel === "" ?
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={phone} style={{ paddingRight: 18, height: 19 }} alt="" />
                                                                    -
                                                                </p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={phone} style={{ paddingRight: 18, height: 19 }} alt="" />
                                                                    {this.state.place_details.information.tel}
                                                                </p>
                                                            </div>
                                                        }
                                                        {this.state.place_details.information.url === null || this.state.place_details.information.url === "" ?
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={website} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                                    -
                                                                </p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={website} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                                    {this.state.place_details.information.url}
                                                                </p>
                                                            </div>
                                                        }
                                                        <hr className="pd-bt-15"></hr>
                                                        {/* แผนที่ */}
                                                        <div style={{ marginTop: 20 }}>
                                                            <h6 className="card-subtitle topic-text pd-bt-15">{this.props.t('Map')}</h6>
                                                            <div className="map-style">
                                                                <img className="map" src={publicUrl + "assets/img/map2.png"} alt=""
                                                                    onClick={() => { window.open("https://maps.google.com/?q=" + this.state.place_details.latitude + "," + this.state.place_details.longitude) }}>
                                                                </img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.lang === 'zh' ?
                                                    <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '0.5rem' }}>
                                                        <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                                        <div className="border-yellow zh"></div>
                                                    </div>
                                                    :
                                                    <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '0.5rem' }}>
                                                        <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                                        <div className="border-yellow"></div>
                                                    </div>
                                                }
                                                <div className="style-img-website">
                                                    <img className="img-website" src={publicUrl + "assets/img/img_cover.png"} alt="" />
                                                    <div className="row row-flex">
                                                        <img className="img-logo-cbt" src={publicUrl + "assets/img/DASTA_LOGO.png"} alt="" />
                                                        <div className="" style={{ display: 'block' }}>
                                                            <div className="normal-text-style-18 cl-white text-center">องค์การบริหารการพัฒนาพื้นที่พิเศษเพื่อการท่องเที่ยวอย่างยั่งยืน(องค์การมหาชน)</div>
                                                            {/* <div className="normal-text-18 cl-white text-center"> </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ justifyContent: 'center' }}>
                                                        <a className="btn btn-website-green mb-60" href="https://www.dasta.or.th/th/" style={{ width: '40%' }}><span className="normal-text-18">{this.props.t('Visit Website')}</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {this.state.matches && (
                                        <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-12" >
                                            <div className="mg-top--70ss pd-bt-30 mg-left-20">
                                                <ImageGallery items={images}
                                                    showThumbnails={false}
                                                    infinite={true}
                                                    showIndex={true}
                                                />
                                            </div >
                                            {/* ชื่อสถานที่ */}
                                            <p className="large-text-36 pd-l-10 cl-black dp">{this.state.place_details.nameOfAttraction}</p>
                                            <div className="pd-bt-15 pd-l-10 row" style={{ margin: 0, marginTop: 10 }}>
                                                <div className="pd-r-15" style={{ display: 'flex' }}><img src={vecterlocation} style={{ paddingRight: 10, height: '25px' }} alt="" /><p className="normal-text-18-detail cl-blue">{this.props.t('SubDistrict')}: {this.state.place_details.location.subDistrict}</p></div>
                                                <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.props.t('District')}: {this.state.place_details.location.district}</p></div>
                                                <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.props.t('Province')}: {this.state.place_details.location.province}</p></div>
                                                <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.state.place_details.location.postcode}</p></div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-12">
                                                {/* รายละเอียดกิจกรรม*/}
                                                {this.state.place_details.information.description !== "" ?
                                                    <div>
                                                        <h5 className="topic-text pd-bt-15">{this.props.t('Activity details')}</h5>
                                                        <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15, whiteSpace: 'pre-line' }}>{this.state.place_details.information.description}</p>
                                                        <hr></hr>
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                                {/* ข้อมูลแนะนำ */}
                                                <div>
                                                    <h5 className="topic-text pd-bt-15" >{this.props.t('Information')}</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="row" style={{ paddingLeft: 16 }}>
                                                            <p className="normal-text-18-bold cl-black dp">{this.props.t('Notice point')}</p>
                                                        </div>
                                                        <div className="normal-text pd-bt-15">- {landmark === null ? <></> : landmark}</div>
                                                        <div className="row" style={{ paddingLeft: 16 }}>
                                                            <p className="normal-text-18-bold cl-black dp">{this.props.t('The cost of activities')}</p>
                                                        </div>
                                                        <p className="normal-text pd-bt-15">- {fee === null ? <></> : fee + " " + this.props.t('baht/person')}</p>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="row" style={{ paddingLeft: 16 }}>
                                                            <p className="normal-text-18-bold cl-black dp">{this.props.t('Activity duration')}</p>
                                                        </div>
                                                        <p className="normal-text pd-bt-15">- {timePeriod === null ? <></> : timePeriod + " " + this.props.t('Minutes')}</p>
                                                    </div>
                                                </div>
                                                {/* สิ่งอำนวยความสะดวก */}
                                                {this.state.place_details.facility < 1 ?
                                                    <div>

                                                    </div>
                                                    :
                                                    <div>
                                                        <h5 className="topic-text pd-bt-15" >{this.props.t('Facilities')}</h5>
                                                        <div className="row" style={{ paddingLeft: 12 }}>
                                                            {this.state.place_details.facility.map((e, i) => {
                                                                let facilitys = e.facilityName + " " + e.amount + " " + e.amountUnitType

                                                                if (e.amount === 0 || e.amount === null) {
                                                                    facilitys = e.facilityName
                                                                }
                                                                // เปรียบเทียบ icon กับ facilityName
                                                                this.state.icon_facility.map((j) => {
                                                                    if (e.facilityName === j.text) {
                                                                        this.state.icon_style = j.icon
                                                                    }
                                                                })
                                                                return (
                                                                    <div key={i} className="col-md-6">
                                                                        <div className="row" >
                                                                            <p key={i} className="normal-text pd-bt-15"><img src={publicUrl + this.state.icon_style} style={{ paddingRight: 10, height: '30px' }} alt="" />{facilitys}</p>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })}
                                                        </div>
                                                        <hr></hr>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-130 ">
                            <Footer />
                        </div>
                    </div>
                </div>
            )
        }
    }

}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
    language: 'th',
})(withTranslation()(TravelRouteAttractionDetailsPage));


