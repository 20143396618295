import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import PageHeaderTravelRoute from './global-components/page-header-travel-route';
import Footer from './global-components/footer';
import { api_endpoint } from '../global-config'
import { withTranslation } from 'react-i18next';
import { Container, BreadcrumbItem } from 'reactstrap';
import '../components/cssnew.css'
import './cssnew.css'
import FadeIn from "react-fade-in";
import "react-image-gallery/styles/css/image-gallery.css";
import * as loadingData from "../loadingttd.json";
import Lottie from "react-lottie";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { Markup } from 'interweave';
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
// import Parser from 'html-react-parser';
const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '10px'
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
class TravelRouteDetailsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            place_id: props.match.params.id,
            place_details: {},
            isLoading: true,
            lang: this.props.i18n.language,
            original: [],
            scroll: "",
            modal_control: false,
            matches: window.matchMedia("(min-width: 992px)").matches,
            isLogin: localStorage.getItem('user_data'),

        }
        console.log(props)
    }

    //เรียกข้อมูลสถานที่
    async get_place_detail() {
        const response = await fetch(
            api_endpoint + `/maininfo/readone`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ID: this.state.place_id,
                    // Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        let arr = []
        console.log(_result.result)
        _result.result.Images.map((e, i) => {
            arr.push({ original: e, thumbnail: e, originalAlt: _result.result.Name[this.state.lang] })
            return null
        })
        console.log(arr)
        this.setState({
            place_details: _result.result,
            original: arr,
            isLoading: false,
        })
    }

    modal_control() {
        this.setState({
            modal_control: true
        }, () => {
            console.log(this.state.modal_control)
            return this.state.modal_control
        })
    }

    login_check() {
        this.setState({
            isLogin: localStorage.getItem("user_data")
        })
    }

    componentDidMount() {
        if (this.state.place_id === undefined) {
            this.props.history.goBack()
        }
        else {
            const language = localStorage.getItem("I18N_LANGUAGE")
            if (!language) {
                localStorage.setItem("I18N_LANGUAGE", "th")
            }
            console.log(language)
            this.get_place_detail()

            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        }
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        this.setState({
            isLoading: true,
            lang: nextProps.i18n.language
        }, () => {

            this.get_place_detail()

            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        })


    }

    render() {
        const images = this.state.original;
        console.log(this.state.place_details)
        if (this.state.isLoading === true) {
            return (
                <div style={{ marginTop: 200 }} className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div className="card">
                                <div className="cardbody">
                                    <Lottie options={defaultOptions} height={300} width={300} />
                                    <div>
                                        <h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            )
        }
        else {
            let publicUrl = process.env.PUBLIC_URL + '/'
            // const Moredetails = this.state.place_details.MoreDetail[this.state.lang] === "" ? this.state.place_details.MoreDetail.th : this.state.place_details.MoreDetail[this.state.lang]
            // close js 'types' can only be used in a .ts file - Visual Studio Code using @ts-check
            // function transform(node: HTMLElement, children: Node[]): React.ReactNode {
            //     if (node.tagName.toLowerCase() === 'p') {
            //         return <p className="normal-text cl-black" style={{ margin: 0, textAlign: 'justify' }}>{children}</p>;
            //     }
            // }
            const articleContent = this.state.place_details.Description.th
            return (
                <div>
                    <Helmet>
                        <title>{this.state.place_details.Name[this.state.lang] === null || this.state.place_details.Name[this.state.lang] === undefined ?
                            this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]}</title>
                        <meta name="description" content={this.state.place_details.Detail[this.state.lang] === null || this.state.place_details.Detail[this.state.lang] === undefined ?
                            this.state.place_details.Detail.th : this.state.place_details.Detail[this.state.lang]} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:title" content={this.state.place_details.Name[this.state.lang] === null || this.state.place_details.Name[this.state.lang] === undefined ?
                            this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]} />
                        <meta property="og:description" content={this.state.place_details.Detail[this.state.lang] === null || this.state.place_details.Detail[this.state.lang] === undefined ?
                            this.state.place_details.Detail.th : this.state.place_details.Detail[this.state.lang]} />
                        <meta property="og:image" content={images[0].original} />
                        <meta itemprop="name" content={this.state.place_details.Name[this.state.lang] === null || this.state.place_details.Name[this.state.lang] === undefined ?
                            this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]} />
                        <meta itemprop="description" content={this.state.place_details.Detail[this.state.lang] === null || this.state.place_details.Detail[this.state.lang] === undefined ?
                            this.state.place_details.Detail.th : this.state.place_details.Detail[this.state.lang]} />
                        <meta itemprop="image" content={images[0].original} />
                    </Helmet>
                    <div className="menu-back-page animated fadeIn">
                        <span className="back-top">
                            <a hrefLang={this.state.lang} href={"/" + this.state.lang + "/routes"} className="normal-text-18-detail"><i className="fa fa-angle-left" style={{ fontSize: 22 }}><span className="large-text-20" style={{ padding: "0px 0px 0px 10px" }}>{this.props.t('Back')}</span></i></a>
                        </span>
                    </div>
                    <Navbar modal_control={this.state.modal_control} login_check={this.login_check} />
                    <PageHeaderTravelRoute headertitle={this.state.place_details.Name[this.state.lang] === "" || this.state.place_details.Name[this.state.lang] === undefined ?
                        this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]} />
                    <div className="pd-top-80 viaje-go-top">
                        <Container>
                            <div className="row" style={{ marginBottom: 20 }}>
                                <div className="new-breadcrumb">
                                    <BreadcrumbItem><a hrefLang={this.state.lang} href={"/" + this.state.lang} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                                    <BreadcrumbItem ><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/routes"} className="normal-text cl-gray">{this.props.t('Tourist Routes')}</a></BreadcrumbItem>
                                    <BreadcrumbItem active><span className="normal-text cl-black">{this.state.place_details.Name.th}</span></BreadcrumbItem>
                                </div>
                            </div>
                            <p className="large-text-36 pd-t-30 cl-black" >{this.props.t('Routes to')} {this.state.place_details.Name[this.state.lang] === "" || this.state.place_details.Name[this.state.lang] === undefined ?
                                this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]}</p>
                            <p className="normal-text cl-blue"><img src={publicUrl + "assets/img/icons/location.png"} alt="map" /><span style={{ marginLeft: 10 }}>{this.state.place_details.Province[this.state.lang]}</span></p>
                            <hr style={{ height: 2, borderWidth: 5, backgroundColor: '#173865' }}></hr>
                            {/* <p className="normal-text pd-bt-15 cl-black">{this.state.place_details.IntroText[this.state.lang]}</p> */}
                            {/* <p className="normal-text pd-bt-15 cl-black" dangerouslySetInnerHTML={{ __html: this.state.place_details.Description.th }}></p> */}
                            <Markup content={articleContent} />
                            {/* <p className="normal-text pd-bt-15 cl-black">{Parser(this.state.place_details.Description.th)}</p> */}
                            {this.state.place_details.Route.map((e, i) => {
                                return (
                                    <div key={i}>
                                        <p className="normal-text pd-bt-15 cl-black" style={{ fontWeight: 'bold' }}>{i + 1}.{e.Name[this.state.lang] === "" ? e.Name.th : e.Name[this.state.lang]}</p>
                                        <div style={{ textAlign: 'center' }}>
                                            <img className="pd-bt-15" src={e.IntroImage} style={{ width: '90%', height: '450px', objectFit: 'cover', borderRadius: 50, borderBottomLeftRadius: 50, borderBottomRightRadius: 50 }} alt="" />
                                        </div>
                                        <p className="normal-text pd-bt-15 cl-black" style={{ textAlign: 'justify', textIndent: 65 }}>{e.Detail[this.state.lang] === "" ? e.Detail.th : e.Detail[this.state.lang]}</p>
                                    </div>
                                )
                            })}
                            <hr></hr>
                            {/* แผนที่ */}
                            <h5 className="topic-text pd-bt-15">{this.props.t('Map')}</h5>
                            <div id="gmaps-markers" className="gmaps" style={{ height: 430, width: '100%' }} >
                                <Map
                                    containerStyle={containerStyle}
                                    google={window.google}
                                    zoom={5.5}
                                    initialCenter={{
                                        lat: 13.75,
                                        lng: 100.517
                                    }}
                                // center={{
                                //     lat: this.state.place_details.Route[0].Latitude,
                                //     lng: this.state.place_details.Route[0].Longitude
                                // }}
                                >
                                    {this.state.place_details.Route.map((e, i) => {
                                        return (
                                            <Marker key={i}
                                                name={'Your position'}
                                                position={{ lat: e.Latitude, lng: e.Longitude }}

                                            />
                                        )
                                    })}
                                </Map>
                            </div>
                        </Container>
                    </div>
                    <Footer />
                </div>
            )
        }
    }

}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
    language: 'th',
})(withTranslation()(TravelRouteDetailsPage));


