import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import * as loadingData from "../../loadingttd.json";
import { api_endpoint } from '../../global-config'
import { withTranslation } from 'react-i18next';
import { BreadcrumbItem } from 'reactstrap';
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingData.default,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

class UserProfile extends Component {
	constructor(props) {
		super(props)
		this.state = {
			places_data: [],
			count_places: [],
			count: 0,
			skip: 0,
			limit: 12,
			subtype: [],
			lang: this.props.i18n.language,
			category: [],
			place_status: -1,
			main_info_id: [1],
			isLoading: true,
			selectedLang: "th",
			isLogin: "",
			TitleName: "",
			FirstName: "",
			LastName: "",
			Username: "",
			Email: "",
			UserType: null,
			UserSubType: null,
			UserGroupID: null,
			DepartmentID: null,
			Password: "",
			OldPassword: '',
			NewPassword: '',
			ConfirmPassword: '',
			passwordLength: false,
			containsNumbers: false,
			isUpperCase: false,
			// containsSymbols: false,
			isLowerCase: false,
			isSamePassword: false

		}
		this.login_check = this.login_check.bind(this);
		this.update_handle = this.update_handle.bind(this);

		// this.ChangePassword_handle = this.ChangePassword_handle(this);
	}

	//คุมภาษา
	change_lang_callback() {

	}
	login_check() {
		this.setState({
			isLogin: localStorage.getItem("user_data"),
		})
	}

	update_handle(target, value) {
		this.setState({
			[target]: value
		})
	}


	async update_profile(event) {
		event.preventDefault();
		const response = await fetch(
			api_endpoint + `/user/update`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + this.state.token
				},
				body: JSON.stringify({
					Username: this.state.Username,
					Password: this.state.Password,
					UserType: this.state.UserType,
					UserSubType: this.state.UserSubType,
					UserGroupID: this.state.UserGroupID,
					DepartmentID: this.state.DepartmentID,
					TitleName: this.state.TitleName,
					FirstName: this.state.FirstName,
					LastName: this.state.LastName,
					Email: this.state.Email
				}), // body data type must match "Content-Type" header
			}
		);
		var _result = await response.json();
		console.log(_result)
		if (_result.status === 'ok') {
			//localStorage.setItem("user_data", JSON.stringify(_result))
			let user_data = localStorage.getItem('user_data')
			localStorage.setItem("user_data", JSON.stringify({
				"DepartmentID": JSON.parse(user_data).DepartmentID,
				"Email": this.state.Email,
				"FirstName": this.state.FirstName,
				"LastName": this.state.LastName,
				"TitleName": this.state.TitleName,
				"UserGroupID": this.state.UserGroupID,
				"UserID": this.state.UserID,
				"UserSubType": this.state.UserSubType,
				"UserType": this.state.UserType,
				"Username": this.state.Username,
				"token": this.state.token,
				"_id": JSON.parse(user_data)._id
			}))
			window.location.reload(false)
			return
		}
		else {
			alert(_result.message)
			return
		}

	}

	async update_password(event) {
		event.preventDefault();
		const response = await fetch(
			api_endpoint + `/user/change_password`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + this.state.token
				},
				body: JSON.stringify({
					Username: this.state.Username,
					Oldpassword: this.state.OldPassword,
					Password: this.state.NewPassword
				}), // body data type must match "Content-Type" header
			}
		);
		var _result = await response.json();
		console.log(_result)
		if (_result.status === 'ok') {
			console.log(_result)
			window.location.reload(false)
			return
		} else {
			alert(_result.message)
			return
		}
	}

	// check to see if there is any number
	checkForNumbers(string) {
		var matches = string.match(/\d+/g);
		this.setState({
			containsNumbers: matches !== null ? true : false
		});
	}

	// check for upper case
	checkForUpperCase(string) {
		var matches = string.match(/[A-Z]/);
		this.setState({
			isUpperCase: matches !== null ? true : false
		});
	}

	checkForLowerCase(string) {
		var matches = string.match(/[a-z]/);
		this.setState({
			isLowerCase: matches !== null ? true : false
		});
	}

	// handle NewPassword
	handleNewPassword = input => e => {
		let targetValue = e.target.value.replace(/\s/g, '');
		this.checkForNumbers(targetValue)
		this.checkForUpperCase(targetValue)
		this.checkForLowerCase(targetValue)
		this.setState({
			[input]: targetValue,
			passwordLength: targetValue.length >= 8 ? true : false
		}, () => {
			// console.log('O:', this.state.OldPassword)
			// console.log('N:', this.state.NewPassword)
			// console.log('C:', this.state.ConfirmPassword)
			if (this.state.ConfirmPassword.length > 1 && this.state.NewPassword.length > 1) {
				this.setState({
					isSamePassword: this.state.NewPassword === this.state.ConfirmPassword
				})
			}
		});
	}

	//เรียกประเภทสถานที่
	// async get_places_types() {
	// 	const response = await fetch(
	// 		api_endpoint + `/maininfo/subtypes`,
	// 		{
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json"
	// 			},
	// 			body: JSON.stringify({
	// 				MainTypeID: 3,
	// 				Lang: this.state.lang,
	// 			}), // body data type must match "Content-Type" header
	// 		}
	// 	);
	// 	var _result = await response.json();
	// 	console.log(_result)
	// 	let arr = []
	// 	for (const [key, value] of Object.entries(_result)) {
	// 		arr.push({ key: key, value: value, selected: false })
	// 	}
	// 	this.setState({
	// 		category: arr
	// 	}, () => {
	// 		console.log(arr)
	// 	})

	// }

	//เรียกรายการโปรด
	async get_favorite() {
		const response = await fetch(
			api_endpoint + `/maininfo/favourites`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					Skip: this.state.skip,
					Limit: this.state.limit,
					Username: JSON.parse(localStorage.getItem('user_data')).Username,
					Lang: this.state.lang,
				}), // body data type must match "Content-Type" header
			}
		);
		var _result = await response.json();
		console.log(_result)
		if (_result.results.length > 0) {
			this.setState({
				places_data: _result.results,
				count: _result.count,
				isLoading: false,
			})
		}
		else {
			this.setState({
				places_data: _result.results,
				count: _result.count,
				isLoading: false,
			})
		}
	}

	//ปิลเตอร์สถานที่เปิดปิด
	place_status(event) {
		console.log(event.target.getAttribute("data-value"))
		if (event.target.value === 0) {
			let arr = this.state.places_data
			let filter_places = arr.filter(item => item.IsOpen === event.target.getAttribute("data-value"))
			console.log(filter_places)

		}
		if (event.target.value === 1) {
			let arr = this.state.places_data
			let filter_places = arr.filter(item => item.IsOpen === event.target.getAttribute("data-value"))
			console.log(filter_places)

		}
		if (event.target.value === -1) {
			let arr = this.state.places_data
			let filter_places = arr.filter(item => item.IsOpen === event.target.getAttribute("data-value"))
			console.log(filter_places)

		}
		else {
			return
		}

	}

	//get user data
	async get_user_read() {
		const response = await fetch(
			api_endpoint + `/user/read`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					Username: JSON.parse(localStorage.getItem('user_data')).Username,
				}),
			}
		);
		var _result = await response.json();
		console.log(_result)

		if (_result.status === 'ok') {
			this.setState({
				TitleName: _result.TitleName,
				FirstName: _result.FirstName,
				LastName: _result.LastName,
				Username: _result.Username,
				Email: _result.Email,
				token: JSON.parse(localStorage.getItem('user_data')).token
			})
		}
	}

	getMainTypeID(mainTypeID) {
		if (mainTypeID === 1) {
			return '/attraction/'
		}
		else if (mainTypeID === 2) {
			return '/activity/'
		}
		else if (mainTypeID === 3) {
			return '/touroperator/'
		}
		else if (mainTypeID === 4) {
			return '/accommodation/'
		}
		else if (mainTypeID === 5) {
			return '/spa/'
		}
		else if (mainTypeID === 6) {
			return '/restaurant-details/'
		}
		else if (mainTypeID === 7) {
			return '/store/'
		}
		// else if (mainTypeID === 8) {
		// 	return '/guide-details/'
		// }
		// else if (mainTypeID === 9) {
		// 	return '/route/'
		// }
	}

	async logout() {
		console.log(JSON.parse(localStorage.getItem("user_data")))
		const response = await fetch(
			api_endpoint + `/auth/logout`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + JSON.parse(localStorage.getItem("user_data")).token
				},
				body: JSON.stringify({}), // body data type must match "Content-Type" header
			}
		);
		var _result = await response.json();
		console.log(_result)
		if (_result.status === 'ok' && _result.success === true) {
			localStorage.removeItem("user_data")
			window.location.replace('/')
			return
		}
		else {
			console.log('error')
			return
		}
	}
	componentDidMount() {
		// redirect to home
		if (localStorage.getItem('user_data') === null) {
			window.location.replace('/#/')
			return
		}

		//check language
		const language = localStorage.getItem("I18N_LANGUAGE")
		if (!language) {
			localStorage.setItem("I18N_LANGUAGE", "th")
		}
		console.log(language)

		//get places and places_types
		this.get_favorite()
		// this.get_places_types()

		//get user data
		this.get_user_read()
	}

	componentWillReceiveProps(nextProps) {
		console.log(nextProps)
		this.setState({
			isLoading: false,
			lang: nextProps.i18n.language

		})
	}

	render() {
		let {
			OldPassword,
			NewPassword,
			ConfirmPassword,
			passwordLength,
			containsNumbers,
			isUpperCase,
			// containsSymbols,
			isLowerCase,
			isSamePassword,
		} = this.state
		let btnStatus = passwordLength && containsNumbers && isUpperCase && isLowerCase && isSamePassword ? false : true;
		let publicUrl = process.env.PUBLIC_URL + '/'
		if (this.state.isLoading === true) {
			return (
				<div style={{ marginTop: 200 }} className="text-center" >
					<FadeIn>
						<div style={{ display: "flex", justifyContent: 'center' }}>
							<div className="card">
								<div className="cardbody">
									<Lottie options={defaultOptions} height={300} width={300} />
									<div>
										<h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
									</div>

								</div>
							</div>

						</div>

					</FadeIn>
				</div>
			)
		} else {
			return <div className="user-profile-area pd-t-44">
				<div className="container">
					<div className="row pd-b-44">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<div className="new-breadcrumb-user">
								<BreadcrumbItem><a hrefLang={this.state.lang} href={"/" + this.state.lang} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
								<BreadcrumbItem active><a href="/user" className="normal-text cl-black">{this.props.t('User Profile')}</a></BreadcrumbItem>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="row">
								<div className="col-lg-3">
									<ul className="nav nav-tabs tp-tabs style-two">
										<li className="nav-item">
											<a className="nav-link active normal-text-18" data-toggle="tab" href="#tabs_1">
												<i className="fa fa-user" aria-hidden="true" />โปรไฟล์</a>
										</li>
										<li className="nav-item">
											<a className="nav-link normal-text-18" data-toggle="tab" href="#tabs_2">
												<i className="fa fa-lock" aria-hidden="true" />เปลี่ยนรหัสผ่าน</a>
										</li>
										<li className="nav-item">
											<a className="nav-link normal-text-18" data-toggle="tab" href="#tabs_3">
												<i className="fa fa-heart" aria-hidden="true" />รายการโปรด</a>
										</li>
										<li className="text-center none-logout">
											<Link className="btn btn-yellow" style={{ backgroundColor: '#071c55', borderRadius: 50 }} onClick={() => { this.logout() }}>
												<i className="fa fa-sign-in" aria-hidden="true"></i>
												<span>{this.props.t('Logout')}</span>
											</Link>
										</li>
										{/* <li className="nav-item">
											<a className="nav-link normal-text-18" data-toggle="tab" href="#tabs_4">
												<i className="fa fa-heart" aria-hidden="true" />test</a>
										</li> */}
									</ul>
								</div>
								<div className="col-xl-9 col-lg-8">
									<div className="tab-content user-tab-content">
										<div className="tab-pane fade show active border-profile" id="tabs_1">
											<div className="user-details mg-profile">
												{/* <p className="user-details-title normal-text-18 cl-black">โปรไฟล์</p>
												<div className="tp-img-upload">
													<div className="tp-avatar-preview">
														<div id="tp_imagePreview" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/icons/image-preview.png)' }}>
														</div>
													</div>
													<div className="tp-avatar-edit">
														<input type="file" id="tp_imageUpload" accept=".png, .jpg, .jpeg"></input>
														<label className="btn btn-transparent" htmlFor="tp_imageUpload"><i className="fa fa-picture-o" />เปลี่ยนรูป</label>
														<div>
															<p className="normal-text-18 cl-black">
																<span>
																	{this.state.FirstName}
																</span>
																<span style={{ marginLeft: 10 }}>
																	{this.state.LastName}
																</span>
															</p>
														</div>
													</div>
												</div> */}
												{/* <hr style={{ borderBottom: '0.5px solid #C4C4C4' }}></hr> */}
												<form className="tp-form-wrap">
													<div>
														<div className="form-row">
															<div className="form-group mr-form-10 width-form-0 ">
																<label htmlFor="inputState" className="normal-text">คำนำหน้า</label>
																<input type="text" className="form-control" id="inputTitlename" placeholder="กรุณากรอกคำนำหน้า"
																	value={this.state.TitleName} onChange={(e) => { this.update_handle('TitleName', e.target.value) }}></input>
															</div>
															<div className="form-group mr-form-29 width-form-1 ">
																<label htmlFor="inputUsername" className="normal-text">ชื่อ</label>
																<input type="text" className="form-control" id="inputFirstname" placeholder="กรุณากรอกชื่อจริง"
																	value={this.state.FirstName} onChange={(e) => { this.update_handle('FirstName', e.target.value) }}></input>
															</div>
															<div className="form-group width-form-2 mr-form-5">
																<label htmlFor="inputLastname" className="normal-text">นามสกุล</label>
																<input type="text" className="form-control " id="inputLastname" placeholder="กรุณากรอกนามสกุล"
																	value={this.state.LastName} onChange={(e) => { this.update_handle('LastName', e.target.value) }}></input>
															</div>
														</div>
														<div className="row">
															<div className="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
																<label className="normal-text" htmlFor="inputFirstName3">ชื่อผู้ใช้งาน</label>
																<input type="text" className="form-control" id="inputUsername" placeholder="กรุณากรอกชื่อผู้ใช้งาน"
																	value={this.state.Username} onChange={(e) => { this.update_handle('username', e.target.value) }} disabled></input>
															</div>
															<div className="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
																<label className="normal-text" htmlFor="inputLastName4">อีเมล์</label>
																<input type="text" className="form-control" id="inputEmail" placeholder="test12345@gmail.com"
																	value={this.state.Email} onChange={(e) => { this.update_handle('Email', e.target.value) }}></input>
															</div>
															<div className="col-12 style-btn-end">
																<button className="style-btn btn-green" type="submit" onClick={(e) => { this.update_profile(e) }}><span className="normal-text-16">บันทึกข้อมูล</span></button>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
										<div className="tab-pane fade border-profile" id="tabs_2">
											<div className="user-settings mg-profile">
												<h3 className="user-details-title normal-text-18 cl-black">เปลี่ยนรหัสผ่าน</h3>
												<form>
													<div className="row">
														<div className="col-12 col-sm-6 mb-3">
															<div className="row">
																<div className="col">
																	<div className="form-group">
																		<label className="normal-text">รหัสผ่านเก่า</label>
																		<input className="form-control" type="password" onChange={(e) => { this.update_handle('OldPassword', e.target.value) }} value={OldPassword} placeholder="กรุณากรอกรหััสผ่านเดิม" />
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col">
																	<div className="form-group">
																		<label className="normal-text">รหัสผ่านใหม่</label>
																		<input className="form-control" type="password" onChange={this.handleNewPassword('NewPassword')} value={NewPassword} placeholder="กรุณากรอกรหััสผ่านใหม่" />
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col">
																	<div className="form-group">
																		<label className="normal-text">ยืนยันรหัสผ่านใหม่</label>
																		<input className="form-control" type="password" onChange={this.handleNewPassword('ConfirmPassword')} value={ConfirmPassword} placeholder="กรุณายืนยันรหัสผ่านใหม่" />
																	</div>
																</div>
															</div>
														</div>
														<div className="col-12 col-sm-5 offset-sm-1 mb-3" style={{ marginTop: 30 }}>
															<div className="mb-2 normal-text-checked">คำแนะนำในการตั้งรหัสผ่าน</div>
															<div className="row">
																<div className="col" style={{ marginLeft: 15 }}>
																	<div className="row">
																		<div className={passwordLength ? "icon-correct" : "icon-circle"}></div>
																		<label className="normal-text-14 light-gray">ควรมีความยาวอย่างน้อย 8 ตัว</label>
																	</div>
																	<div className="row">
																		<div className={containsNumbers ? "icon-correct" : "icon-circle"}></div>
																		<label className="normal-text-14 light-gray">ควรมีตัวเลข [0-9] อย่างน้อย 1 ตัว</label>
																	</div>
																	<div className="row">
																		<div className={isUpperCase ? "icon-correct" : "icon-circle"}></div>
																		<label className="normal-text-14 light-gray">ควรมีอักษรภาษาอังกฤษพิมพ์ใหม่ [A-Z] อย่างน้อย 1 ตัว</label>
																	</div>
																	<div className="row">
																		<div className={isLowerCase ? "icon-correct" : "icon-circle"}></div>
																		<label className="normal-text-14 light-gray">ควรมีอักษรภาษาอังกฤษ [a-z] อย่างน้อย 1 ตัว</label>
																	</div>
																	<div className="row">
																		<div className={isSamePassword ? "icon-correct" : "icon-circle"}></div>
																		<label className="normal-text-14 light-gray">รหัสผ่านใหม่ไม่ตรงกัน</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-12 style-btn-end">
															<button className="Submit" disabled={btnStatus} onClick={(e) => { this.update_password(e) }}><span className="normal-text-16">บันทึกข้อมูล</span></button>
															{/* <button className="Submit" disabled={btnStatus} onClick={this.submitForm}><span className="normal-text-16">บันทึกข้อมูล</span></button> */}

														</div>
													</div>
												</form>

											</div>

											{/* <div className="custom-controls-stacked">
																	<div className="custom-control custom-checkbox">
																		<input type="checkbox" className="custom-control-input" id="customCheck1" checked></input>
																		<label className="custom-control-label normal-text-14 light-gray" htmlFor="customCheck1">ควรมีความยาวอย่างน้อย 8 ตัว</label>
																	</div>
																	<div className="custom-control custom-checkbox">
																		<input type="checkbox" className="custom-control-input" id="customCheck2" checked></input>
																		<label className="custom-control-label normal-text-14 light-gray" htmlFor="customCheck2">ควรมีตัวเลข [0-9] อย่างน้อย 1 ตัว</label>
																	</div>
																	<div className="custom-control custom-checkbox">
																		<input type="checkbox" className="custom-control-input" id="customCheck3" checked></input>
																		<label className="custom-control-label normal-text-14 light-gray" htmlFor="customCheck3">ควรมีอักษรภาษาอังกฤษพิมพ์ใหม่ [A-Z] อย่างน้อย 1 ตัว</label>
																	</div>
																	<div className="custom-control custom-checkbox">
																		<input type="checkbox" className="custom-control-input" id="customCheck4" checked></input>
																		<label className="custom-control-label normal-text-14 light-gray" htmlFor="customCheck4">ควรมีอักษรภาษาอังกฤษ [a-z] อย่างน้อย 1 ตัว</label>
																	</div>
																</div> */}


										</div>
										<div className="tab-pane fade" id="tabs_3">
											{this.state.places_data.length > 0 ?
												<div className="row justify-content-center">
													{this.state.places_data.map((e, i) => {
														let place_name = ""

														if (e.Name[this.props.i18n.language] !== undefined && e.Name[this.props.i18n.language] !== "" && e.Name[this.props.i18n.language] !== null) {
															place_name = e.Name[this.props.i18n.language]
														}
														else {
															place_name = e.Name.th === undefined ? e.Name : e.Name.th
														}
														return (
															<div key={i} className="col-xl-4 col-lg-6 col-sm-6">
																<div className="single-destinations-list style-two animated slideInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
																	<Link to={{ pathname: this.getMainTypeID(e.MainTypeID) + e.ID, params: e.ID }}>
																		<div className="thumb">
																			{e.IsOpen === 1 ?
																				<div style={{ position: 'absolute', zIndex: 100, backgroundColor: '#339933', color: 'white', padding: 3, paddingLeft: 10, paddingRight: 10, borderRadius: 5, margin: 10 }}><span data-nosnippet>{this.props.t('Open')}</span></div>
																				:
																				<div style={{ position: 'absolute', zIndex: 100, backgroundColor: 'gray', color: 'white', padding: 3, paddingLeft: 10, paddingRight: 10, borderRadius: 5, margin: 10 }}><span data-nosnippet>{this.props.t('Closed')}</span></div>
																			}
																			<img style={{ height: 220, width: '100%' }} src={e.IntroImage} alt={place_name} />

																		</div>
																	</Link>

																	<div className="details" style={{ padding: 5, borderBottom: '1px solid #F0F0F0' }}>
																		<p className="location" style={{ color: '#173865' }}><img src={publicUrl + "assets/img/icons/location.png"} alt="map" />{e.Province[this.state.lang]}</p>
																		<p className="normal-text-18 weight-700 cl-black">{place_name.length > 40 ? place_name.slice(0, 36) + "..." : place_name}</p>
																		{/* <p className="normal-text-18 weight-700 cl-black">{place_name.length > 25 ? place_name.slice(0, 25) + "..." : place_name}</p> */}
																		<div className="row" style={{ justifyContent: 'space-between', margin: 2 }}>
																			<div className="content">
																				<p className="normal-text-14" style={{ color: 'gold' }}><span data-nosnippet>{this.props.t('Official Verify')}</span></p>
																			</div>
																			<div className="content">
																				<p className="normal-text-14" style={{ color: 'gray' }}><Link to={{ pathname: this.getMainTypeID(e.MainTypeID) + e.ID, params: e.ID, }}><span data-nosnippet>{this.props.t('Details')}</span></Link></p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														)
													})}
												</div>
												:
												<div>
													<div className="col-xl-12 col-lg-12 order-lg-12">
														<div style={{ justifyContent: 'center', alignSelf: 'center' }}>
															<Lottie options={defaultOptions} height={300} width={300} />
															<p className="normal-text-14" style={{ textAlign: 'center' }}>ไม่สามารถโหลดข้อมูลได้หรืออาจจะไม่มีข้อมูลนี้อยู่ในระบบ</p>
															<p className="normal-text-14" style={{ textAlign: 'center' }}>กรุณาลองเปลี่ยนคำค้นหาเป็นคำอื่นๆ</p>
														</div>

													</div>
												</div>
											}

										</div>
										{/* <div className="tab-pane fade border-profile" id="tabs_4">
											<div className="user-settings mg-profile">

												<div className="title">
													Pasword Checker
                </div>
												<div className="content">
													<form>
														<input type="text" onChange={this.handleChange('password')} value={password} placeholder="Enter Password" />
														<div>
															<div className={passwordLength ? 'green' : null}>Contains More than 8 characters</div>
															<div className={containsNumbers ? 'green' : null}>Contains numbers</div>
															<div className={isUpperCase ? 'green' : null}>Contains UpperCase</div>
															<div className={isLowerCase ? 'green' : null}>Contains LowerCase</div>
														</div>
														<button className="Submit" disabled={btnStatus} onClick={this.submitForm}>Submit</button>
													</form>
												</div>
											</div>
										</div> */}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >

		}

	}
}

export default withTranslation()(UserProfile);