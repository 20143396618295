import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class NewTourist extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="holiday-plan-area tp-holiday-plan-area mg-top-96" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/BG1.jpg)' }}>
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12" style={{ marginTop: 50 }}>
						<div className="section-title">
							<h2 className="title wow animated fadeInUp large-text-48 cl-black" data-wow-duration="0.6s" data-wow-delay="0.1s">{this.props.t('New attractions from every community')}</h2>
							<div className="row">
								<div className="col-lg-9">
									<p className="wow animated fadeInUp large-text light-gray" data-wow-duration="0.6s" data-wow-delay="0.2s">{this.props.t('Everybody in Thailand is willing to welcome travelers from all over the world')}</p>
								</div>
								<div className="col-lg-3" style={{ textAlign: 'end' }}>
									<Link className="btn btn-gray" to={"/" + this.props.lang + "/attractions"} style={{ fontSize: 24, marginTop: 0, borderRadius: 39 }} target="_blank" rel="noopener noreferrer">{this.props.t('View more')}</Link>
								</div>
							</div>

						</div>
					</div>
				</div>
				{this.props.place.length > 0 ?
					<div className="row">
						{this.props.place.map((e, i) => {
							let place_name = ""
							let province_name = ""
							if (e.Name[this.props.lang] !== undefined && e.Name[this.props.lang] !== "" && e.Name[this.props.lang] !== null) {
								place_name = e.Name[this.props.lang]
							}
							if (e.Province[this.props.lang] !== undefined && e.Province[this.props.lang] !== "" && e.Province[this.props.lang] !== null) {
								province_name = e.Province[this.props.lang]
							}
							else {
								place_name = e.Name.th === undefined ? e.Name : e.Name.th
								province_name = e.Province.th === undefined ? e.Province : e.Province.th
							}
							if (i < 4) {
								return (
									<div key={i} className="col-lg-3 col-sm-6">
										<div className="single-destinations-list style-two animated slideInUp border-solid" data-wow-duration="0.4s" data-wow-delay="0.1s">
											<Link to={{ pathname: "/" + this.props.lang + "/attraction/" + e.ID, params: e.ID }}>
												<div className="thumb">
													{e.IsOpen === 1 ?
														<div style={{ position: 'absolute', zIndex: 100, backgroundColor: '#339933', color: 'white', padding: 3, paddingLeft: 10, paddingRight: 10, borderRadius: 5, margin: 10 }}><span data-nosnippet>{this.props.t('Open')}</span></div>
														:
														<div style={{ position: 'absolute', zIndex: 100, backgroundColor: 'gray', color: 'white', padding: 3, paddingLeft: 10, paddingRight: 10, borderRadius: 5, margin: 10 }}><span data-nosnippet>{this.props.t('Closed')}</span></div>
													}
													<img style={{ height: 220, width: '100%' }} src={e.IntroImage} alt={place_name} />
												</div>
											</Link>
											<div className="details" style={{ padding: 5, borderBottom: '1px solid #F0F0F0' }}>
												<p className="location" style={{ color: '#173865' }}><img src={publicUrl + "assets/img/icons/location.png"} alt="map" />{province_name.length > 25 ? province_name : province_name}</p>
												<p className="normal-text-18 weight-700 cl-black">{place_name.length > 40 ? place_name.slice(0, 36) + "..." : place_name}</p>
												{/* <p className="normal-text-18 weight-700 cl-black">{place_name.length > 25 ? place_name.slice(0, 25) + "..." : place_name}</p> */}
												<div className="row" style={{ justifyContent: 'space-between', margin: 2 }}>
													<div className="content">
														<p className="normal-text-14" style={{ color: 'gold' }}><span data-nosnippet>{this.props.t('Official Verify')}</span></p>
													</div>
													<div className="content">
														<p className="normal-text-14" style={{ color: 'gray' }}><Link to={{ pathname: "/" + this.props.lang + "/attraction/" + e.ID, params: e.ID, }}><span data-nosnippet>{this.props.t('Details')}</span></Link></p>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							}
							else {
								return null
							}

						})}
					</div>
					:
					<div>

						ไม่พบข้อมูล
					</div>
				}
			</div>
		</div>
	}
}

export default withTranslation()(NewTourist);