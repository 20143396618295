// import { Link } from 'react-router-dom';
import React, { Component } from 'react';
// import { api_endpoint } from '../global-config'
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Search from './section-components/search';
import Intro from './section-components/intro';
// import Offer from './section-components/offer';
// import Video from './section-components/video';
// import HolidayPlan from './section-components/holiday-plan';
// import Review from './section-components/review';
// import BlogSection from './blog-components/blog-section';
import Ads from './section-components/ads';
// import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';
// import Province from './section-components/province';
import Accommodation from './section-components/accommodation';
import Community from './section-components/community';
import Restaurant from './section-components/restaurant';
import Store from './section-components/store';
import Activities from './section-components/activities';
// import Campaign from './section-components/campaign';
import News from './section-components/news';
import BlogTravel from './section-components/blog-travel';
import Tourist from './section-components/tourist';
import NewTourist from './section-components/new-tourist';
import CardTravel from './section-components/card-travel';
import Recommend from './section-components/recommend';
import Counter from './section-components/counter';
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import { Card, CardBody } from "reactstrap";
import * as loadingData from "../loadingttd.json";
import { api_endpoint } from '../global-config'
// import SearchingPage from './searching'
// import PlacePage from './places'
// import AccommodationPage from './accommodation'
// import RestaurantPage from './restaurant'
// import SpaPage from './spa'
// import StorePage from './store'
// import ActivityPage from './activitys'
// import RoutePage from './travel-route'
// import TourOperatorPage from './tour-operator'
import { withTranslation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

class Home_V1 extends Component {
    // const Home_V1 = () => {
    constructor(props) {
        super(props)
        this.state = {
            activitys_data: [],
            places_data: [],
            places_new_data: [],
            community_data: [],
            accommodation_data: [],
            restaurant_data: [],
            campaign_data: [],
            route_data: [],
            shop_data: [],
            news_data: [],
            count_activities: [],
            count_attractions: [],
            count_routes: [],
            count_touroperators: [],
            count_places: [],
            content_data_story: [],
            content_data_page: [],
            lang: this.props.i18n.language,
            count: 0,
            skip: 0,
            limit: 15,
            // lang: 'th',
            // search: "",
            category: [],
            page_index: 0,
            // pagination_min: -1,
            // pagination_max: 5,
            // searching: false,
            // place_status: -1,
            main_id_type: [4],
            provinces: [],
            provinces_id: [],
            // regions: [],
            // region_id: [],
            // region_selected: 'กรุณาเลือกภูมิภาค',
            // province_selected: 'กรุณาเลือกจังหวัด'
            isLoadingActivity: true,
            isLoadingPlace: true,
            isLoadingCommunity: true,
            isLoadingAccommodation: true,
            isLoadingRestaurant: true,
            isLoadingShop: true,
            isLoadingCampaign: true,
            isLoadingNews: true,
            isLoadingCount_all: true,
            isLoadingRoute: true,
            isLoadingContentStory: true,
            isLoadingContentPage: true,
            isSearching: false,
            searchingPage: "",
            searchingValue: "",
            selectedLang: "th",

        }
        this.search = this.search.bind(this);
    }


    //เรียกสถานที่
    async get_activity() {
        const response = await fetch(
            api_endpoint + `/maininfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: 0,
                    Limit: 4,
                    Lang: 3,
                    MainTypeID: [2],
                    ProvinceCode: this.state.provinces_id,
                    RegionID: this.state.region_id,


                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            activitys_data: _result.results,
            isLoadingActivity: false,
        }, () => {
            // this.get_places()
        })
    }

    async get_places() {
        const response = await fetch(
            api_endpoint + `/maininfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: 0,
                    Limit: 4,
                    Lang: this.state.lang,
                    MainTypeID: [1],
                    // ProvinceCode: ['83'],
                    RegionID: this.state.region_id,
                    SortBy: [
                        { "field": "View.ViewCount", "sort": -1 }
                    ],
                    Random: "Y"
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            places_data: _result.results,
            isLoadingPlace: false,
        }, () => {
            // this.get_accommodation()
        })
    }

    async get_new_places() {
        const response = await fetch(
            api_endpoint + `/maininfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: 0,
                    Limit: 4,
                    Lang: this.state.lang,
                    MainTypeID: [1],
                    ProvinceCode: [],
                    RegionID: this.state.region_id,
                    SortBy: [
                        { "field": "created_at", "sort": -1 }
                    ],
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.results.length > 0) {
            this.setState({
                places_new_data: _result.results,
                isLoadingContentPage: false
            })
        }
    }
    async get_community() {
        const response = await fetch(
            api_endpoint + `/cbt/route_get`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    language: this.state.lang,
                    numberOfResult: 3
                    // provinceName: this.state.province_name === "null" ? "" : this.state.province_name,
                    // numberOfDay: this.state.days_id,
                    // pageNumber: this.state.page_index,
                    // numberOfResult: this.state.maxPage,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            community_data: _result.result,
            isLoadingCommunity: false
        }, () => {
            // this.get_accommodation()
        })
    }

    async get_accommodation() {
        const response = await fetch(
            api_endpoint + `/maininfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: 0,
                    Limit: 4,
                    Lang: this.state.lang,
                    MainTypeID: [4],
                    ProvinceCode: this.state.provinces_id,
                    RegionID: this.state.region_id,
                    Random: "Y"


                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            accommodation_data: _result.results,
            isLoadingAccommodation: false,
        }, () => {
            // this.get_restaurant()
        })
    }

    async get_restaurant() {
        const response = await fetch(
            api_endpoint + `/maininfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: 0,
                    Limit: 4,
                    Lang: this.state.lang,
                    MainTypeID: [6],
                    ProvinceCode: this.state.provinces_id,
                    RegionID: this.state.region_id,
                    Random: "Y"


                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            restaurant_data: _result.results,
            isLoadingRestaurant: false,
        }, () => {
            // this.get_shop()
        })
    }

    async get_shop() {
        const response = await fetch(
            api_endpoint + `/maininfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: 0,
                    Limit: 4,
                    Lang: this.state.lang,
                    MainTypeID: [7],
                    ProvinceCode: this.state.provinces_id,
                    RegionID: this.state.region_id,
                    Random: "Y"


                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            shop_data: _result.results,
            isLoadingShop: false,
        }, () => {
            // this.get_count_all()
        })
    }

    async get_news() {
        const response = await fetch(
            api_endpoint + `/contentinfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: this.state.skip,
                    Limit: this.state.limit,
                    Lang: this.state.lang,
                    ContentCategoryID: [2]


                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            news_data: _result.results,
            isLoadingNews: false
        }, () => {
            // this.get_content_page()
        })
    }

    async get_count_all() {
        const response = await fetch(
            api_endpoint + `/maininfo/count_all`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({

                })
            },
        )
        var _result = await response.json();
        this.setState({
            count_activities: _result.activities,
            count_attractions: _result.attractions,
            count_routes: _result.routes,
            count_touroperators: _result.touroperators,
            isLoadingCount_all: false,
        }, () => {
            // this.get_route()
        })
    }

    async get_campaign() {
        const response = await fetch(
            api_endpoint + `/contentinfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: 0,
                    Limit: this.state.limit,
                    Lang: this.state.lang,
                    ContentCategoryID: [4]


                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            campaign_data: _result.results,
            isLoadingCampaign: false
        }, () => {
            // this.get_content_page()
        })
    }

    async get_route() {
        const response = await fetch(
            api_endpoint + `/maininfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: 0,
                    Limit: 15,
                    Lang: this.state.lang,
                    MainTypeID: [9],
                    ProvinceCode: this.state.provinces_id,
                    RegionID: this.state.region_id,


                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            route_data: _result.results,
            isLoadingRoute: false
        }, () => {
            // this.get_content_story()
        })
    }

    async get_content_story() {
        const response = await fetch(
            api_endpoint + `/contentinfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: this.state.skip,
                    Limit: 4,
                    Lang: this.state.lang,
                    ContentCategoryID: [1]


                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            content_data_story: _result.results,
            isLoadingContentStory: false
        }, () => {
            // this.get_content_page()
        })
    }

    async get_content_page() {

        const response = await fetch(
            api_endpoint + `/contentinfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Skip: this.state.skip,
                    Limit: this.state.limit,
                    Lang: this.state.lang,
                    ContentCategoryID: [3]


                }), // body data type must match "Content-Type" header
            }
        );

        var _result = await response.json();
        this.setState({
            content_data_page: _result.results,
            // isLoadingContentPage: false
        })
    }


    componentDidMount() {
        this.get_new_places()
        this.get_activity()
        this.get_places()
        this.get_community()
        this.get_accommodation()
        this.get_restaurant()
        this.get_shop()
        this.get_news()
        this.get_count_all()
        this.get_route()
        this.get_content_story()
        this.get_content_page()
        // this.get_campaign()

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    search(value, data, page) {
        this.setState({
            isSearching: value,
            searchingValue: data,
            searchingPage: page
        })
    }


    getSearchPage(searchingPage) {
        // this.state.isSearching = false
        // this.state.searchingPage = ''
        switch (searchingPage) {
            case '1':
                // return <Redirect to={{ pathname: '/attractions', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/' + this.state.lang + '/attractions' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '100':
                // return <Redirect to={{ pathname: '/attractions', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/attractions/agriculture' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '2':
                // return <Redirect to={{ pathname: '/activities', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/activities' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '3':
                // return <Redirect to={{ pathname: '/touroperators', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/touroperators' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '4':
                // return <Redirect to={{ pathname: '/accommodations', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/accommodations?search=' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '5':
                // return <Redirect to={{ pathname: '/spas', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/spas' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '6':
                // return <Redirect to={{ pathname: '/restaurant', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/restaurants' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '7':
                // return <Redirect to={{ pathname: '/store', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/stores' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '9':
                // return <Redirect to={{ pathname: '/travel', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/routes?search=' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            case '10':
                // return <Redirect to={{ pathname: '/travel', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/community_routes?search=' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
            default:
                // return <Redirect to={{ pathname: '/attractions', 
                //     state: { search: this.state.searchingValue, searching: this.search } 
                // }} />
                return <Redirect to={'/attractions?search=' + (this.state.searchingValue !== '' ? '?search=' + this.state.searchingValue : '')} />
        }
    }

    render() {
        // console.log(this.state.route_data)
        // console.log(this.state.count_all)
        // console.log(this.state.places_data)
        // console.log(this.state.campaign_data)
        // console.log(this.state.news_data)
        if (this.state.isLoadingActivity === true ||
            this.state.isLoadingPlace === true ||
            // this.state.isLoadingCommunity === true ||
            this.state.isLoadingAccommodation === true ||
            this.state.isLoadingRestaurant === true ||
            this.state.isLoadingShop === true ||
            // this.state.isLoadingNews === true ||
            // this.state.isLoadingCount_all === true ||
            // this.state.isLoadingCampaign === true ||
            // this.state.isLoadingRoute === true ||
            this.state.isLoadingContentStory === true ||
            this.state.isLoadingContentPage === true) {

            return <div style={{ marginTop: 200 }} className="text-center" >
                <FadeIn>
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <Card>
                            <CardBody>
                                <Lottie options={defaultOptions} height={300} width={300} />
                                <div>
                                    <h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
                                </div>

                            </CardBody>
                        </Card>

                    </div>

                </FadeIn>
            </div>

        }
        else {
            if (this.state.isSearching === true) {
                return this.getSearchPage(this.state.searchingPage)
            }
            else {
                return <div>
                    <Helmet>
                        <title>Thailand Tourism Directory - Digital Tourism</title>
                        <meta name="description" content={this.props.t('up-to-date, Trusted information source from government organizations') + " " +
                            this.props.t('Complete, Accurate') + " " + this.props.t('New Standard of Thailand Tourism Information')} />

                        <meta name="facebook-domain-verification" content="wh835cw7fflwdvo7hwd1s1av72mlbu" />
                        <meta property="og:url" content="https://thailandtourismdirectory.go.th/" />
                        <meta property="og:title" content="Thailand Tourism Directory - Digital Tourism" />
                        <meta property="og:description" content={this.props.t('up-to-date, Trusted information source from government organizations') + " " +
                            this.props.t('Complete, Accurate') + " " + this.props.t('New Standard of Thailand Tourism Information')} />
                        <meta property="og:image" content="https://thailandtourismdirectory.go.th/assets/img/ttd-facebook-image.png" />
                        <meta itemprop="name" content="Thailand Tourism Directory - Digital Tourism" />
                        <meta itemprop="description" content={this.props.t('up-to-date, Trusted information source from government organizations') + " " +
                            this.props.t('Complete, Accurate') + " " + this.props.t('New Standard of Thailand Tourism Information')} />
                        <meta itemprop="image" content="https://thailandtourismdirectory.go.th/assets/img/ttd-facebook-image.png" />

                    </Helmet>

                    <Navbar search={this.search} />
                    <Banner />
                    <Search search={this.search} lang={this.state.lang} />
                    <Intro />
                    {/* <Province /> */}
                    <Tourist place={this.state.places_data} lang={this.state.lang} />
                    <NewTourist place={this.state.places_new_data} lang={this.state.lang} />
                    {!this.state.isLoadingCommunity &&
                        <Community community={this.state.community_data} lang={this.state.lang} />
                    }

                    <Accommodation accommodation={this.state.accommodation_data} lang={this.state.lang} />
                    <Restaurant restaurant={this.state.restaurant_data} lang={this.state.lang} />
                    <Store shop={this.state.shop_data} lang={this.state.lang} />
                    {!this.state.isLoadingNews &&
                        <News news={this.state.news_data} lang={this.state.lang} />
                    }

                    {!this.state.isLoadingCount_all &&
                        <Counter count_activities={this.state.count_activities} count_attractions={this.state.count_attractions} count_routes={this.state.count_routes} count_touroperators={this.state.count_touroperators} />
                    }
                    <Activities place={this.state.activitys_data} lang={this.state.lang} />
                    {!this.state.isLoadingRoute &&
                        <BlogTravel route={this.state.route_data} lang={this.state.lang} />
                    }
                    {/* {!this.state.isLoadingContentStory && */}
                    <CardTravel content_story={this.state.content_data_story} lang={this.state.lang} />
                    {/* } */}
                    <Recommend content_page={this.state.content_data_page} lang={this.state.lang} />
                    {/* <Campaign campaign={this.state.campaign_data} lang={this.state.lang} /> */}
                    {/* <Video />
                <Review />
                <BlogSection /> */}
                    <Ads />
                    {/* <Subscribe /> */}
                    <Footer />
                </div>
            }

        }
    }
}

export default withTranslation()(Home_V1);

