import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';
import Navbar from './global-components/navbar';
import PageHeaderAccommodation from './global-components/page-header-accommodation';
import { withTranslation } from 'react-i18next';
import Footer from './global-components/footer';
import { api_endpoint } from '../global-config'
import vecterlocation from '../assets/images/icon/location.svg'
import vecterlove from '../assets/images/icon/love.svg'
// import clock from "../assets/images/icon/bi_clock.svg"
// import check from "../assets/images/icon/check.svg"
// import User from "../assets/images/icon/User.svg"
// import User1 from "../assets/images/icon/User1.png"
import edit from "../assets/images/icon/edit.svg"
// import feather_edit from "../assets/images/icon/feather_edit.svg"
import '../components/cssnew.css'
import facebook from "../assets/images/icon/facebook1.svg"
import locations from "../assets/images/icon/mark.svg"
import phone from "../assets/images/icon/telephone.svg"
import mail from "../assets/images/icon/email.svg"
import line from "../assets/images/icon/line1.svg"
import Twitter from "../assets/images/icon/twitter.svg"
import YouTube from "../assets/images/icon/youtube.svg"
import ContactName from "../assets/images/icon/name.svg"
// import Gmap from "../assets/images/icon/map.png"
import Map from "../assets/images/icon/map.svg"
import website from "../assets/images/icon/website.svg"
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import star from "../assets/images/icon/star.svg"
import FadeIn from "react-fade-in";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import ReactStars from "react-rating-stars-component";
import './cssnew.css'
import * as loadingData from "../loadingttd.json";
import Lottie from "react-lottie";
// import { Link } from 'react-router-dom'
import { GoogleApiWrapper } from "google-maps-react";
import Share from "../assets/images/icon/Share.svg"
import Vectorgologin from "../assets/images/icon/Vectorgologin.svg"
import dataver from "../assets/images/icon/dataver.svg"
import dataofof from "../assets/images/icon/dataofof.svg"
import dataof from "../assets/images/icon/dataof.svg"
// import Parser from 'html-react-parser';
import moment from 'moment';
import {
    EmailShareButton,
    FacebookShareButton,

    LineShareButton,
    LinkedinShareButton,

    TwitterShareButton,

    WhatsappShareButton,

} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,

    LineIcon,
    LinkedinIcon,

    TwitterIcon,

    WhatsappIcon,
} from "react-share";
// import MapsDetail from './section-components/maps'
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
import Interweave, { Node } from 'interweave';

import ModalComment from "./section-components/modal-comment"
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
// const containerStyle = {
//     position: 'relative',
//     width: '100%',
//     height: '100%',
//     borderRadius: '10px'
// }

class AccommodationDetailsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            place_id: props.match.params.id,
            place_details: {},
            isLoading: true,
            lang: this.props.i18n.language,
            original: [],
            scroll: "",
            modal_control: false,
            rating: '',
            comment: "",
            comment_count: 0,
            comment_data: [],
            matches: window.matchMedia("(min-width: 992px)").matches,
            isLogin: localStorage.getItem('user_data'),
            username: null,
            isFavourite: false,

        }
        this.login_check = this.login_check.bind(this);
        this.copy = this.copy.bind(this);
    }
    showMap() {
        this.setState({ showLightbox: true })
    }

    copy() {
        this.elRef.select();
        document.execCommand("copy");
    }
    ratingChanged(newRating) {

        this.setState({
            rating: newRating
        })
    };

    //เรียกข้อมูลสถานที่
    async get_place_detail() {
        const response = await fetch(
            api_endpoint + `/maininfo/readone`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ID: this.state.place_id,
                    // Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        let arr = []
        console.log(_result.result.Images)
        _result.result.Images.map((e, i) => {
            return arr.push({ original: e, thumbnail: e, originalAlt: _result.result.Name[this.state.lang] })
        })
        console.log(arr)
        this.setState({
            place_details: _result.result,
            original: arr
        }, () => {
            this.comment_get()
        })
    }

    async comment_add(e) {
        let formData = new FormData()
        formData.append('id', this.state.place_id)
        formData.append('star', this.state.rating)
        formData.append('comment', this.state.comment)
        // formData.append('review_pics', '')
        console.log(formData)
        const response = await fetch(
            api_endpoint + `/maininfo/review/add`,
            {
                method: "POST",
                headers: {
                    Authorization: 'bearer ' + JSON.parse(localStorage.getItem('user_data')).token
                },
                body: formData
            }
        );
        var _result = await response.json();

        console.log(_result)
        console.log('comment: ' + this.state.comment);
        e.preventDefault()
        setTimeout(() => {
            alert('แสดงความคิดเห็นเรียบร้อย');
            window.location.reload(1);
        }, 100);

    }

    async comment_get() {
        var jsonBody = {
            'id': this.state.place_id,
            'skip': 0,
            'take': 5
        }
        if (localStorage.getItem('user_data')) {
            jsonBody['user_id'] = JSON.parse(localStorage.getItem('user_data')).UserID
        }
        const response = await fetch(
            api_endpoint + '/maininfo/review/get',
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(jsonBody)
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === 'success') {
            this.setState({
                comment_data: _result.data,
                isLoading: false
            })
        }
        else {
            console.log('error')
            return
        }

    }

    async check_favoutite() {
        if (this.state.isLogin === null) {
            this.setState({
                isFavourite: false
            })
        }
        else {
            const response = await fetch(
                api_endpoint + `/user/favourite/check`,
                {
                    method: "POST",
                    headers: {
                        Authorization: 'bearer ' + JSON.parse(localStorage.getItem('user_data')).token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        Username: JSON.parse(localStorage.getItem('user_data')).Username,
                        MainInfoID: Number(this.state.place_id)
                    }),
                }
            );
            var _result = await response.json();
            console.log(_result)
            if (_result.Favourite === "Y") {
                this.setState({
                    isFavourite: true
                })
            }
        }
    }

    async favourite_add() {
        if (this.state.isFavourite) {
            const response = await fetch(
                api_endpoint + `/user/favourite/remove`,
                {
                    method: "POST",
                    headers: {
                        Authorization: 'bearer ' + JSON.parse(localStorage.getItem('user_data')).token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        Username: JSON.parse(localStorage.getItem('user_data')).Username,
                        MainInfoID: Number(this.state.place_id)
                    }),
                }
            );
            var _result = await response.json();
            if (_result.status === "ok") {
                console.log(_result.message)
                this.setState({
                    isFavourite: false
                })
            } else {
                alert('error')
            }
        }
        else {
            const response = await fetch(
                api_endpoint + `/user/favourite/add`,
                {
                    method: "POST",
                    headers: {
                        Authorization: 'bearer ' + JSON.parse(localStorage.getItem('user_data')).token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        Username: JSON.parse(localStorage.getItem('user_data')).Username,
                        MainInfoID: Number(this.state.place_id)
                    }),
                }
            );
            var _result = await response.json();
            if (_result.status === "ok") {
                console.log(_result.message)
                this.setState({
                    isFavourite: true
                })
            } else {
                alert('error')
            }
        }
    }

    modal_control() {
        this.setState({
            modal_control: true
        }, () => {
            console.log(this.state.modal_control)
            return this.state.modal_control
        })
    }
    rating_render(value) {
        console.log(value)

        if (parseInt(value) === 0) {
            return (
                <div>
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                </div>
            )
        }
        if (parseInt(value) === 1) {
            return (
                <div>
                    <i className="ic-yellow fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                </div>
            )
        }
        if (parseInt(value) === 2) {
            return (
                <div>
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                </div>
            )
        }
        if (parseInt(value) === 3) {
            return (
                <div>
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                </div>
            )
        }
        if (parseInt(value) === 4) {
            return (
                <div>
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="fa fa-star" />
                </div>
            )
        }
        if (parseInt(value) === 5) {
            return (
                <div>
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                    <i className="ic-yellow fa fa-star" />
                </div>
            )
        }
    }
    login_check() {
        this.setState({
            isLogin: localStorage.getItem("user_data")
        })
    }

    componentDidMount() {
        if (this.state.place_id === undefined) {
            this.props.history.goBack()
        }
        else {
            const language = localStorage.getItem("I18N_LANGUAGE")
            if (!language) {
                localStorage.setItem("I18N_LANGUAGE", "th")
            }
            console.log(language)
            this.get_place_detail()
            this.check_favoutite()
            console.log(this.state.isFavourite)
            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        }
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        this.setState({
            isLoading: true,
            lang: nextProps.i18n.language
        }, () => {

            this.get_place_detail()

            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        })


    }
    render() {
        const images = this.state.original;
        console.log(this.state.place_details)
        // console.log(this.state.comment_data)
        if (this.state.isLoading === true) {
            return (
                <div style={{ marginTop: 200 }} className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div className="card">
                                <div className="cardbody">
                                    <Lottie options={defaultOptions} height={300} width={300} />
                                    <div>
                                        <h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </FadeIn>
                </div>
            )
        }
        else {
            let publicUrl = process.env.PUBLIC_URL + '/'
            const Moredetails = this.state.place_details.MoreDetail[this.state.lang] === "" ? this.state.place_details.MoreDetail.th : this.state.place_details.MoreDetail[this.state.lang]
            // close js 'types' can only be used in a .ts file - Visual Studio Code using @ts-check
            function transform(node: HTMLElement, children: Node[]): React.ReactNode {
                if (node.tagName.toLowerCase() === 'p') {
                    return <p className="normal-text cl-black" style={{ margin: 0, textAlign: 'justify' }}>{children}</p>;
                }
            }
            return (
                <div>
                    <Helmet>
                        <title>{this.state.place_details.Name[this.state.lang] === null || this.state.place_details.Name[this.state.lang] === undefined ?
                            this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]}</title>
                        <meta name="description" content={this.state.place_details.Detail[this.state.lang] === null || this.state.place_details.Detail[this.state.lang] === undefined ?
                            this.state.place_details.Detail.th : this.state.place_details.Detail[this.state.lang]} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:title" content={this.state.place_details.Name[this.state.lang] === null || this.state.place_details.Name[this.state.lang] === undefined ?
                            this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]} />
                        <meta property="og:description" content={this.state.place_details.Detail[this.state.lang] === null || this.state.place_details.Detail[this.state.lang] === undefined ?
                            this.state.place_details.Detail.th : this.state.place_details.Detail[this.state.lang]} />
                        <meta property="og:image" content={images[0].original} />
                        <meta itemprop="name" content={this.state.place_details.Name[this.state.lang] === null || this.state.place_details.Name[this.state.lang] === undefined ?
                            this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]} />
                        <meta itemprop="description" content={this.state.place_details.Detail[this.state.lang] === null || this.state.place_details.Detail[this.state.lang] === undefined ?
                            this.state.place_details.Detail.th : this.state.place_details.Detail[this.state.lang]} />
                        <meta itemprop="image" content={images[0].original} />
                    </Helmet>
                    <div className="menu-back-page animated fadeIn">
                        <span className="back-top">
                            <a hrefLang={this.state.lang} href={"/" + this.state.lang + "/accommodations"} className="normal-text-18-detail"><i className="fa fa-angle-left" style={{ fontSize: 22 }}><span className="large-text-20" style={{ padding: "0px 0px 0px 10px" }}>{this.props.t('Back')}</span></i></a>
                        </span>
                    </div>
                    <Navbar modal_control={this.state.modal_control} login_check={this.login_check} />
                    <PageHeaderAccommodation headertitle={this.state.place_details.Name[this.state.lang] === "" || this.state.place_details.Name[this.state.lang] === undefined ?
                        this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]} />
                    <div>
                        {/* แถบตอนย่อจอ */}
                        {!this.state.matches && (
                            <div style={{ padding: 20 }}>
                                <div>
                                    <ImageGallery items={images}
                                        showThumbnails={false}
                                        infinite={true}
                                        showIndex={true}
                                    />
                                </div>
                                {/* ชื่อสถานที่และที่ตั้ง */}
                                <div style={{ marginTop: 26 }}>
                                    <p className="large-text-36 cl-black dp">{this.state.place_details.Name[this.state.lang] === "" || this.state.place_details.Name[this.state.lang] === undefined ?
                                        this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]}</p>
                                    <div className="row" style={{ margin: 0, marginTop: 10 }}>
                                        <div className="pd-r-15" style={{ display: 'flex' }}><img src={vecterlocation} style={{ paddingRight: 10, height: '25px' }} alt="" /><p className="normal-text-18-detail cl-blue">{this.props.t('SubDistrict')}: {this.state.place_details.SubDistrict[this.state.lang]}</p></div>
                                        <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.props.t('District')}: {this.state.place_details.District[this.state.lang]}</p></div>
                                        <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.props.t('Province')}: {this.state.place_details.Province[this.state.lang]}</p></div>
                                        <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.state.place_details.PostCode}</p></div>
                                    </div>
                                </div>
                                {/* ปุ่มบันทุก / แชร์ / แจ้งแก้ไข */}
                                <div className="row" style={{ marginTop: 20 }}>
                                    {this.state.isLogin === null ?
                                        <div className="col-sm-6 col-12"></div>
                                        :
                                        <div className="col-sm-6 col-12" >
                                            <button className="Buttonsavesmall" onClick={() => {
                                                let user_data = localStorage.getItem('user_data')
                                                if (user_data === null) {
                                                    // console.log('no log')
                                                    this.modal_control()
                                                }
                                                else {
                                                    this.favourite_add()
                                                }
                                            }}><img src={vecterlove} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t(this.state.isFavourite ? 'Favourited' : 'Favourite')}</button>
                                        </div>
                                    }
                                    {this.state.isLogin === null ?
                                        <div className="col-sm-12 col-12 button-details">
                                            <button className="Buttonshare" data-toggle="modal" data-target="#exampleModalCenter" style={{ cursor: 'pointer' }}><img src={Share} style={{ paddingRight: 10, height: '15px', paddingLeft: 10 }} alt="" /><span style={{ paddingRight: 10 }}>{this.props.t('Share information')}</span></button>
                                        </div>
                                        :
                                        <div className="col-sm-6 col-12 button-details">
                                            <button className="Buttonshare" data-toggle="modal" data-target="#exampleModalCenter" style={{ cursor: 'pointer' }}><img src={Share} style={{ paddingRight: 10, height: '15px', paddingLeft: 10 }} alt="" /><span style={{ paddingRight: 10 }}>{this.props.t('Share information')}</span></button>
                                        </div>
                                    }
                                </div>
                                <div className="card card-color" style={{ borderRadius: 15, marginTop: 34 }}>
                                    <div className="card-body">
                                        {/* <div className="pd-bt-30" style={{ marginTop: 34 }}>
                                            {this.state.place_details.IsOpen === 0 ?
                                                <h5 className="mg-top-card open-text cl-gray"><span data-nosnippet>{this.props.t('Closed')}</span></h5>
                                                :
                                                <h5 className="mg-top-card open-text cl-green"><img src={clock} style={{ paddingRight: 10, height: '30px', }} alt="" /><span data-nosnippet>{this.props.t('Open')}</span></h5>
                                            }
                                        </div> */}
                                        {/* ข้อมูลที่เป็นหน่วยงานของรัฐ */}
                                        {this.state.place_details.Official === 0 ?
                                            <div className="row pd">
                                                <div className="col-md-3 col-sm-3 col-4 al-self" style={{ textAlign: 'center' }}>
                                                    <img className="" src={dataofof} alt="" />
                                                </div>
                                                <div className="col-md-9 col-sm-9 col-8">
                                                    <p className="normal-text ">{this.props.t('Official Information of Government Agencies')}:</p>
                                                    <p className="normal-text " style={{ color: "#173865" }}>{this.props.t('Not official information')}</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="row pd">
                                                <div className="col-md-3 col-sm-3 col-4 al-self" style={{ textAlign: 'center' }}>
                                                    <img className="" src={dataof} alt="" />
                                                </div>
                                                <div className="col-md-9 col-sm-9 col-8">
                                                    <p className="normal-text ">{this.props.t('Official Information of Government Agencies')} :</p>
                                                    <p className="normal-text " style={{ color: "#173865" }}>{this.props.t('Department of Tourism')}</p>
                                                </div>
                                            </div>
                                        }
                                        <hr className=""></hr>
                                        <div className="row pd">
                                            <div className="col-md-3 col-sm-3 col-4 al-self" style={{ textAlign: 'center' }}>
                                                <img className="" src={dataver} alt="" />
                                            </div>
                                            <div className="col-md-9 col-sm-9 col-8">
                                                {/* {this.state.place_details.UpdatedEditorTeam.length < 1 ?
                                                    <div>

                                                    </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 col-12">
                                                            <p className="normal-text">{this.props.t('Update information')} : </p><p style={{ color: "#173865" }}>{this.state.place_details.UpdatedEditorTeam}</p>
                                                        </div>
                                                    </div>
                                                } */}
                                                {this.state.place_details.SourceDepartment.length < 1 ?
                                                    <div>

                                                    </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 col-12">
                                                            <p className="normal-text">{this.props.t('Affiliation')} : </p><p style={{ color: "#173865" }}>{this.state.place_details.SourceDepartment.th}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <hr className=""></hr>
                                        {/* ข้อมูลติดต่อ */}
                                        <div style={{ marginTop: 20 }}>
                                            <h6 className="card-subtitle topic-text pd-bt-15" >{this.props.t('Contact us')}</h6>
                                            {this.state.place_details.ContactName[this.state.lang] !== "" ?
                                                <div>
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={ContactName} style={{ paddingRight: 17, height: 17 }} alt="" />
                                                        {/* {this.props.t('Contact Name')} {this.state.place_details.ContactName[this.state.lang]} */}
                                                        {this.state.place_details.ContactName[this.state.lang]}
                                                        {this.state.place_details.ContactPosition[this.state.lang] !== "" ?
                                                            <span style={{ marginLeft: 5 }}>
                                                                <span>(</span>{this.state.place_details.ContactPosition[this.state.lang]}<span>)</span>
                                                            </span>
                                                            : this.state.place_details.ContactPosition.th !== "" ?
                                                                <span>
                                                                    <span>(</span>{this.state.place_details.ContactPosition.th}<span>)</span>
                                                                </span>
                                                                :
                                                                <span></span>
                                                        }
                                                    </p>
                                                </div>
                                                : this.state.place_details.ContactName.th !== "" ?
                                                    <div>
                                                        <p className="normal-text pd-bt-15">
                                                            <img src={ContactName} style={{ paddingRight: 17, height: 17 }} alt="" />
                                                            {/* {this.props.t('Contact Name')} {this.state.place_details.ContactName.th} */}
                                                            {this.state.place_details.ContactName.th}
                                                            {this.state.place_details.ContactPosition[this.state.lang] !== "" ?
                                                                <span style={{ marginLeft: 5 }}>
                                                                    <span>(</span>{this.state.place_details.ContactPosition[this.state.lang]}<span>)</span>
                                                                </span>
                                                                : this.state.place_details.ContactPosition.th !== "" ?
                                                                    <span>
                                                                        <span>(</span>{this.state.place_details.ContactPosition.th}<span>)</span>
                                                                    </span>
                                                                    :
                                                                    <span></span>
                                                            }
                                                        </p>
                                                    </div>
                                                    :
                                                    <div>

                                                    </div>
                                            }
                                            {this.state.place_details.Address[this.state.lang] !== "" ?
                                                <div>
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={locations} style={{ paddingRight: 17, height: 27 }} alt="" />
                                                        {/* {this.props.t('Address')} {this.state.place_details.Address[this.state.lang]} */}
                                                        {this.state.place_details.Address[this.state.lang]}
                                                    </p>
                                                </div>
                                                : this.state.place_details.Address.th !== "" ?
                                                    <div>
                                                        <p className="normal-text pd-bt-15">
                                                            <img src={locations} style={{ paddingRight: 17, height: 27 }} alt="" />
                                                            {/* {this.props.t('Address')} {this.state.place_details.Address.th} */}
                                                            {this.state.place_details.Address.th}
                                                        </p>
                                                    </div>
                                                    :
                                                    <div>

                                                    </div>
                                            }
                                            {this.state.place_details.Mobile.length < 1 ?
                                                <div>

                                                </div>
                                                :
                                                <div>
                                                    {/* <p className="normal-text pd-bt-15"><img src={phone} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Phone number')} {this.state.place_details.Mobile}</p> */}
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={phone} style={{ paddingRight: 18, height: 19 }} alt="" />
                                                        {this.state.place_details.Mobile}
                                                    </p>
                                                </div>
                                            }
                                            {this.state.place_details.LineID.length < 1 ?
                                                <div>

                                                </div>
                                                :
                                                <div>
                                                    {/* <p className="normal-text pd-bt-15"><img src={line} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Line')} {this.state.place_details.LineID}</p> */}
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={line} style={{ paddingRight: 13, height: 24, marginLeft: -1 }} alt="" />
                                                        {this.state.place_details.LineID}
                                                    </p>
                                                </div>
                                            }
                                            {this.state.place_details.Email.length < 1 ?
                                                <div>

                                                </div>
                                                :
                                                <div>
                                                    {/* <p className="normal-text pd-bt-15"><img src={mail} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Email')} {this.state.place_details.Email}</p> */}
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={mail} style={{ paddingRight: 15, height: 20 }} alt="" />
                                                        {this.state.place_details.Email}
                                                    </p>
                                                </div>
                                            }
                                            {this.state.place_details.Website === null || this.state.place_details.Website === "" ?
                                                <div>

                                                </div>
                                                :
                                                <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                    {/* <p className="normal-text"><img src={website} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Website')}</p> */}
                                                    <p className="normal-text pd-bt-15">
                                                        <img src={website} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                    </p>
                                                    <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.Website) }}>
                                                        <span className="normal-text">{this.props.t('Visit Website')}</span>
                                                    </button>
                                                </div>
                                            }
                                            {this.state.place_details.FacebookUrl === null || this.state.place_details.FacebookUrl === "" ?
                                                <div>

                                                </div>
                                                :
                                                <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                    {/* <p className="normal-text"><img src={facebook} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Facebook')}</p> */}
                                                    <p className="pd-bt-15">
                                                        <img src={facebook} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                    </p>
                                                    <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.FacebookUrl) }}>
                                                        <span className="normal-text">{this.props.t('Facebook Page')}</span>
                                                    </button>
                                                </div>
                                            }
                                            {this.state.place_details.Twitter === null || this.state.place_details.Twitter === "" ?
                                                <div>

                                                </div>
                                                :
                                                <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                    {/* <p className="normal-text"><img src={Twitter} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Twitter')}</p> */}
                                                    <p className="pd-bt-15">
                                                        <img src={Twitter} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                    </p>
                                                    <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.Twitter) }}>
                                                        <span className="normal-text">{this.props.t('Twitter')}</span>
                                                    </button>
                                                </div>
                                            }
                                            {this.state.place_details.VdoYoutubeUrl === null || this.state.place_details.VdoYoutubeUrl === "" ?
                                                <div
                                                ></div>
                                                :
                                                <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                    {/* <p className="normal-text"><img src={YouTube} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Video Introduction')}</p> */}
                                                    <p className="pd-bt-15">
                                                        <img src={YouTube} style={{ paddingRight: 7, marginLeft: -1, height: 30 }} alt="" />
                                                    </p>
                                                    <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.VdoYoutubeUrl) }}>
                                                        <span className="normal-text">{this.props.t('Video Introduction')}</span>
                                                    </button>
                                                </div>
                                            }
                                            {this.state.place_details.MapImage === null || this.state.place_details.MapImage === "" ?
                                                <div>

                                                </div>
                                                :
                                                <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                    {/* <p className="normal-text"><img src={Map} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Map Image')}</p> */}
                                                    <p className="pd-bt-15">
                                                        <img src={Map} style={{ paddingRight: 12, paddingLeft: 5, height: 24 }} alt="" />
                                                    </p>
                                                    <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { this.showMap() }} >
                                                        <span className="normal-text">{this.props.t('Click to view image')}</span>
                                                    </button>
                                                    {this.state.showLightbox ?
                                                        <Lightbox image={this.state.place_details.MapImage} title={this.state.place_details.Name[this.state.lang]} onClose={() => this.setState({ showLightbox: false })} /> : null}
                                                </div>
                                            }
                                        </div>
                                        {/* <div className="row" style={{ display: 'flex', margin: 0 }}>
                                            <p className="pd-bt-15">
                                                <img src={Gmap} style={{ paddingRight: 11, height: 24 }} alt="" />
                                            </p>
                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open("https://maps.google.com/?q=" + this.state.place_details.Latitude + "," + this.state.place_details.Longitude) }}>
                                                <span className="normal-text">{this.props.t('Map')}</span>
                                            </button>
                                        </div> */}
                                        <hr className=""></hr>
                                        <div style={{ marginTop: 20 }}>
                                            <h6 className="card-subtitle topic-text pd-bt-15">{this.props.t('Map')}</h6>
                                            <div className="map-style">
                                                <img className="map" src={publicUrl + "assets/img/map2.png"} alt=""
                                                    onClick={() => { window.open("https://maps.google.com/?q=" + this.state.place_details.Latitude + "," + this.state.place_details.Longitude) }}>
                                                </img>
                                            </div>
                                        </div>
                                        {/* แผนที่ */}
                                        {/* <div style={{ marginTop: 20 }}> */}
                                        {/* <h6 className="card-subtitle topic-text pd-bt-15" ><a href={"https://maps.google.com/?q="+this.state.place_details.Latitude+","+this.state.place_details.Longitude } target="_blank">{this.props.t('Map')}</a></h6> */}
                                        {/* <MapsDetail maps_lat={this.state.place_details.Latitude} maps_lng={this.state.place_details.Longitude}></MapsDetail> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                                {/* ข้อมูลสถานที่ */}
                                {this.state.place_details.Detail[this.state.lang] !== "" ?
                                    <div style={{ marginTop: 20 }}>
                                        <h5 className="topic-text pd-bt-15">{this.props.t('Description')}</h5>
                                        <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15, whiteSpace: 'pre-line' }}>{this.state.place_details.Detail[this.state.lang]}</p>
                                        <hr></hr>
                                    </div>
                                    : this.state.place_details.Detail.th !== "" ?
                                        <div style={{ marginTop: 20 }}>
                                            <h5 className="topic-text pd-bt-15">{this.props.t('Description')}</h5>
                                            <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.Detail.th}</p>
                                            <hr></hr>
                                        </div>
                                        :
                                        <div>

                                        </div>
                                }
                                {/* ข้อมูลเพิ่มเติม */}
                                {this.state.place_details.MoreDetail[this.state.lang] !== "" ?
                                    <div>
                                        <h5 className="topic-text pd-bt-15">{this.props.t('More information')}</h5>
                                        <Interweave content={Moredetails} transform={transform} />
                                        {/* <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.MoreDetail[this.state.lang]}</p> */}
                                        <div><hr></hr></div>
                                    </div>
                                    : this.state.place_details.MoreDetail.th !== "" ?
                                        <div>
                                            <h5 className="topic-text pd-bt-15">{this.props.t('More information')}</h5>
                                            <Interweave content={Moredetails} transform={transform} />
                                            {/* <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.MoreDetail.th}</p> */}
                                            <div><hr></hr></div>
                                        </div>
                                        :
                                        <div>

                                        </div>
                                }
                                {/* ข้อมลแนะนำ */}
                                <div>
                                    <h5 className="topic-text pd-bt-15" >{this.props.t('Information')}</h5>
                                </div>
                                <div className="row">
                                    {/* การขึ้นทะเบียน */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ paddingLeft: 13 }}>
                                            <img src={publicUrl + "assets/img/icons/card-checked.png"} style={{ paddingRight: 10, height: '25px', marginTop: '5px' }} alt="" /><p className="normal-text pd-bt-15" style={{ fontWeight: 'bold' }}>{this.props.t('Registration')}</p>
                                        </div>
                                        {this.state.place_details.Accommodation.Info.AccommodationRegistrationType.th !== "" ?
                                            <p className="normal-text pd-bt-15">- {this.props.t('Registration type')} : {this.state.place_details.Accommodation.Info.AccommodationRegistrationType[this.state.lang]}</p>
                                            :
                                            <p className="normal-text pd-bt-15">- {this.props.t('Registration type')} : -</p>
                                        }
                                        {this.state.place_details.Accommodation.Info.AccommodationStandard.th !== "" ?
                                            <p className="normal-text pd-bt-15" >- {this.props.t('Standard')} : {this.state.place_details.Accommodation.Info.AccommodationStandard[this.state.lang]}</p>
                                            :
                                            <p className="normal-text pd-bt-15">- {this.props.t('Standard')} : -</p>
                                        }
                                        {this.state.place_details.Accommodation.Info.LicenseNo !== "" ?
                                            <p className="normal-text pd-bt-15" >- {this.props.t('License number')} : {this.state.place_details.Accommodation.Info.LicenseNo}</p>
                                            :
                                            <p className="normal-text pd-bt-15">- {this.props.t('License number')} : -</p>
                                        }
                                        {this.state.place_details.Accommodation.Info.LicenseDate !== null ?
                                            <p className="normal-text pd-bt-15" >- {this.props.t('Allowed Date')} : {moment(this.state.place_details.Accommodation.Info.LicenseDate).format('l')}</p>
                                            :
                                            <p className="normal-text pd-bt-15">- {this.props.t('Allowed Date')} : -</p>
                                        }
                                        {this.state.place_details.Accommodation.Info.LicenseExpireDate !== null ?
                                            <p className="normal-text pd-bt-15" >- {this.props.t('Expiration date')} : {moment(this.state.place_details.Accommodation.Info.LicenseExpireDate).format('l')}</p>
                                            :
                                            <p className="normal-text pd-bt-15">- {this.props.t('Expiration date')} : -</p>
                                        }
                                    </div>
                                    {/* จำนวนห้องพัก */}
                                    {this.state.place_details.Accommodation.Info.RoomAmount || this.state.place_details.Accommodation.Info.MeetingRoomAmount !== 0 ?
                                        <div className="col-md-6">
                                            <div className="row" style={{ paddingLeft: 13 }}>
                                                <img src={publicUrl + "assets/img/icons/card-checked.png"} style={{ paddingRight: 10, height: '25px', marginTop: '5px' }} alt="" /><p className="normal-text pd-bt-15" style={{ fontWeight: 'bold' }}>{this.props.t('Number of rooms')}</p>
                                            </div>
                                            {this.state.place_details.Accommodation.Info.RoomAmount !== 0 ?
                                                <p className="normal-text pd-bt-15">- {this.props.t('Number of rooms')} : {this.state.place_details.Accommodation.Info.RoomAmount} {this.props.t('Rooms')}</p>
                                                :
                                                <p className=""></p>
                                            }
                                            {this.state.place_details.Accommodation.Info.MeetingRoomAmount !== 0 ?
                                                <p className="normal-text pd-bt-15" >- {this.props.t('Number of meeting rooms')} : {this.state.place_details.Accommodation.Info.MeetingRoomAmount} {this.props.t('Rooms')}</p>
                                                :
                                                <p className=""></p>
                                            }
                                        </div>
                                        :
                                        <div></div>
                                    }
                                    {/* กล่มเป้าหมาย */}
                                    {this.state.place_details.Accommodation.Targets.length < 1 ?
                                        <div>
                                        </div>
                                        :
                                        <div className="col-md-6">
                                            <div className="row" style={{ paddingLeft: 13 }}>
                                                <img src={publicUrl + "assets/img/icons/targets.png"} style={{ paddingRight: 10, height: '25px', marginTop: '5px' }} alt="" /><p className="normal-text pd-bt-15" style={{ fontWeight: 'bold' }}>{this.props.t('Group of tourist')}</p>
                                            </div>
                                            {this.state.place_details.Accommodation.Targets.map((e, i) => {
                                                return (
                                                    <div key={i}>
                                                        <p className="normal-text pd-bt-15">- {e[this.state.lang]}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    {/* การเดินทาง */}
                                    {this.state.place_details.TravelTypes.Items.length < 1 ?
                                        <div>

                                        </div>
                                        :
                                        <div className="col-md-6">
                                            <div className="row" style={{ paddingLeft: 13 }}>
                                                <img src={this.state.place_details.TravelTypes.Icon} style={{ paddingRight: 10, height: '25px', marginTop: '5px' }} alt="" /><p className="normal-text pd-bt-15" style={{ fontWeight: 'bold' }}>{this.props.t('Transportations')}</p>
                                            </div>
                                            {this.state.place_details.TravelTypes.Items.map((e, i) => {
                                                return (
                                                    <div key={i}>
                                                        <p className="normal-text pd-bt-15">- {e[this.state.lang]}</p>
                                                    </div>
                                                )
                                            })}
                                            {this.state.place_details.TravelRemark[this.state.lang] !== "" ?
                                                <div>
                                                    <p className="text-left normal-text pd-bt-15" >{this.props.t('Travel Remark')}: {this.state.place_details.TravelRemark[this.state.lang]}</p>
                                                </div>
                                                : this.state.place_details.TravelRemark.th !== "" ?
                                                    <div>
                                                        <p className="text-left normal-text pd-bt-15" >{this.props.t('Travel Remark')}: {this.state.place_details.TravelRemark.th}</p>
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className="text-left normal-text pd-bt-15" >{this.props.t('Travel Remark')}: -</p>
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <hr></hr>
                                {/* สิ่งอำนวยความสะดวก */}
                                <div>
                                    <h5 className="topic-text pd-bt-15" >{this.props.t('Facilities')}</h5>
                                </div>
                                {this.state.place_details.Accommodation.Facilities === undefined ?
                                    <div>

                                    </div>
                                    :
                                    <div className="row" style={{ paddingTop: 12 }}>
                                        {Object.keys(this.state.place_details.Accommodation.Facilities).map((e, i) => {
                                            return (
                                                <div className="col-md-6">
                                                    <div key={i}>
                                                        <div className="row" style={{ paddingLeft: 13 }}>
                                                            <img src={this.state.place_details.Accommodation.Facilities[e] != null ? this.state.place_details.Accommodation.Facilities[e].Icon : null} style={{ paddingRight: 10, height: '20px', marginTop: 5 }} alt="" />
                                                            <p className="normal-text pd-bt-15 cl-black" style={{ fontWeight: 'bold' }}>{this.state.place_details.Accommodation.Facilities[e][this.state.lang]}</p>
                                                        </div>
                                                        {this.state.place_details.Accommodation.Facilities[e].SubType.map((h, j) => {
                                                            return (
                                                                <div key={j} >
                                                                    <p className="normal-text pd-bt-15"> - {h[this.state.lang] !== undefined || h[this.state.lang] !== null ? h[this.state.lang] : h.th}</p>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                <hr></hr>
                                {/* รีวิว */}
                                <div className="" >
                                    <h5 className="topic-text">{this.props.t('Reviews')}</h5>
                                </div>
                                <div className="card card-color" style={{ borderRadius: 15, marginTop: 23 }}>
                                    <div className="card-body">
                                        <div className="location-review-area pd-bt-15" >
                                            {this.state.isLogin === null || this.state.isLogin === undefined ?
                                                <div>
                                                    <div className="btn-wrapper" style={{ textAlign: 'center' }}>
                                                        <div onClick={() => {
                                                            let user_data = localStorage.getItem('user_data')
                                                            if (user_data == null) {
                                                                // console.log('no log')
                                                                this.modal_control()
                                                            }
                                                            else {
                                                                // console.log('log')
                                                            }
                                                        }} >
                                                            <div className="btn btn-transparent" style={{ cursor: 'pointer' }}><img src={Vectorgologin} style={{ paddingRight: 10 }} alt="" />{this.props.t('Please')} <span style={{ color: '#245aa3' }}>{this.props.t('login')}</span> {this.props.t('before review')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <form className="tp-form-wrap bg-gray tp-form-wrap-one pd">
                                                            <div className="row">
                                                                <div className="col-md-2 col-sm-3 col-5" >
                                                                    <div className="single-comment-wrap">
                                                                        <div className="thumb">
                                                                            {/* <img className="img-profile" src={User} />
                                                                        <img className="style-profile-facebook" src={facebook} alt="icon" /> */}
                                                                            {JSON.parse(localStorage.getItem('user_data')).FirstName === null ?
                                                                                <div style={{ textAlign: 'center', fontSize: 45, width: 85, height: 85, backgroundColor: 'orange', borderRadius: '50%' }}>
                                                                                    <label style={{ marginTop: 24, color: 'white' }}>{JSON.parse(localStorage.getItem('user_data')).Username.slice(0, 1).toUpperCase()}</label>
                                                                                </div>
                                                                                :
                                                                                <div style={{ textAlign: 'center', fontSize: 45, width: 85, height: 85, backgroundColor: 'orange', borderRadius: '50%' }}>
                                                                                    <label style={{ marginTop: 24, color: 'white' }}>{JSON.parse(localStorage.getItem('user_data')).FirstName.slice(0, 1).toUpperCase()}</label>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-10 col-sm-9 col-7 style-rating" >
                                                                    <h4 className="single-page-small-title normal-text-18-detail" style={{ padding: 0 }}><span>{JSON.parse(localStorage.getItem('user_data')).FirstName === null ? JSON.parse(localStorage.getItem('user_data')).Username : JSON.parse(localStorage.getItem('user_data')).FirstName}</span></h4>
                                                                    <div className="row">
                                                                        <div className="ml-3 cl-black" style={{ paddingRight: 10, color: '#173865' }}>{this.props.t('Rating')}</div>
                                                                    </div>
                                                                    <div className="rating-star spacing-star">
                                                                        <ReactStars
                                                                            count={5}
                                                                            onChange={(e) => { this.ratingChanged(e) }}
                                                                            size={30}
                                                                            activeColor="#ffd700"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12" style={{ paddingTop: 37 }}>
                                                                    <label className="single-input-wrap">
                                                                        <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: 0 }}>
                                                                            <span className="single-input-title normal-text-18-detail">{this.props.t('Review details')}</span>
                                                                            {this.state.comment_count === 300 ?
                                                                                <span className="single-input-title normal-text-18-detail" style={{ color: 'red' }}>{this.state.comment_count} / 300</span>
                                                                                :
                                                                                <span className="single-input-title normal-text-18-detail">{this.state.comment_count} / 300</span>
                                                                            }
                                                                        </div>
                                                                        <textarea defaultValue={""} maxLength={300} onChange={(e) => {
                                                                            this.setState({ comment: e.target.value })
                                                                            this.setState({ comment_count: e.target.value.length })
                                                                        }} />
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <ModalComment modal_comment={this.state.modal_comment} />
                                                                    {/* <a className="btn btn-blue" href="#">+ {this.props.t('Add Photo')}</a> */}
                                                                    <div className="btn float-right" style={{ backgroundColor: '#173865', width: '135px', color: 'white', fontWeight: 'bold' }} onClick={(e) => { this.comment_add(e) }}>{this.props.t('Save')}</div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* รายละเอียดรีวิว */}
                                <h5 className="topic-text reviews">{this.props.t('Review information')}</h5>
                                <div className="card card-color-reviews" style={{ backgroundColor: '#F8F8F8' }}>

                                    <div className="row card-reviews" >
                                        <div className="col-lg-4">
                                            <p className="normal-text-18 pd-bt-51" style={{ textAlign: 'center', color: '#000000', marginTop: 10 }}>{this.props.t('Average rating')}</p>
                                            <div className="row pd-bt-51" style={{ justifyContent: 'center' }}>
                                                <p className="normal-text" style={{ color: '#F4D006', fontSize: 64, alignItems: 'flex-end' }}>0</p><p style={{ color: '#F4D006', fontSize: 35, marginTop: 13 }}> /5</p>
                                            </div>
                                            <div className="row" style={{ justifyContent: 'center' }}>
                                                <p className="normal-text-18" style={{ color: '#000000' }}>{this.props.t('All Reviews')} : </p><p className="normal-text-18" style={{ color: '#F4D006', paddingLeft: 10 }}> 0 <span data-nosnippet>{this.props.t('list')}</span></p>
                                            </div>
                                        </div>
                                        <div className="col-lg-8" >
                                            <div className="row m-row-10">
                                                <div className="small-text-13 mt-text-5">5</div><span className="width-star"><img src={star} alt="" ></img></span>
                                                <div className="col-md-11 col-sm-10 col-10 mt-style-4 al-self">
                                                    <ProgressBar style={{ height: 7 }} variant="warning" now={0} />
                                                </div>
                                                <div className="small-text-13 mt-style-4">(0)</div>
                                            </div>
                                            <div className="row m-row-10">
                                                <div className="small-text-13 mt-text-5">4</div><span className="width-star"><img src={star} alt="" ></img></span>
                                                <div className="col-md-11 col-sm-10 col-10 mt-style-4 al-self">
                                                    <ProgressBar style={{ height: 7 }} variant="warning" now={0} />
                                                </div>
                                                <div className="small-text-13 mt-style-4">(0)</div>
                                            </div>
                                            <div className="row m-row-10">
                                                <div className="small-text-13 mt-text-5">3</div><span className="width-star"><img src={star} alt="" ></img></span>
                                                <div className="col-md-11 col-sm-10 col-10 mt-style-4 al-self">
                                                    <ProgressBar style={{ height: 7 }} variant="warning" now={0} />
                                                </div>
                                                <div className="small-text-13 mt-style-4">(0)</div>
                                            </div>
                                            <div className="row m-row-10">
                                                <div className="small-text-13 mt-text-5">2</div><span className="width-star"><img src={star} alt="" ></img></span>
                                                <div className="col-md-11 col-sm-10 col-10 mt-style-4 al-self">
                                                    <ProgressBar style={{ height: 7 }} variant="warning" now={0} />
                                                </div>
                                                <div className="small-text-13 mt-style-4">(0)</div>
                                            </div>
                                            <div className="row m-row-10">
                                                <div className="small-text-13 mt-text-5">1</div><span className="width-star"><img src={star} alt="" ></img></span>
                                                <div className="col-md-11 col-sm-10 col-10 mt-style-4 al-self">
                                                    <ProgressBar style={{ height: 7 }} variant="warning" now={0} />
                                                </div>
                                                <div className="small-text-13 mt-style-4">(0)</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* แสดงคอมเม้นต์ */}
                                {this.state.isLogin === null ?
                                    <div className="comments-area">
                                        <ul className="comment-list">
                                            <li>
                                                {this.state.comment_data.map((e, i) => {
                                                    // console.log(e.user.Username)
                                                    // console.log(this.state.comment_data)
                                                    return (
                                                        <div key={i}>
                                                            {e.comment && (e.IsEnabled === 'Y' || e.user.Username === JSON.parse(localStorage.getItem('user_data')).Username) ?
                                                                <div className="single-comment-wrap" style={{ marginBottom: 20 }}>
                                                                    <div className="thumb" style={{ backgroundColor: '#ffa500' }}>
                                                                        <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
                                                                            <label style={{ fontSize: 30 }}>{e.user.Username.slice(0, 1).toUpperCase()}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <h4 className="title">{e.user.FirstName === null ? e.user.Username : e.user.FirstName}</h4>
                                                                        </div>
                                                                        {this.rating_render(e.star)}
                                                                        <p>{e.comment}</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div></div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    <div className="comments-area">
                                        <ul className="comment-list">
                                            <li>
                                                {this.state.comment_data.map((e, i) => {
                                                    // console.log(e.user.Username)
                                                    // console.log(this.state.comment_data)
                                                    return (
                                                        <div key={i}>
                                                            {e.comment && e.IsEnabled === "Y" &&
                                                                <div className="single-comment-wrap" style={{ marginBottom: 20 }}>
                                                                    <div className="thumb" style={{ backgroundColor: '#ffa500' }}>
                                                                        <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
                                                                            <label style={{ fontSize: 30 }}>{e.user.Username.slice(0, 1).toUpperCase()}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <h4 className="title">{e.user.FirstName === null ? e.user.Username : e.user.FirstName}</h4>
                                                                        </div>
                                                                        {this.rating_render(e.star)}
                                                                        <p>{e.comment}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {e.comment && e.user.Username === JSON.parse(localStorage.getItem('user_data')).Username && (e.IsEnabled === "N") &&
                                                                <div className="single-comment-wrap" style={{ marginBottom: 20 }}>
                                                                    <div className="thumb" style={{ backgroundColor: '#ffa500' }}>
                                                                        <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
                                                                            <label style={{ fontSize: 30 }}>{e.user.Username.slice(0, 1).toUpperCase()}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <h4 className="title">{e.user.FirstName === null ? e.user.Username : e.user.FirstName}</h4>
                                                                            <h4 className="title cl-gray"><span>(</span>{this.props.t('Pending review approval')}<span>)</span></h4>
                                                                        </div>
                                                                        {this.rating_render(e.star)}
                                                                        <p>{e.comment}</p>
                                                                    </div>
                                                                </div>

                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </li>
                                        </ul>
                                    </div>
                                }
                                {/* เว็บซด์ */}
                                <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: 12 }}>
                                    <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                    <div className="border-yellow"></div>
                                </div>
                                <div className="style-img-website">
                                    <img className="img-website" src={publicUrl + "assets/img/bg/ttd.png"} alt="img"></img>
                                    <div className="row row-flex">
                                        <img className="img-logo" src={publicUrl + "assets/img/logo_ttd.png"} alt="img"></img>
                                        <div className="" style={{ display: 'block', marginLeft: '0.5rem' }}>
                                            <div className="large-text cl-white">กระทรวงการท่องเที่ยวและกีฬา</div>
                                            <div className="normal-text-18 cl-white">MINISTRY OF TOURISM & SPORT</div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ justifyContent: 'center' }}>
                                        <a className="btn btn-website-blue" href="https://www.mots.go.th/" target="_blank" rel="noopener noreferrer"><span className="normal-text-18">{this.props.t('Visit Website')}</span></a>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="tour-details-area mg-top--70s">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 col-12" style={{ marginTop: '-40px' }}>
                                        {/* ปกติ */}
                                        {this.state.matches && (
                                            <div>
                                                <div className="card card-color" style={{ borderRadius: 15 }}>
                                                    <div className="card-body">
                                                        {/* <div className="pd-bt-30">
                                                            {this.state.place_details.IsOpen == 0 ?
                                                                <h5 className="mg-top-card open-text cl-gray"><span data-nosnippet>{this.props.t('Closed')}</span></h5>
                                                                :
                                                                <h5 className="mg-top-card open-text cl-green"><img src={clock} style={{ paddingRight: 10, height: '30px', }} alt="" /><span data-nosnippet>{this.props.t('Open')}</span></h5>
                                                            }
                                                        </div> */}
                                                        {/* ข้อมูลที่เป็นหน่วยงานของรัฐ */}
                                                        {this.state.place_details.Official === 0 ?
                                                            <div className="row pd">
                                                                <div className="col-xl-3 col-lg-5 col-md-3 al-self" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <img className="" src={dataofof} alt="" />
                                                                </div>
                                                                <div className="col-xl-9 col-lg-7 col-md-9">
                                                                    <p className="normal-text ">{this.props.t('Official Information of Government Agencies')}:</p>
                                                                    <p className="normal-text " style={{ color: "#173865" }}>{this.props.t('Not official information')}</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="row pd">
                                                                <div className="col-xl-3 col-lg-5 col-md-3 al-self" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <img className="" src={dataof} alt="" />
                                                                </div>
                                                                <div className="col-xl-9 col-lg-7 col-md-9">
                                                                    <p className="normal-text ">{this.props.t('Official Information of Government Agencies')} :</p>
                                                                    <p className="normal-text " style={{ color: "#173865" }}>{this.props.t('Department of Tourism')}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        <hr className=""></hr>
                                                        <div className="row pd">
                                                            <div className="col-xl-3 col-lg-5 col-md-3 al-self" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <img className="" src={dataver} alt="" />
                                                            </div>
                                                            <div className="col-xl-9 col-lg-7 col-md-9">
                                                                {/* {this.state.place_details.UpdatedEditorTeam.length < 1 ?
                                                                    <div >

                                                                    </div>
                                                                    :
                                                                    <div className="row">
                                                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                                                            <p className="normal-text">{this.props.t('Update information')} : </p><p style={{ color: "#173865" }}>{this.state.place_details.UpdatedEditorTeam}</p>
                                                                        </div>
                                                                    </div>
                                                                } */}
                                                                {this.state.place_details.SourceDepartment.length < 1 ?
                                                                    <div>

                                                                    </div>
                                                                    :
                                                                    <div className="row">
                                                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                                                            <p className="normal-text">{this.props.t('Affiliation')} : </p><p style={{ color: "#173865" }}>{this.state.place_details.SourceDepartment[this.state.lang]}</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <hr className="pd-bt-15"></hr>
                                                        {/* ข้อมูลติดต่อ */}
                                                        <h6 className="card-subtitle topic-text pd-bt-15" >{this.props.t('Contact us')}</h6>
                                                        {this.state.place_details.ContactName[this.state.lang] !== "" ?
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={ContactName} style={{ paddingRight: 17, height: 17 }} alt="" />
                                                                    {/* {this.props.t('Contact Name')} {this.state.place_details.ContactName[this.state.lang]} */}
                                                                    {this.state.place_details.ContactName[this.state.lang]}
                                                                    {this.state.place_details.ContactPosition[this.state.lang] !== "" ?
                                                                        <span style={{ marginLeft: 5 }}>
                                                                            <span>(</span>{this.state.place_details.ContactPosition[this.state.lang]}<span>)</span>
                                                                        </span>
                                                                        : this.state.place_details.ContactPosition.th !== "" ?
                                                                            <span>
                                                                                <span>(</span>{this.state.place_details.ContactPosition.th}<span>)</span>
                                                                            </span>
                                                                            :
                                                                            <span></span>
                                                                    }
                                                                </p>
                                                            </div>
                                                            : this.state.place_details.ContactName.th !== "" ?
                                                                <div>
                                                                    <p className="normal-text pd-bt-15">
                                                                        <img src={ContactName} style={{ paddingRight: 17, height: 17 }} alt="" />
                                                                        {/* {this.props.t('Contact Name')} {this.state.place_details.ContactName.th} */}
                                                                        {this.state.place_details.ContactName.th}
                                                                        {this.state.place_details.ContactPosition[this.state.lang] !== "" ?
                                                                            <span style={{ marginLeft: 5 }}>
                                                                                <span>(</span>{this.state.place_details.ContactPosition[this.state.lang]}<span>)</span>
                                                                            </span>
                                                                            : this.state.place_details.ContactPosition.th !== "" ?
                                                                                <span>
                                                                                    <span>(</span>{this.state.place_details.ContactPosition.th}<span>)</span>
                                                                                </span>
                                                                                :
                                                                                <span></span>
                                                                        }
                                                                    </p>
                                                                </div>
                                                                :
                                                                <div>

                                                                </div>
                                                        }
                                                        {this.state.place_details.Address[this.state.lang] !== "" ?
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={locations} style={{ paddingRight: 17, height: 27 }} alt="" />
                                                                    {/* {this.props.t('Address')} {this.state.place_details.Address[this.state.lang]} */}
                                                                    {this.state.place_details.Address[this.state.lang]}
                                                                </p>
                                                            </div>
                                                            : this.state.place_details.Address.th !== "" ?
                                                                <div>
                                                                    <p className="normal-text pd-bt-15">
                                                                        <img src={locations} style={{ paddingRight: 17, height: 27 }} alt="" />
                                                                        {/* {this.props.t('Address')} {this.state.place_details.Address.th} */}
                                                                        {this.state.place_details.Address.th}
                                                                    </p>
                                                                </div>
                                                                :
                                                                <div>

                                                                </div>
                                                        }
                                                        {this.state.place_details.Mobile.length < 1 ?
                                                            <div>

                                                            </div>
                                                            :
                                                            <div>
                                                                {/* <p className="normal-text pd-bt-15"><img src={phone} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Phone number')} {this.state.place_details.Mobile}</p> */}
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={phone} style={{ paddingRight: 18, height: 19 }} alt="" />
                                                                    {this.state.place_details.Mobile}
                                                                </p>
                                                            </div>
                                                        }
                                                        {this.state.place_details.LineID.length < 1 ?
                                                            <div>

                                                            </div>
                                                            :
                                                            <div>
                                                                {/* <p className="normal-text pd-bt-15"><img src={line} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Line')} {this.state.place_details.LineID}</p> */}
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={line} style={{ paddingRight: 13, height: 24, marginLeft: -1 }} alt="" />
                                                                    {this.state.place_details.LineID}
                                                                </p>
                                                            </div>
                                                        }
                                                        {this.state.place_details.Email.length < 1 ?
                                                            <div>

                                                            </div>
                                                            :
                                                            <div>
                                                                {/* <p className="normal-text pd-bt-15"><img src={mail} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Email')} {this.state.place_details.Email}</p> */}
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={mail} style={{ paddingRight: 15, height: 20 }} alt="" />
                                                                    {this.state.place_details.Email}
                                                                </p>
                                                            </div>
                                                        }
                                                        {this.state.place_details.Website === null || this.state.place_details.Website === "" ?
                                                            <div>

                                                            </div>
                                                            :
                                                            <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                                {/* <p className="normal-text"><img src={website} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Website')}</p> */}
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={website} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                                </p>
                                                                <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.Website) }}>
                                                                    <span className="normal-text">{this.props.t('Visit Website')}</span>
                                                                </button>
                                                            </div>
                                                        }
                                                        {this.state.place_details.FacebookUrl === null || this.state.place_details.FacebookUrl === "" ?
                                                            <div>

                                                            </div>
                                                            :
                                                            <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                                {/* <p className="normal-text"><img src={facebook} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Facebook')}</p> */}
                                                                <p className="pd-bt-15">
                                                                    <img src={facebook} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                                </p>
                                                                <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.FacebookUrl) }}>
                                                                    <span className="normal-text">{this.props.t('Facebook Page')}</span>
                                                                </button>
                                                            </div>
                                                        }
                                                        {this.state.place_details.Twitter === null || this.state.place_details.Twitter === "" ?
                                                            <div>

                                                            </div>
                                                            :
                                                            <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                                {/* <p className="normal-text"><img src={Twitter} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Twitter')}</p> */}
                                                                <p className="pd-bt-15">
                                                                    <img src={Twitter} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                                </p>
                                                                <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.Twitter) }}>
                                                                    <span className="normal-text">{this.props.t('Twitter')}</span>
                                                                </button>
                                                            </div>
                                                        }
                                                        {this.state.place_details.VdoYoutubeUrl === null || this.state.place_details.VdoYoutubeUrl === "" ?
                                                            <div
                                                            ></div>
                                                            :
                                                            <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                                {/* <p className="normal-text"><img src={YouTube} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Video Introduction')}</p> */}
                                                                <p className="pd-bt-15">
                                                                    <img src={YouTube} style={{ paddingRight: 7, marginLeft: -1, height: 30 }} alt="" />
                                                                </p>
                                                                <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.VdoYoutubeUrl) }}>
                                                                    <span className="normal-text">{this.props.t('Video Introduction')}</span>
                                                                </button>
                                                            </div>
                                                        }
                                                        {this.state.place_details.MapImage === null || this.state.place_details.MapImage === "" ?
                                                            <div>

                                                            </div>
                                                            :
                                                            <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                                {/* <p className="normal-text"><img src={Map} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Map Image')}</p> */}
                                                                <p className="pd-bt-15">
                                                                    <img src={Map} style={{ paddingRight: 12, paddingLeft: 5, height: 24 }} alt="" />
                                                                </p>
                                                                <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { this.showMap() }} >
                                                                    <span className="normal-text">{this.props.t('Click to view image')}</span>
                                                                </button>
                                                                {this.state.showLightbox ?
                                                                    <Lightbox image={this.state.place_details.MapImage} title={this.state.place_details.Name[this.state.lang]} onClose={() => this.setState({ showLightbox: false })} /> : null}
                                                            </div>
                                                        }
                                                        <hr className="pd-bt-15"></hr>
                                                        {/* แผนที่ */}
                                                        <div style={{ marginTop: 20 }}>
                                                            <h6 className="card-subtitle topic-text pd-bt-15">{this.props.t('Map')}</h6>
                                                            <div className="map-style">
                                                                <img className="map" src={publicUrl + "assets/img/map2.png"} alt=""
                                                                    onClick={() => { window.open("https://maps.google.com/?q=" + this.state.place_details.Latitude + "," + this.state.place_details.Longitude) }}>
                                                                </img>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                            <p className="pd-bt-15">
                                                                <img src={Gmap} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                            </p>
                                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open("https://maps.google.com/?q=" + this.state.place_details.Latitude + "," + this.state.place_details.Longitude) }}>
                                                                <span className="normal-text">{this.props.t('Map')}</span>
                                                            </button>
                                                        </div> */}
                                                        {/* <hr className="pd-bt-15"></hr> */}
                                                        {/* แผนที่ */}
                                                        {/* <div style={{ marginTop: 20 }}> */}
                                                        {/* <h6 className="card-subtitle topic-text pd-bt-15" ><a href={"https://maps.google.com/?q="+this.state.place_details.Latitude+","+this.state.place_details.Longitude } target="_blank">{this.props.t('Map')}</a></h6> */}
                                                        {/* <MapsDetail maps_lat={this.state.place_details.Latitude} maps_lng={this.state.place_details.Longitude}></MapsDetail> */}
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                                {this.state.lang === 'zh' ?
                                                    <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: 12 }}>
                                                        <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                                        <div className="border-yellow zh"></div>
                                                    </div>
                                                    :
                                                    <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: 12 }}>
                                                        <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                                        <div className="border-yellow"></div>
                                                    </div>
                                                }
                                                <div className="style-img-website">
                                                    <img className="img-website" src={publicUrl + "assets/img/bg/ttd.png"} alt="img"></img>
                                                    <div className="row row-flex">
                                                        <img className="img-logo" src={publicUrl + "assets/img/logo_ttd.png"} alt="img"></img>
                                                        <div className="" style={{ display: 'block', marginLeft: '0.5rem' }}>
                                                            <div className="large-text cl-white">กระทรวงการท่องเที่ยวและกีฬา</div>
                                                            <div className="normal-text-18 cl-white">MINISTRY OF TOURISM & SPORT</div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ justifyContent: 'center' }}>
                                                        <a className="btn btn-website-blue" href="https://www.mots.go.th/" target="_blank" style={{ width: '40%' }} rel="noopener noreferrer"><span className="normal-text-18">{this.props.t('Visit Website')}</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    {this.state.matches && (
                                        <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-12 mb-12" >
                                            <div className="mg-top--70ss pd-bt-30 mg-left-20">
                                                <ImageGallery items={images}
                                                    showThumbnails={false}
                                                    infinite={true}
                                                    showIndex={true}
                                                />
                                            </div>
                                            {/* ชื่อสถานที่และที่ตั้ง */}
                                            <div>
                                                <p className="large-text-36 pd-l-10 cl-black dp">{this.state.place_details.Name[this.state.lang] === "" || this.state.place_details.Name[this.state.lang] === undefined ?
                                                    this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]}</p>
                                            </div>
                                            <div className="pd-bt-15 pd-l-10 row" style={{ margin: 0, marginTop: 10 }}>
                                                <div className="pd-r-15" style={{ display: 'flex' }}><img src={vecterlocation} style={{ paddingRight: 10, height: '25px' }} alt="" /><p className="normal-text-18-detail cl-blue">{this.props.t('SubDistrict')}: {this.state.place_details.SubDistrict[this.state.lang]}</p></div>
                                                <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.props.t('District')}: {this.state.place_details.District[this.state.lang]}</p></div>
                                                <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.props.t('Province')}: {this.state.place_details.Province[this.state.lang]}</p></div>
                                                <div className="pd-r-15"><p className="normal-text-18-detail cl-blue">{this.state.place_details.PostCode}</p></div>
                                            </div>
                                            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12" >
                                                <div className="row pd-bt-30">
                                                    <div className="col-lg-10 col-xl-10 col-md-8 col-sm-8 col-8 mb-8">
                                                        <div className="row">
                                                            {/* ปุ่มบันทุก / แชร์ / แจ้งแก้ไข */}
                                                            {this.state.isLogin === null ?
                                                                <div></div>
                                                                :
                                                                <div style={{ padding: 10 }} >
                                                                    <button className="Buttonsave" style={{ cursor: 'pointer' }} onClick={() => {
                                                                        let user_data = localStorage.getItem('user_data')
                                                                        if (user_data === null) {
                                                                            // console.log('no log')
                                                                            this.modal_control()
                                                                        }
                                                                        else {
                                                                            this.favourite_add()
                                                                        }
                                                                    }}><img src={vecterlove} style={{ paddingRight: 10, height: '15px', paddingLeft: 10 }} alt="" /><span style={{ paddingRight: 10 }}>{this.props.t(this.state.isFavourite ? 'Favourited' : 'Favourite')}</span></button>
                                                                </div>
                                                            }
                                                            <div style={{ padding: 10 }}>
                                                                <button className="Buttonshare" data-toggle="modal" data-target="#exampleModalCenter" style={{ cursor: 'pointer' }}><img src={Share} style={{ paddingRight: 10, height: '15px', paddingLeft: 10 }} alt="" /><span style={{ paddingRight: 10 }}>{this.props.t('Share information')}</span></button>
                                                            </div>
                                                            {/* <div style={{ padding: 10 }}>
                                                                <button className="Buttonmodify" style={{ cursor: 'pointer' }}><img src={feather_edit} style={{ paddingRight: 10, height: '15px', paddingLeft: 10 }} alt="" /><span style={{ paddingRight: 10 }}>{this.props.t('Change Report')}</span></button>
                                                            </div> */}
                                                            <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ right: -125, top: -100 }}>
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.t('Share')}</h5>
                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <h6 className="pd-bt-15">{this.props.t('Via social media channels')}</h6>
                                                                            <FacebookShareButton url={window.location.href} style={{ padding: 10 }}>
                                                                                <FacebookIcon size={58} iconFillColor="white" round={true} ></FacebookIcon>
                                                                            </FacebookShareButton>
                                                                            <FacebookShareButton url={window.location.href} style={{ padding: 10 }}>
                                                                                <FacebookMessengerIcon size={58} iconFillColor="white" round={true}></FacebookMessengerIcon>
                                                                            </FacebookShareButton>
                                                                            <TwitterShareButton url={window.location.href} style={{ padding: 10 }}>
                                                                                <TwitterIcon size={58} iconFillColor="white" round={true}></TwitterIcon>
                                                                            </TwitterShareButton>
                                                                            <LineShareButton url={window.location.href} style={{ padding: 10 }}>
                                                                                <LineIcon size={58} iconFillColor="white" round={true}></LineIcon>
                                                                            </LineShareButton>
                                                                            <LinkedinShareButton url={window.location.href} style={{ padding: 10 }}>
                                                                                <LinkedinIcon size={58} iconFillColor="white" round={true}></LinkedinIcon>
                                                                            </LinkedinShareButton>
                                                                            <WhatsappShareButton url={window.location.href} style={{ padding: 10 }}>
                                                                                <WhatsappIcon size={58} iconFillColor="white" round={true}></WhatsappIcon>
                                                                            </WhatsappShareButton>
                                                                            <EmailShareButton url={window.location.href} style={{ padding: 10 }}>
                                                                                <EmailIcon size={58} iconFillColor="white" round={true}></EmailIcon>
                                                                            </EmailShareButton>
                                                                            <h6 className="pd-bt-15" style={{ paddingTop: 10 }}>{this.props.t('You can copy the link and forward it.')}</h6>
                                                                            <div className="form-group ">
                                                                                <div className="row">
                                                                                    <div className="col-lg-10">
                                                                                        <input type="text" className="form-control" id="url-input" ref={el => this.elRef = el} value={window.location.href}></input>
                                                                                    </div>
                                                                                    <div className="col-lg-2">
                                                                                        <h5 onClick={this.copy} style={{ cursor: 'pointer', marginTop: 4 }}>{this.props.t('Copy')}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="modal-footer">
                                                                        
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-xl-2 col-md-4 col-sm-4 col-4 mb-4">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-12">
                                                {/* ข้อมูลสถานที่ */}
                                                {this.state.place_details.Detail[this.state.lang] !== "" ?
                                                    <div style={{ marginTop: 20 }}>
                                                        <h5 className="topic-text pd-bt-15">{this.props.t('Description')}</h5>
                                                        <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15, whiteSpace: 'pre-line' }}>{this.state.place_details.Detail[this.state.lang]}</p>
                                                        <hr></hr>
                                                    </div>
                                                    : this.state.place_details.Detail.th !== "" ?
                                                        <div style={{ marginTop: 20 }}>
                                                            <h5 className="topic-text pd-bt-15">{this.props.t('Description')}</h5>
                                                            <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.Detail.th}</p>
                                                            <hr></hr>
                                                        </div>
                                                        :
                                                        <div>

                                                        </div>
                                                }
                                                {/* ข้อมูลเพิ่มเติม */}
                                                {this.state.place_details.MoreDetail[this.state.lang] !== "" ?
                                                    <div>
                                                        <h5 className="topic-text pd-bt-15">{this.props.t('More information')}</h5>
                                                        <Interweave content={Moredetails} transform={transform} />
                                                        {/* <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.MoreDetail[this.state.lang]}</p> */}
                                                        <div><hr></hr></div>
                                                    </div>
                                                    : this.state.place_details.MoreDetail.th !== "" ?
                                                        <div>
                                                            <h5 className="topic-text pd-bt-15">{this.props.t('More information')}</h5>
                                                            <Interweave content={Moredetails} transform={transform} />
                                                            {/* <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.MoreDetail.th}</p> */}
                                                            <div><hr></hr></div>
                                                        </div>
                                                        :
                                                        <div>

                                                        </div>
                                                }
                                                {/* ข้อมูลแนะนำ */}
                                                <div>
                                                    <h5 className="topic-text pd-bt-15" >{this.props.t('Information')}</h5>
                                                </div>
                                                <div className="row">
                                                    {/* การขึ้นทะเบียน */}
                                                    <div className="col-lg-6">
                                                        <div className="row" style={{ paddingLeft: 13 }}>
                                                            <img src={publicUrl + "assets/img/icons/card-checked.png"} style={{ paddingRight: 10, height: '25px', marginTop: '5px' }} alt="" /><p className="normal-text pd-bt-15" style={{ fontWeight: 'bold' }}>{this.props.t('Registration')}</p>
                                                        </div>
                                                        {this.state.place_details.Accommodation.Info.AccommodationRegistrationType.th !== "" ?
                                                            <p className="normal-text pd-bt-15">- {this.props.t('Registration type')} : {this.state.place_details.Accommodation.Info.AccommodationRegistrationType[this.state.lang]}</p>
                                                            :
                                                            <p className="normal-text pd-bt-15">- {this.props.t('Registration type')} : -</p>
                                                        }
                                                        {this.state.place_details.Accommodation.Info.AccommodationStandard.th !== "" ?
                                                            <p className="normal-text pd-bt-15" >- {this.props.t('Standard')} : {this.state.place_details.Accommodation.Info.AccommodationStandard[this.state.lang]}</p>
                                                            :
                                                            <p className="normal-text pd-bt-15">- {this.props.t('Standard')} : -</p>
                                                        }
                                                        {this.state.place_details.Accommodation.Info.LicenseNo !== "" ?
                                                            <p className="normal-text pd-bt-15" >- {this.props.t('License number')} : {this.state.place_details.Accommodation.Info.LicenseNo}</p>
                                                            :
                                                            <p className="normal-text pd-bt-15">- {this.props.t('License number')} : -</p>
                                                        }
                                                        {this.state.place_details.Accommodation.Info.LicenseDate !== null ?
                                                            <p className="normal-text pd-bt-15" >- {this.props.t('Allowed Date')} : {moment(this.state.place_details.Accommodation.Info.LicenseDate).format('l')}</p>
                                                            :
                                                            <p className="normal-text pd-bt-15">- {this.props.t('Allowed Date')} : -</p>
                                                        }
                                                        {this.state.place_details.Accommodation.Info.LicenseExpireDate !== null ?
                                                            <p className="normal-text pd-bt-15" >- {this.props.t('Expiration date')} : {moment(this.state.place_details.Accommodation.Info.LicenseExpireDate).format('l')}</p>
                                                            :
                                                            <p className="normal-text pd-bt-15">- {this.props.t('Expiration date')} : -</p>
                                                        }
                                                    </div>
                                                    {/* จำนวนห้องพัก */}
                                                    {this.state.place_details.Accommodation.Info.RoomAmount || this.state.place_details.Accommodation.Info.MeetingRoomAmount !== 0 ?
                                                        <div className="col-lg-6">
                                                            <div className="row" style={{ paddingLeft: 13 }}>
                                                                <img src={publicUrl + "assets/img/icons/card-checked.png"} style={{ paddingRight: 10, height: '25px', marginTop: '5px' }} alt="" /><p className="normal-text pd-bt-15" style={{ fontWeight: 'bold' }}>{this.props.t('Number of rooms')}</p>
                                                            </div>
                                                            {this.state.place_details.Accommodation.Info.RoomAmount !== 0 ?
                                                                <p className="normal-text pd-bt-15">- {this.props.t('Number of rooms')} : {this.state.place_details.Accommodation.Info.RoomAmount} {this.props.t('Rooms')}</p>
                                                                :
                                                                <p className=""></p>
                                                            }
                                                            {this.state.place_details.Accommodation.Info.MeetingRoomAmount !== 0 ?
                                                                <p className="normal-text pd-bt-15" >- {this.props.t('Number of meeting rooms')} : {this.state.place_details.Accommodation.Info.MeetingRoomAmount} {this.props.t('Rooms')}</p>
                                                                :
                                                                <p className=""></p>
                                                            }
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    {/* กล่มเป้าหมาย */}
                                                    {this.state.place_details.Accommodation.Targets.length < 1 ?
                                                        <div>
                                                        </div>
                                                        :
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <img src={publicUrl + "assets/img/icons/targets.png"} style={{ paddingRight: 10, height: '25px', marginTop: '5px' }} alt="" /><p className="normal-text pd-bt-15" style={{ fontWeight: 'bold' }}>{this.props.t('Group of tourist')}</p>
                                                            </div>
                                                            {this.state.place_details.Accommodation.Targets.map((e, i) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <p className="normal-text pd-bt-15">- {e[this.state.lang]}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                    {/* การเดินทาง */}
                                                    {this.state.place_details.TravelTypes.Items.length < 1 ?
                                                        <div>

                                                        </div>
                                                        :
                                                        <div className="col-lg-6">
                                                            <div className="row" style={{ paddingLeft: 13 }}>
                                                                <img src={this.state.place_details.TravelTypes.Icon} style={{ paddingRight: 10, height: '25px', marginTop: '5px' }} alt="" /><p className="normal-text pd-bt-15" style={{ fontWeight: 'bold' }}>{this.props.t('Transportations')}</p>
                                                            </div>
                                                            {this.state.place_details.TravelTypes.Items.map((e, i) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <p className="normal-text pd-bt-15">- {e[this.state.lang]}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                            {this.state.place_details.TravelRemark[this.state.lang] !== "" ?
                                                                <div>
                                                                    <p className="text-left normal-text pd-bt-15" >{this.props.t('Travel Remark')}: {this.state.place_details.TravelRemark[this.state.lang]}</p>
                                                                </div>
                                                                : this.state.place_details.TravelRemark.th !== "" ?
                                                                    <div>
                                                                        <p className="text-left normal-text pd-bt-15" >{this.props.t('Travel Remark')}: {this.state.place_details.TravelRemark.th}</p>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <p className="text-left normal-text pd-bt-15" >{this.props.t('Travel Remark')}: -</p>
                                                                    </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <hr></hr>
                                                {/* สิ่งอำนวยความสะดวก */}
                                                {this.state.place_details.Accommodation.Facilities === undefined ?
                                                    <div>

                                                    </div>
                                                    :
                                                    <div>
                                                        <h5 className="topic-text pd-bt-15" >{this.props.t('Facilities')}</h5>
                                                        <div className="row" style={{ paddingTop: 12 }}>
                                                            {Object.keys(this.state.place_details.Accommodation.Facilities).map((e, i) => {
                                                                return (
                                                                    <div className="col-lg-6">
                                                                        <div key={i}>
                                                                            <div className="row" style={{ paddingLeft: 13 }}>
                                                                                <img src={this.state.place_details.Accommodation.Facilities[e] !== null ? this.state.place_details.Accommodation.Facilities[e].Icon : null} style={{ paddingRight: 10, height: '20px', marginTop: 5 }} alt="" />
                                                                                <p className="normal-text pd-bt-15 cl-black" style={{ fontWeight: 'bold' }}>{this.state.place_details.Accommodation.Facilities[e][this.state.lang]}</p>
                                                                            </div>
                                                                            {this.state.place_details.Accommodation.Facilities[e].SubType.map((h, j) => {
                                                                                return (
                                                                                    <div key={j} >
                                                                                        <p className="normal-text pd-bt-15"> - {h[this.state.lang] !== undefined || h[this.state.lang] !== null ? h[this.state.lang] : h.th}</p>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                                <hr></hr>
                                                {/* เขียนรีวิว */}
                                                <div className="row pd-bt-15">
                                                    <div className="col-xl-6 col-lg-7" >
                                                        <h5 className="topic-text" style={{ alignItems: 'center', alignContent: 'center' }}>{this.props.t('Reviews')}</h5>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-5" style={{ textAlign: 'end' }}>
                                                        <h5 className="topic-text" style={{ color: '#F4D006' }}><img src={edit} style={{ paddingRight: 10, height: '30px', }} alt="" />{this.props.t('Write review')}</h5>
                                                    </div>
                                                </div>
                                                <div className="location-review-area pd-bt-15" >
                                                    {this.state.isLogin === null || this.state.isLogin === undefined ?
                                                        <div className="btn-wrapper" style={{ textAlign: 'center' }}>
                                                            <div onClick={() => {
                                                                let user_data = localStorage.getItem('user_data')
                                                                if (user_data === null) {
                                                                    // console.log('no log')
                                                                    this.modal_control()
                                                                }
                                                                else {
                                                                    // console.log('log')
                                                                }
                                                            }} >
                                                                <div className="btn btn-transparent" style={{ cursor: 'pointer' }}><img src={Vectorgologin} style={{ paddingRight: 10 }} alt="" />{this.props.t('Please')} <span style={{ color: '#245aa3' }}>{this.props.t('login')}</span> {this.props.t('before review')}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <form className="tp-form-wrap bg-gray tp-form-wrap-one">
                                                                    <div className="row">
                                                                        <div className="col-md-2" >
                                                                            <div className="single-comment-wrap">
                                                                                <div className="thumb">
                                                                                    {/* <img src={User} style={{ borderRadius: '50%' }} alt="" /> */}
                                                                                    {JSON.parse(localStorage.getItem('user_data')).FirstName === null ?
                                                                                        <div style={{ textAlign: 'center', fontSize: 45, width: 85, height: 85, backgroundColor: 'orange', borderRadius: '50%' }}>
                                                                                            <label style={{ marginTop: 24, color: 'white' }}>{JSON.parse(localStorage.getItem('user_data')).Username.slice(0, 1).toUpperCase()}</label>
                                                                                        </div>
                                                                                        :
                                                                                        <div style={{ textAlign: 'center', fontSize: 45, width: 85, height: 85, backgroundColor: 'orange', borderRadius: '50%' }}>
                                                                                            <label style={{ marginTop: 24, color: 'white' }}>{JSON.parse(localStorage.getItem('user_data')).FirstName.slice(0, 1).toUpperCase()}</label>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-10" >
                                                                            <h4 className="single-page-small-title normal-text-18-detail" style={{ padding: 0 }}><span>{JSON.parse(localStorage.getItem('user_data')).FirstName === null ? JSON.parse(localStorage.getItem('user_data')).Username : JSON.parse(localStorage.getItem('user_data')).FirstName}</span></h4>
                                                                            <div className="row">
                                                                                <span className="ml-3 normal-text-18-detail" style={{ paddingRight: 10, color: '#173865', }}>{this.props.t('Rating')}</span>
                                                                                <div className="spacing-star">
                                                                                    <ReactStars
                                                                                        count={5}
                                                                                        onChange={(e) => { this.ratingChanged(e) }}
                                                                                        size={30}
                                                                                        activeColor="#ffd700"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12" style={{ paddingTop: 37 }}>
                                                                            <label className="single-input-wrap">
                                                                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: 0 }}>
                                                                                    <span className="single-input-title normal-text-18-detail">{this.props.t('Review details')}</span>
                                                                                    {this.state.comment_count === 300 ?
                                                                                        <span className="single-input-title normal-text-18-detail" style={{ color: 'red' }}>{this.state.comment_count} / 300</span>
                                                                                        :
                                                                                        <span className="single-input-title normal-text-18-detail">{this.state.comment_count} / 300</span>
                                                                                    }
                                                                                </div>
                                                                                <textarea defaultValue={""} maxLength={300} onChange={(e) => {
                                                                                    this.setState({ comment: e.target.value })
                                                                                    this.setState({ comment_count: e.target.value.length })
                                                                                }} />
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <ModalComment modal_comment={this.state.modal_comment} />
                                                                            {/* <a className="btn btn-blue" href="#">+ {this.props.t('Add Photo')}</a> */}
                                                                            <div className="btn float-right" style={{ backgroundColor: '#173865', width: '135px', color: 'white', fontWeight: 'bold' }} onClick={(e) => { this.comment_add(e) }}>{this.props.t('Save')}</div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <hr></hr>
                                                {/* รีวิว */}
                                                <h5 className="topic-text pd-bt-15">{this.props.t('Review information')}</h5>
                                                <div className="card card-color" style={{ backgroundColor: '#F8F8F8' }}>
                                                    <div className="row" style={{ padding: 10 }}>
                                                        <div className="col-lg-4" style={{ borderRight: '1px solid #E2E2E2' }}>
                                                            <p className="normal-text-18 pd-bt-51" style={{ textAlign: 'center', color: '#000000', marginTop: 10 }}>{this.props.t('Average rating')}</p>
                                                            <div className="row pd-bt-51" style={{ justifyContent: 'center' }}>
                                                                <p className="normal-text" style={{ color: '#F4D006', fontSize: 60, alignItems: 'flex-end' }}>0</p><p style={{ color: '#F4D006', fontSize: 40, marginTop: 9 }}> /5</p>
                                                            </div>
                                                            <div className="row" style={{ justifyContent: 'center' }}>
                                                                <p className="normal-text-18" style={{ color: '#000000' }}>{this.props.t('All Reviews')} : </p><p className="normal-text-18" style={{ color: '#F4D006', paddingLeft: 10 }}> 0 <span data-nosnippet>{this.props.t('list')}</span></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8" style={{ display: 'grid' }}>
                                                            <div className="row">
                                                                <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                    <div className="small-text-13 cl-black al-self">5</div>
                                                                    <img className="al-self" style={{ width: 12, height: 12 }} src={star} alt=""></img>
                                                                    <div className="al-self" style={{ width: '85%' }}><ProgressBar style={{ height: 7 }} variant="warning" now={0} /></div>
                                                                    <div className="small-text-13 cl-black al-self">(0)</div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                    <div className="small-text-13 cl-black al-self">4</div>
                                                                    <img className="al-self" style={{ width: 12, height: 12 }} src={star} alt=""></img>
                                                                    <div className="al-self" style={{ width: '85%' }}><ProgressBar style={{ height: 7 }} variant="warning" now={0} /></div>
                                                                    <div className="small-text-13 cl-black al-self">(0)</div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                    <div className="small-text-13 cl-black al-self">3</div>
                                                                    <img className="al-self" style={{ width: 12, height: 12 }} src={star} alt=""></img>
                                                                    <div className="al-self" style={{ width: '85%' }}><ProgressBar style={{ height: 7 }} variant="warning" now={0} /></div>
                                                                    <div className="small-text-13 cl-black al-self">(0)</div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                    <div className="small-text-13 cl-black al-self">2</div>
                                                                    <img className="al-self" style={{ width: 12, height: 12 }} src={star} alt=""></img>
                                                                    <div className="al-self" style={{ width: '85%' }}><ProgressBar style={{ height: 7 }} variant="warning" now={0} /></div>
                                                                    <div className="small-text-13 cl-black al-self">(0)</div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                    <div className="small-text-13 cl-black al-self">1</div>
                                                                    <img className="al-self" style={{ width: 12, height: 12 }} src={star} alt=""></img>
                                                                    <div className="al-self" style={{ width: '85%' }}><ProgressBar style={{ height: 7 }} variant="warning" now={0} /></div>
                                                                    <div className="small-text-13 cl-black al-self">(0)</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* แสดงคอมเม้นต์ */}
                                                {this.state.isLogin === null ?
                                                    <div className="comments-area">
                                                        <ul className="comment-list">
                                                            <li>
                                                                {this.state.comment_data.map((e, i) => {
                                                                    // console.log(e.user.Username)
                                                                    // console.log(this.state.comment_data)
                                                                    return (
                                                                        <div key={i}>
                                                                            {e.comment && (e.IsEnabled === 'Y' || e.user.Username === JSON.parse(localStorage.getItem('user_data')).Username) ?
                                                                                <div className="single-comment-wrap" style={{ marginBottom: 20 }}>
                                                                                    <div className="thumb" style={{ backgroundColor: '#ffa500' }}>
                                                                                        <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
                                                                                            <label style={{ fontSize: 30 }}>{e.user.Username.slice(0, 1).toUpperCase()}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                            <h4 className="title">{e.user.FirstName === null ? e.user.Username : e.user.FirstName}</h4>
                                                                                        </div>
                                                                                        {this.rating_render(e.star)}
                                                                                        <p>{e.comment}</p>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div></div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    :
                                                    <div className="comments-area">
                                                        <ul className="comment-list">
                                                            <li>
                                                                {this.state.comment_data.map((e, i) => {
                                                                    // console.log(e.user.Username)
                                                                    // console.log(this.state.comment_data)
                                                                    return (
                                                                        <div key={i}>
                                                                            {e.comment && e.IsEnabled === "Y" &&
                                                                                <div className="single-comment-wrap" style={{ marginBottom: 20 }}>
                                                                                    <div className="thumb" style={{ backgroundColor: '#ffa500' }}>
                                                                                        <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
                                                                                            <label style={{ fontSize: 30 }}>{e.user.Username.slice(0, 1).toUpperCase()}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                            <h4 className="title">{e.user.FirstName === null ? e.user.Username : e.user.FirstName}</h4>
                                                                                        </div>
                                                                                        {this.rating_render(e.star)}
                                                                                        <p>{e.comment}</p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {e.comment && e.user.Username === JSON.parse(localStorage.getItem('user_data')).Username && (e.IsEnabled === "N") &&
                                                                                <div className="single-comment-wrap" style={{ marginBottom: 20 }}>
                                                                                    <div className="thumb" style={{ backgroundColor: '#ffa500' }}>
                                                                                        <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
                                                                                            <label style={{ fontSize: 30 }}>{e.user.Username.slice(0, 1).toUpperCase()}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                            <h4 className="title">{e.user.FirstName === null ? e.user.Username : e.user.FirstName}</h4>
                                                                                            <h4 className="title cl-gray"><span>(</span>{this.props.t('Pending review approval')}<span>)</span></h4>
                                                                                        </div>
                                                                                        {this.rating_render(e.star)}
                                                                                        <p>{e.comment}</p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-130 ">
                        <Footer />
                    </div>
                </div>
            )
        }
    }
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
    language: 'en',
})(withTranslation()(AccommodationDetailsPage));


