import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class CardTravel extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}

	}
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return <div className="client-area pd-top-108 pd-bottom-120 jarallax" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/bg2.png)', marginTop: 80, backgroundSize: 'cover' }}>
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="section-title style-two">
							<h2 className="title large-text-64 cl-yellow">{this.props.t('Start your journey')}</h2>
							<p className="title large-text-48 lh">{this.props.t('Discover Thainess')}</p>
							<p className="large-text">{this.props.t('Discover Thainess through your journey to the amazing attractions, you will love Thailand.')}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="destinations1-list-slider-bg">
				<div className="container">
					<div className="row">
						<div className="col-xl-9 col-lg-10 offset-xl-1 order-lg-12">
							<div className="destinations1-list-slider">
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/contentstory/" + this.props.content_story[0].ID, params: this.props.content_story[0] !== undefined ? this.props.content_story[0].ID : null }}>
									<div className="d-list-slider-item" >
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<img className="province-img" src={this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroImage} alt="list" />
											</div>
											<div className="details height-details">
												<h4 className="title normal-text-18">{this.props.content_story.length < 1 ? null : this.props.content_story[0].Title[this.props.i18n.language]}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].Province[this.props.i18n.language]}</span></p>
												{this.props.i18n.language === 'zh' ?
													<p className="content" style={{ textAlign: 'left' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroText[this.props.i18n.language].slice(0, 60) + "..."}</p>
													:
													<p className="content" style={{ textAlign: 'left' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroText[this.props.i18n.language].slice(0, 100) + "..."}</p>
												}
											</div>
										</div>
									</div>
								</Link>
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/contentstory/" + this.props.content_story[1].ID, params: this.props.content_story[1] !== undefined ? this.props.content_story[1].ID : null }}>
									<div className="d-list-slider-item">
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<img className="province-img" src={this.props.content_story.length < 1 ? null : this.props.content_story[1].IntroImage} alt="list" />
											</div>
											<div className="details height-details">
												<h4 className="title normal-text-18">{this.props.content_story.length < 1 ? null : this.props.content_story[1].Title[this.props.i18n.language]}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[1].Province[this.props.i18n.language]}</span></p>
												{this.props.i18n.language === 'zh' ?
													<p className="content" style={{ textAlign: 'left' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroText[this.props.i18n.language].slice(0, 60) + "..."}</p>
													:
													<p className="content" style={{ textAlign: 'left' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroText[this.props.i18n.language].slice(0, 100) + "..."}</p>
												}
											</div>
										</div>
									</div>
								</Link>
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/contentstory/" + this.props.content_story[2].ID, params: this.props.content_story[2] !== undefined ? this.props.content_story[2].ID : null }}>
									<div className="d-list-slider-item">
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<img className="province-img" src={this.props.content_story.length < 1 ? null : this.props.content_story[2].IntroImage} alt="list" />
											</div>
											<div className="details height-details">
												<h4 className="title normal-text-18">{this.props.content_story.length < 1 ? null : this.props.content_story[2].Title[this.props.i18n.language].slice(0, 30) + "..."}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[2].Province[this.props.i18n.language]}</span></p>
												{this.props.i18n.language === 'zh' ?
													<p className="content" style={{ textAlign: 'left' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroText[this.props.i18n.language].slice(0, 60) + "..."}</p>
													:
													<p className="content" style={{ textAlign: 'left' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroText[this.props.i18n.language].slice(0, 100) + "..."}</p>
												}
											</div>
										</div>
									</div>
								</Link>
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/contentstory/" + this.props.content_story[3].ID, params: this.props.content_story[3] !== undefined ? this.props.content_story[3].ID : null }}>
									<div className="d-list-slider-item">
										<div className="single-destinations-list text-center">
											<div className="thumb">
												<img className="province-img" src={this.props.content_story.length < 1 ? null : this.props.content_story[3].IntroImage} alt="list" />
											</div>
											<div className="details height-details">
												<h4 className="title normal-text-18">{this.props.content_story.length < 1 ? null : this.props.content_story[3].Title[this.props.i18n.language].slice(0, 30) + "..."}</h4>
												<p className="location" style={{ textAlign: 'start' }}><img className="province-location" src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-14" style={{ color: '#245AA3' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[3].Province[this.props.i18n.language]}</span></p>
												{this.props.i18n.language === 'zh' ?
													<p className="content" style={{ textAlign: 'left' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroText[this.props.i18n.language].slice(0, 60) + "..."}</p>
													:
													<p className="content" style={{ textAlign: 'left' }}>{this.props.content_story.length < 1 ? null : this.props.content_story[0].IntroText[this.props.i18n.language].slice(0, 100) + "..."}</p>
												}
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						<div className="col-lg-2 align-self-center order-lg-11">
							<div className="container">
								<div className="destinations1-slider-controls">
									<div className="slider1-nav tp-control-nav" />
									{/*slider-nav*/}
									<div className="tp-slider-extra slider-extra">
										<div className="text">
											<span className="first">01 </span>
											<span className="devider">/</span>
											<span className="last" />
										</div>
										{/*text*/}
										<div className="d-list-progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
											<span className="slider__label sr-only" />
										</div>
									</div>
									{/*slider-extra*/}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>



	}
}

export default withTranslation()(CardTravel);