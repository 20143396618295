import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import PageHeaderContent from './global-components/page-header-content';
import Footer from './global-components/footer';
import { api_endpoint } from '../global-config'
import FadeIn from "react-fade-in";
import * as loadingData from "../loadingttd.json";
import Lottie from "react-lottie";
import { BreadcrumbItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Interweave, { Node } from 'interweave';
// import ModalComment from "./section-components/modal-comment"
// import Parser from 'html-react-parser';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
class ContentPageDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content_id: props.match.params.id,
            content_details: {},
            // content_details_name: "",
            // details: "",
            // province: "",
            isLoading: true,
            lang: this.props.i18n.language,
            original: [],
        }
        console.log(this.props)
    }

    //เรียกข้อมูลสถานที่
    // async get_content_detail() {
    //     const response = await fetch(
    //         api_endpoint + `/contentinfo/readone`,
    //         {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify({
    //                 ID: this.state.content_id,
    //                 Lang: this.state.lang,
    //             }), 
    //         }
    //     );
    //     var _result = await response.json();
    //     let arr = []
    //     console.log(_result.result.Images)
    //     _result.result.Images.map((e, i) => {
    //         arr.push({ original: e, thumbnail: e })
    //     })
    //     console.log(arr)
    //     this.setState({
    //         content_details: _result.result,
    //         original: arr,
    //         loading: false
    //     }, () => {
    //         this.setState({
    //             content_details_name: this.state.content_details.Title.th,
    //             details: this.state.content_details.Detail.th,
    //         }, () => {
    //             {
    //                 this.state.content_details.MainInfo.map((e, i) => {
    //                     this.setState({
    //                         province: e.Province.th
    //                     })
    //                 })
    //             }
    //         })
    //     })
    // }

    //เรียกข้อมูลสถานที่
    async get_content_detail() {
        const response = await fetch(
            api_endpoint + `/contentinfo/readone`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ID: this.state.content_id,
                    Lang: this.state.lang,
                }),
            }
        );
        var _result = await response.json();
        let arr = []
        console.log(_result.result.Images)
        _result.result.Images.map((e, i) => {
            return arr.push({ original: e, thumbnail: e })
        })
        this.setState({
            content_details: _result.result,
            original: arr,
            isLoading: false
        })
    }

    componentDidMount() {
        if (this.state.content_id === undefined) {
            this.props.history.goBack()
        }
        else {
            const language = localStorage.getItem("I18N_LANGUAGE")
            if (!language) {
                localStorage.setItem("I18N_LANGUAGE", "th")
            }
            this.get_content_detail()
        }
    }
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        this.setState({
            isLoading: true,
            lang: nextProps.i18n.language
        }, () => {
            this.get_content_detail()
        })
    }

    render() {
        // console.log(Object(this.state.original))
        console.log(this.state.content_details)
        if (this.state.isLoading === true) {
            return (
                <div style={{ marginTop: 200 }} className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div className="card">
                                <div className="cardbody">
                                    <Lottie options={defaultOptions} height={300} width={300} />
                                    <div>
                                        <h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            )
        }
        else {
            const articleContent = this.state.content_details.Detail[this.state.lang] === "" ? this.state.content_details.Detail.th : this.state.content_details.Detail[this.state.lang]
            // close js 'types' can only be used in a .ts file - Visual Studio Code using @ts-check
            function transform(node: HTMLElement, children: Node[]): React.ReactNode {
                if (node.tagName.toLowerCase() === 'span') {
                    return <span className="normal-text cl-black" style={{ textAlign: 'justify' }}>{children}</span>;
                }
            }
            return (
                <div>
                    <Navbar />
                    <PageHeaderContent headertitle={this.state.content_details.Title[this.state.lang]} />
                    <div className="blog-details-area pd-top-80 viaje-go-top">
                        <div className="container">
                            <div className="row" style={{ marginBottom: 20 }}>
                                <div className="new-breadcrumb">
                                    <BreadcrumbItem><a hrefLang={this.state.lang} href="/" className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                                    <BreadcrumbItem ><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/contentpages"} className="normal-text cl-gray">{this.props.t('Article')}</a></BreadcrumbItem>
                                    <BreadcrumbItem active><span className="normal-text cl-black">{this.state.content_details.Title[this.state.lang]}</span></BreadcrumbItem>
                                </div>
                            </div>
                            <div className="row">
                                <p className="large-text cl-black">{this.state.content_details.Title[this.state.lang]}</p>
                            </div>
                            <div className="row" >
                                <div className="">
                                    <p className="normal-text cl-blue">{this.props.t('What to Know About Thailand')}</p>
                                </div>
                            </div>
                            <div className="row" style={{ border: '2px solid #173865', marginBottom: 30 }}></div>
                            <div className="row">
                                <Interweave content={articleContent} transform={transform} />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div >
            )
        }
    }

}

export default withTranslation()(ContentPageDetails);

