import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';
// import HomeV2 from './components/home-v2';
// import HomeV3 from './components/home-v3';
// import AboutUs from './components/about';
// import TourList from './components/tour-list';
// import TourListV2 from './components/tour-list-v2';
// import TourListV3 from './components/tour-list-v3';
// import TourDetails from './components/tour-details';
// import DestinationLIst from './components/destination-list';
// import DestinationLIstV2 from './components/destination-list-v2';
// import DestinationDetails from './components/destination-details';
// import Gallery from './components/gallery';
// import GalleryDetails from './components/gallery-details';
// import Faq from './components/faq';
// import Contact from './components/contact';
// import Error from './components/error';
// import CommingSoon from './components/comming-soon';
import UserProfile from './components/user-profile';
// import Blog from './components/blog';
// import BlogV2 from './components/blog-v2';
// import BlogV3 from './components/blog-v3';
// import BlogDetails from './components/blog-details';
// import Login from './components/login';
// import Logout from './components/logout';

import PlacesAgriculture from './components/places-agriculture.js';
import Places from './components/places';
import PlacesMap from './components/places-map';
import Activitys from './components/activitys';
import ActivitysMap from './components/activitys-map';
import Accommodation from './components/accommodation';
import AccommodationMap from './components/accommodation-map';
import Spa from './components/spa';
import SpaMap from './components/spa-map';
import TourOperator from './components/tour-operator';
import TourOperatorMap from './components/tour-operator-map';
import Restaurant from './components/restaurant';
import RestaurantMap from './components/restaurant-map';
import Store from './components/store';
import StoreMap from './components/store-map';
import TravelRoute from './components/travel-route';
import TravelRouteMap from './components/travel-route-map';
import TravelRouteCommunity from './components/travel-route-community';
import TravelRouteCommunityMap from './components/travel-route-community-map';
import Guide from './components/guide';
import GuideMap from './components/guide-map';

import PlacesDetails from './components/place-details';
import ActivityDetails from './components/activity-details';
import AccommodationDetails from './components/accommodation-details';
import SpaDetails from './components/spa-details';
import TourOperatorDetails from './components/tour-operator-details';
import RestaurantDetails from './components/restaurant-details';
import StoreDetails from './components/store-details';
import TravelRouteDetails from './components/travel-route-details';
import TravelRouteCommunityDetails from './components/travel-route-community-details';
import TravelRouteAttractionDetails from './components/travel-route-attraction-details';
import GuideDetails from './components/guide-details';

import AllContentStory from './components/all-content-story';
import AllContentPage from './components/all-content-page';
import ContentStoryDetails from './components/content-story-details';
import ContentPageDetails from './components/content-page-details';
import AllNews from './components/all-news';
import NewsDetails from './components/news-details';
import Campaign from './components/campaign';
import CampaignDetails from './components/campaign-details'
ReactGA.initialize('G-CSS0J0SR6N');

class Root extends Component {
	render() {
		return (
			<Router>
				<div>
					<Switch>
						{/* <Route exact path="/" component={Login} /> */}
						{/* <Route path="/login" component={Login} />
						<Route path="/logout" component={Logout} />
						<Route path="/home-v2" component={HomeV2} />
						<Route path="/home-v3" component={HomeV3} />
						<Route path="/about" component={AboutUs} />
						<Route path="/tour-list" component={TourList} />
						<Route path="/tour-list-v2" component={TourListV2} />
						<Route path="/tour-list-v3" component={TourListV3} />
						<Route path="/tour-details" component={TourDetails} />
						<Route path="/destination-list" component={DestinationLIst} />
						<Route path="/destination-list-v2" component={DestinationLIstV2} />
						<Route path="/destination-details" component={DestinationDetails} />
						<Route path="/gallery" component={Gallery} />
						<Route path="/gallery-details" component={GalleryDetails} />
						<Route path="/faq" component={Faq} />
						<Route path="/contact" component={Contact} />
						<Route path="/error" component={Error} />
						<Route path="/comming-soon" component={CommingSoon} />
						<Route path="/blog" component={Blog} />
						<Route path="/blog-v2" component={BlogV2} />
						<Route path="/blog-v3" component={BlogV3} />
						<Route path="/blog-details" component={BlogDetails} /> */}
						{/* route page*/}
						<Redirect exact from="/" to="/th" />
						<Route exact path="/:lng(th|en|zh)" render={(props) => <HomeV1 {...props} />} />
						<Route path="/user" component={UserProfile} />
						<Redirect exact from="/:maininfos" to="/th/:maininfos" />
						<Route path="/:lng(th|en|zh)/attractions/agriculture" render={(props) => <PlacesAgriculture {...props} />} />
						<Route path="/:lng(th|en|zh)/attractions" render={(props) => <Places {...props} />} />
						<Route path="/:lng(th|en|zh)/attractions_map" render={(props) => <PlacesMap {...props} />} />
						<Route path="/:lng(th|en|zh)/activities" render={(props) => <Activitys {...props} />} />
						<Route path="/:lng(th|en|zh)/activities_map" render={(props) => <ActivitysMap {...props} />} />
						<Route path="/:lng(th|en|zh)/accommodations" render={(props) => <Accommodation {...props} />} />
						<Route path="/:lng(th|en|zh)/accommodations_map" render={(props) => <AccommodationMap {...props} />} />
						<Route path="/:lng(th|en|zh)/spas" render={(props) => <Spa {...props} />} />
						<Route path="/:lng(th|en|zh)/spas_map" render={(props) => <SpaMap {...props} />} />
						<Route path="/:lng(th|en|zh)/restaurants" render={(props) => <Restaurant {...props} />} />
						<Route path="/:lng(th|en|zh)/restaurants_map" render={(props) => <RestaurantMap {...props} />} />
						<Route path="/:lng(th|en|zh)/stores" render={(props) => <Store {...props} />} />
						<Route path="/:lng(th|en|zh)/stores_map" render={(props) => <StoreMap {...props} />} />
						<Route path="/:lng(th|en|zh)/touroperators" render={(props) => <TourOperator {...props} />} />
						<Route path="/:lng(th|en|zh)/touroperators_map" render={(props) => <TourOperatorMap {...props} />} />
						<Route path="/:lng(th|en|zh)/routes" render={(props) => <TravelRoute {...props} />} />
						<Route path="/:lng(th|en|zh)/routes_map" render={(props) => <TravelRouteMap {...props} />} />
						<Route path="/:lng(th|en|zh)/community_routes" render={(props) => <TravelRouteCommunity {...props} />} />
						<Route path="/:lng(th|en|zh)/community_routes_map" render={(props) => <TravelRouteCommunityMap {...props} />} />
						<Route path="/:lng(th|en|zh)/contentstorys" component={AllContentStory} />
						<Route path="/:lng(th|en|zh)/contentpages" component={AllContentPage} />
						<Route path="/:lng(th|en|zh)/news" component={AllNews} />
						<Route path="/:lng(th|en|zh)/campaigns" component={Campaign} />
						<Route path="/:lng(th|en|zh)/guides" component={Guide} />
						<Route path="/:lng(th|en|zh)/guides_map" render={(props) => <GuideMap {...props} />} />
						{/* route page details*/}
						<Redirect exact from="/:maininfo/:id" to="/th/:maininfo/:id" />
						<Route path="/:lng(th|en|zh)/attraction/:id" component={PlacesDetails} />
						<Route path="/:lng(th|en|zh)/activity/:id" component={ActivityDetails} />
						<Route path="/:lng(th|en|zh)/accommodation/:id" component={AccommodationDetails} />
						<Route path="/:lng(th|en|zh)/spa/:id" component={SpaDetails} />
						<Route path="/:lng(th|en|zh)/restaurant/:id" component={RestaurantDetails} />
						<Route path="/:lng(th|en|zh)/store/:id" component={StoreDetails} />
						<Route path="/:lng(th|en|zh)/touroperator/:id" component={TourOperatorDetails} />
						<Route path="/:lng(th|en|zh)/route/:id" component={TravelRouteDetails} />
						<Route path="/:lng(th|en|zh)/community_routes_details/:id" component={TravelRouteCommunityDetails} />
						<Route path="/:lng(th|en|zh)/attraction_community/:id" component={TravelRouteAttractionDetails} />
						<Route path="/:lng(th|en|zh)/contentstory/:id" component={ContentStoryDetails} />
						<Route path="/:lng(th|en|zh)/contentpage/:id" component={ContentPageDetails} />
						<Route path="/:lng(th|en|zh)/new/:id" component={NewsDetails} />
						<Route path="/:lng(th|en|zh)/campaign/:id" component={CampaignDetails} />
						<Route path="/:lng(th|en|zh)/guide/:id" component={GuideDetails} />

						<Route path="*">
							<Redirect to="/" />
						</Route>

					</Switch>
				</div>
			</Router>
		)
	}
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('viaje'));
