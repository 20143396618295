import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import { api_endpoint } from '../../global-config'
import * as loadingData from "./loandtdd.json";
import Lottie from "react-lottie";
import { TabContent, TabPane, BreadcrumbItem } from "reactstrap";
import classnames from 'classnames';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { withTranslation } from 'react-i18next';
// import drop from "../../assets/images/icon/drop.svg"

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: '10px'
}
class Campaign_Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content_data: [],
      count_content: [],
      count: 0,
      skip: 0,
      limit: 12,
      lang: this.props.i18n.language,
      search: "",
      category: [],
      page_index: 0,
      pagination_min: -1,
      pagination_max: 5,
      searching: false,
      place_status: -1,
      provinces: [],
      provinces_id: [],
      regions: [],
      region_id: [],
      region_selected: 'กรุณาเลือกภูมิภาค',
      province_selected: 'กรุณาเลือกจังหวัด',
      isLoading: false,
      selectedLang: "th",
      activeTab: '1',
      ContentCategoryID: [4],
      regions_loading: true,
      provinces_loading: true,
      showingInfoWindow: false,
      activeMarker: {},          // Shows the active marker upon click
      selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
    }
  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  //คุมภาษา
  change_lang_callback() {

  }
  //มุมมอง
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  //ช่อง search
  searchInput(value) {
    this.setState({
      search: value.target.value
    })
  }

  //ค้นหาสถานที่
  async search_content() {
    setTimeout(this.setState({
      isLoading: true
    }), 250)
    if (this.state.search.length < 1 || this.state.search === "") {
      this.get_content()
      return
    }
    const response = await fetch(
      api_endpoint + `/contentinfo/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Search: this.state.search,
          Skip: this.state.skip,
          Limit: this.state.limit,
          Lang: this.state.lang,
          ContentCategoryID: this.state.ContentCategoryID,
          // MainTypeID: this.state.main_id_type,
          ProvinceCode: this.state.province_id,
          RegionID: this.state.region_id
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    if (_result.sortedResults.length > 0) {
      console.log('_result')
      if (this.state.searching === false) {
        console.log('searching')
        this.setState({
          content_data: _result.sortedResults,
          count: _result.count,
          pagination_min: -1,
          pagination_max: 5,
          page_index: 0,
          searching: true,
          isLoading: false
        }, () => {
          this.get_content_count()
        })
      }
      else {
        this.setState({
          content_data: _result.sortedResults,
          count: _result.count,
          // pagination_min: -1,
          // pagination_max: 5,
          // page_index: 0,
          searching: true,
          isLoading: false
        }, () => {
          this.get_content_count()
        })
      }

    }
    else {
      this.setState({
        content_data: _result.sortedResults,
        count: _result.count,
        // pagination_min: -1,
        // pagination_max: 5,
        // page_index: 0,
        searching: true,
        isLoading: false
      }, () => {
        this.get_content_count()
      })
    }


  }


  //เรียกประเภทสถานที่
  async get_content_types() {
    const response = await fetch(
      api_endpoint + `/contentinfo/readone`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ContentCategoryID: this.state.ContentCategoryID,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    let arr = []
    for (const [key, value] of Object.entries(_result)) {
      arr.push({ key: key, value: value, selected: false })
    }
    this.setState({
      category: arr
    }, () => {
      console.log(arr)
    })

  }


  //เรียกสถานที่
  async get_content() {
    const response = await fetch(
      api_endpoint + `/contentinfo/read`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Skip: this.state.skip,
          Limit: this.state.limit,
          ContentCategoryID: this.state.ContentCategoryID
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    if (_result.results.length > 0) {
      this.setState({
        content_data: _result.results,
        count: _result.count
      }, () => {
        this.get_content_count()
      })
    }
    else {
      this.setState({
        content_data: _result.results,
        count: _result.count
      }, () => {
        this.get_content_count()
      })
    }
  }


  //จำนวนข้อมูลทั้งหมด
  async get_content_count() {
    // const response = await fetch(
    //   `https://api.ttd.dodeep.work/api/v1/attraction/count`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({
    //       Subtype: this.state.subtype,
    //       Lang: this.state.lang
    //     }), // body data type must match "Content-Type" header
    //   }
    // );
    // var _result = await response.json();

    let arr = []
    for (let i = 0, j = 0; i < this.state.count / 12; i++) {
      if (i === this.state.page_index) {
        if (this.state.page_index === 0) {
          arr.push({ page: i + 1, onSelected: true, skip: 0, limit: 12 })
          continue
        }
        else {
          arr.push({ page: i + 1, onSelected: true, skip: this.state.page_index * 12, limit: 12 })
          j = this.state.page_index * 12
          continue
        }
      }
      else {
        if (i < 1) {
          arr.push({ page: i + 1, onSelected: false, skip: 0, limit: 12 })
          continue
        }
        else {
          j = j + 12
          arr.push({ page: i + 1, onSelected: false, skip: j, limit: 12 })
          continue
        }

      }

    }
    this.setState({
      count_content: arr,
      isLoading: false
    }, () => {
      console.log(this.state.count_content)
    })

  }


  //เลือกหน้า
  pageSelected(value) {
    console.log(value)

    console.log(value)
    let arr = this.state.count_content
    let prev_page = arr.filter(item => item.onSelected === true)
    arr[prev_page[0].page - 1] = { ...arr[prev_page[0].page - 1], onSelected: false }
    arr[value] = { ...arr[value], onSelected: true }
    let selected_page = arr[value]
    console.log(selected_page.skip)
    console.log(selected_page.limit)
    this.setState({
      count_content: arr,
      skip: selected_page.skip,
      limit: selected_page.limit,
      page_index: arr[value].page - 1
    }, () => {
      console.log(this.state.count_content)
      if (this.state.searching === true) {
        this.search_content()
      }
      else {
        this.get_content()
      }

    })
  }

  //เลื่อนขวา
  pagination_right() {
    if (this.state.page_index + 1 === this.state.pagination_max) {
      this.setState({
        pagination_min: this.state.pagination_min + 5,
        pagination_max: this.state.pagination_max + 5,
        page_index: this.state.page_index + 1
      }, () => {
        let arr = this.state.count_content
        let prev_page = arr.filter(item => item.onSelected === true)
        arr[prev_page[0].page - 1] = { ...arr[prev_page[0].page - 1], onSelected: false }
        arr[this.state.page_index] = { ...arr[this.state.page_index], onSelected: true }
        let selected_page = arr[this.state.page_index]
        this.setState({
          count_content: arr,
          skip: selected_page.skip,
          limit: selected_page.limit,
          page_index: arr[this.state.page_index].page - 1
        }, () => {
          if (this.state.searching === true) {
            this.search_content()
          }
          else {
            this.get_content()
          }
        })
      })
    }
    else {
      this.setState({
        page_index: this.state.page_index + 1
      }, () => {
        let arr = this.state.count_content
        let prev_page = arr.filter(item => item.onSelected === true)
        arr[prev_page[0].page - 1] = { ...arr[prev_page[0].page - 1], onSelected: false }
        arr[this.state.page_index] = { ...arr[this.state.page_index], onSelected: true }
        let selected_page = arr[this.state.page_index]
        this.setState({
          count_content: arr,
          skip: selected_page.skip,
          limit: selected_page.limit,
          page_index: arr[this.state.page_index].page - 1
        }, () => {
          if (this.state.searching === true) {
            this.search_content()
          }
          else {
            this.get_content()
          }
        })
      })
    }

  }


  //เลื่อนซ้าย
  pagination_left() {
    console.log(this.state.page_index)
    console.log(this.state.pagination_min)
    if (this.state.page_index - 1 === this.state.pagination_min) {
      this.setState({
        pagination_min: this.state.pagination_min - 5,
        pagination_max: this.state.pagination_max - 5,
        page_index: this.state.page_index + -1
      }, () => {
        let arr = this.state.count_content
        let prev_page = arr.filter(item => item.onSelected === true)
        arr[prev_page[0].page - 1] = { ...arr[prev_page[0].page - 1], onSelected: false }
        arr[this.state.page_index] = { ...arr[this.state.page_index], onSelected: true }
        let selected_page = arr[this.state.page_index]
        this.setState({
          count_content: arr,
          skip: selected_page.skip,
          limit: selected_page.limit,
          page_index: arr[this.state.page_index].page - 1
        }, () => {
          if (this.state.searching === true) {
            this.search_content()
          }
          else {
            this.get_content()
          }
        })
      })
    }
    else {
      this.setState({
        page_index: this.state.page_index - 1
      }, () => {
        let arr = this.state.count_content
        let prev_page = arr.filter(item => item.onSelected === true)
        arr[prev_page[0].page - 1] = { ...arr[prev_page[0].page - 1], onSelected: false }
        arr[this.state.page_index] = { ...arr[this.state.page_index], onSelected: true }
        let selected_page = arr[this.state.page_index]
        this.setState({
          count_content: arr,
          skip: selected_page.skip,
          limit: selected_page.limit,
          page_index: arr[this.state.page_index].page - 1
        }, () => {
          if (this.state.searching === true) {
            this.search_content()
          }
          else {
            this.get_content()
          }
        })
      })
    }

  }

  //เช็คบล๊อกเลือกประเภท
  category_selected(value) {
    let arr = this.state.category
    let prev_selected = arr.filter(item => item.selected === true)
    let subtype_arr = this.state.subtype
    if (prev_selected.length < 1) {
      arr[value] = { ...arr[value], selected: true }
      subtype_arr.push(arr[value].key)
      this.setState({
        category: arr,
        pagination_min: -1,
        pagination_max: 5,
        skip: 0,
        limit: 12,
        page_index: 0,
        subtype: subtype_arr
      }, () => {
        console.log(this.state.category)
        console.log(this.state.subtype)
        if (this.state.search.length > 0) {
          this.search_content()
        }
        else {
          this.get_content()
        }
      })
    }
    else {
      if (subtype_arr.indexOf(arr[value].key) === -1) {
        arr[value] = { ...arr[value], selected: true }
        subtype_arr.push(arr[value].key)
        this.setState({
          category: arr,
          pagination_min: -1,
          pagination_max: 5,
          skip: 0,
          limit: 12,
          page_index: 0,
          subtype: subtype_arr
        }, () => {
          console.log(this.state.category)
          console.log(this.state.subtype)
          if (this.state.search.length > 0) {
            this.search_content()
          }
          else {
            this.get_content()
          }
        })
      }
      else {
        let index = parseInt(subtype_arr.indexOf(arr[value].key))
        arr[value] = { ...arr[value], selected: false }
        subtype_arr.splice(index, 1)
        this.setState({
          category: arr,
          pagination_min: -1,
          pagination_max: 5,
          skip: 0,
          limit: 12,
          page_index: 0,
          subtype: subtype_arr
        }, () => {
          console.log(this.state.category)
          console.log(this.state.subtype)
          if (this.state.search.length > 0) {
            this.search_content()
          }
          else {
            this.get_content()
          }
        })
      }


      // if (prev_selected[0].key == arr[value].key) {
      //   arr[value] = { ...arr[value], selected: false }
      //   this.setState({
      //     category: arr,
      //     pagination_min: -1,
      //     pagination_max: 5,
      //     skip: 0,
      //     limit: 12,
      //     page_index: 0,
      //     subtype: subtype_arr.slice(subtype_arr.indexOf(arr[value].key),1)
      //   }, () => {
      //     console.log(this.state.category)
      //     console.log(this.state.subtype)
      //     if (this.state.search.length > 0) {
      //       this.search_content()
      //     }
      //     else {
      //       this.get_content()
      //     }
      //   })
      // }
      // else {
      //   let prev_selected_cancel = arr.findIndex(item => item.key === prev_selected[0].key)
      //   console.log(prev_selected_cancel)
      //   arr[prev_selected_cancel] = { ...arr[prev_selected_cancel], selected: false }
      //   arr[value] = { ...arr[value], selected: true }
      //   subtype_arr.push(arr[value].key)
      //   this.setState({
      //     category: arr,
      //     pagination_min: -1,
      //     pagination_max: 5,
      //     skip: 0,
      //     limit: 12,
      //     page_index: 0,
      //     subtype: subtype_arr
      //   }, () => {
      //     console.log(this.state.category)
      //     console.log(this.state.subtype)
      //     if (this.state.search.length > 0) {
      //       this.search_content()
      //     }
      //     else {
      //       this.get_content()
      //     }
      //   })
      // }

    }

  }



  //ปิลเตอร์สถานที่เปิดปิด
  place_status(event) {
    console.log(event.target.getAttribute("data-value"))
    if (event.target.value === 0) {
      let arr = this.state.content_data
      let filter_places = arr.filter(item => item.IsOpen === event.target.getAttribute("data-value"))
      console.log(filter_places)

    }
    if (event.target.value === 1) {
      let arr = this.state.content_data
      let filter_places = arr.filter(item => item.IsOpen === event.target.getAttribute("data-value"))
      console.log(filter_places)

    }
    if (event.target.value === -1) {
      let arr = this.state.content_data
      let filter_places = arr.filter(item => item.IsOpen === event.target.getAttribute("data-value"))
      console.log(filter_places)

    }
    else {
      return
    }

  }

  //เรียกข้อมูลภูมิภาค
  async lkup_regions() {
    const response = await fetch(
      api_endpoint + `/lkup/regions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Lang: this.state.lang,
          RegionID: this.state.region_id,

        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    var regions = []
    regions.push(
      {
        "ID": 0,
        "Details": {
          [this.state.lang]: {
            "Name": this.props.t('All regions')
          }
        }
      },
    )
    regions = regions.concat(_result)
    console.log(_result)
    this.setState({
      regions: regions,
      regions_loading: false
    })
  }

  //เรียกข้อมูลจังหวัด
  async lkup_provinces() {
    const response = await fetch(
      api_endpoint + `/lkup/provinces`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Lang: this.state.lang,
          RegionID: this.state.region_id,

        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    this.setState({
      provinces: _result,
      provinces_loading: false
    })
  }

  //เลือกภูมิภาค
  region_selected(value) {
    let arr = []
    arr.push(value.ID)
    this.setState({
      region_selected: value.Details[this.state.lang].Name,
      region_id: arr,
      province_selected: 'กรุณาเลือกจังหวัด',
      provinces_id: []
    }, () => {
      this.lkup_provinces()
      if (this.state.searching === true) {
        this.search_content()
      }
      else {
        this.get_content()
      }
    })
  }

  //เลือกจังหวัด
  province_selected(value) {
    console.log(value)
    let arr = []
    arr.push(value.Code.toString())
    this.setState({
      province_selected: value.Details[this.state.lang].Name,
      provinces_id: arr
    }, () => {
      console.log(this.state.provinces_id)
      if (this.state.searching === true) {
        this.search_content()
      }
      else {
        this.get_content()
      }
    })
  }
  renderMarker(e) {
    var newURL = window.location.origin + "/" + this.state.lang + "/campaign/" + e.ID
    return (
      <div>
        <p className="normal-text-14" style={{ color: 'gray', marginLeft: 81 }}><a hrefLang={this.state.lang} href={newURL}>{this.props.t('See more details')}</a></p>
      </div>
    )
  }


  componentDidMount() {
    const language = localStorage.getItem("I18N_LANGUAGE")
    if (!language) {
      localStorage.setItem("I18N_LANGUAGE", "th")
    }
    console.log(language)
    // const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    // console.log(this.state.selectedLang)
    // this.setState({ selectedLang: currentLanguage })
    this.get_content()
    this.get_content_count()
    this.lkup_provinces()
    this.lkup_regions()
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    this.setState({
      isLoading: true,
      lang: nextProps.i18n.language
    }, () => {
      this.get_content()
      this.get_content_count()
      this.lkup_provinces()
      this.lkup_regions()
    })


  }

  render() {
    if (this.state.isLoading === true || this.state.provinces_loading === true || this.state.regions_loading === true) {
      return (
        <div className="tour-list-area pd-top-80 viaje-go-top">
          <div className="container">
            <div className="row" style={{ marginBottom: 20 }}>
              <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6 ">
                <div className="new-breadcrumb">
                  <BreadcrumbItem><a hrefLang={this.state.lang} href={"/" + this.state.lang} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                  <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/campaigns"} className="normal-text cl-black">{this.props.t('Campaign')}</a></BreadcrumbItem>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="row style-breadcrumb">
                  <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                  <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                    <div className="small-text viewpoint-list cl-blue">
                      <i className="fa fa-list-ul viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                      <span data-nosnippet>{this.props.t('list')}</span>
                    </div>
                  </div>
                  <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                    <div className="small-text viewpoint-list">
                      <i className="fa fa-map-o viewpoint-list-size" aria-hidden="true" ></i>
                      <span data-nosnippet>{this.props.t('Maps')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 order-lg-1">
                <div className="sidebar-area sidebar-area-inner-page">
                  <div className="widget tour-list-widget">
                    <div className="single-widget-search-input-title">{this.props.t('Search for Campaign page')}</div>
                    <div className="widget-tour-list-search">
                      <div className="search-form">
                        <div className="form-group">
                          <input type="text" placeholder={this.props.t('Campaign')} value={this.state.search} onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                              console.log('do validate');
                              this.search_content()
                            }
                          }} onChange={(e) => { this.searchInput(e) }} />
                        </div>
                        <button className="submit-btn" type="button" onClick={() => { this.search_content() }}><i className="ti-search" /></button>
                      </div>
                    </div>
                    <Link className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-4 order-lg-12">
                <div style={{ justifyContent: 'center', alignSelf: 'center' }}>
                  <Lottie options={defaultOptions} height={300} width={300} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      let publicUrl = process.env.PUBLIC_URL + '/'
      return <div className="tour-list-area pd-top-80 viaje-go-top">
        <div className="container">
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1" id="all-order">
              <div className="row" style={{ marginBottom: 20 }}>
                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12">
                  <div className="new-breadcrumb">
                    <BreadcrumbItem><a hrefLang={this.state.lang} href={"/" + this.state.lang} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                    <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/campaigns"} className="normal-text cl-black">{this.props.t('Campaign')}</a></BreadcrumbItem>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="row style-breadcrumb">
                    <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                    <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                      <div className="small-text viewpoint-list cl-blue">
                        <i className="fa fa-list-ul viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                        <span data-nosnippet>{this.props.t('list')}</span>
                      </div>
                    </div>
                    <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                      <div className="small-text viewpoint-list">
                        <i className="fa fa-map-o viewpoint-list-size" aria-hidden="true" ></i>
                        <span data-nosnippet>{this.props.t('Maps')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                { /* Search Box*/}
                <div className="col-xl-3 col-lg-4 order-lg-1">
                  <div className="sidebar-area sidebar-area-inner-page">
                    <div className="widget tour-list-widget">
                      <div className="single-widget-search-input-title">{this.props.t('Search for Campaign page')}</div>
                      <div className="widget-tour-list-search">
                        <div className="search-form">
                          <div className="form-group">
                            <input type="text" placeholder={this.props.t('Campaign')} value={this.state.search} onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                console.log('do validate');
                                this.search_content()
                              }
                            }} onChange={(e) => { this.searchInput(e) }} />
                          </div>
                          <button className="submit-btn" type="button" onClick={() => { this.search_content() }}><i className="ti-search" /></button>
                        </div>
                      </div>
                      <Link className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 order-lg-12">
                  <div >
                    {this.state.content_data.length > 0 ?
                      <div className="row justify-content-center">
                        {this.state.content_data.map((e, i) => {
                          let content_name = ""
                          if (e.Title[this.props.i18n.language] !== undefined && e.Title[this.props.i18n.language] !== "" && e.Title[this.props.i18n.language] !== null) {
                            content_name = e.Title[this.props.i18n.language]
                          }
                          else {
                            content_name = e.Title.th === undefined ? e.Title : e.Title.th
                          }
                          return (
                            <div key={i} className="col-lg-4 col-sm-6">
                              <div className="single-destinations-list style-two animated slideInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
                                <Link hrefLang={this.state.lang} to={{ pathname: "/" + this.state.lang + "/campaign/" + e.ID, params: e.ID }}>
                                  <div className="thumb">
                                    <img style={{ height: 220, width: '100%' }} src={e.IntroImage} onError={(event) => event.target.setAttribute("src", "https://thailandtourismdirectory.go.th/assets/upload/icons/no-image.jpg")} alt={content_name} />
                                  </div>
                                </Link>
                                <div className="details" style={{ padding: 10 }}>
                                  <h4 className="normal-text-18 weight-700 cl-black">{content_name.length > 25 ? content_name.slice(0, 25) + "..." : content_name}</h4>
                                  <div className="row" style={{ justifyContent: 'space-between', margin: 2 }}>
                                    <div className="content">
                                      <p className="normal-text-14" style={{ color: 'gold' }}><span data-nosnippet>{this.props.t('Official Verify')}</span></p>
                                    </div>
                                    <div className="content">
                                      <p className="normal-text-14" style={{ color: 'gray' }}><Link hrefLang={this.state.lang} to={{ pathname: "/" + this.state.lang + "/campaign/" + e.ID, params: e.ID, }}><span data-nosnippet>{this.props.t('Details')}</span></Link></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      :
                      <div className="col-xl-12 col-lg-12 order-lg-12">
                        <div style={{ justifyContent: 'center', alignSelf: 'center' }}>
                          <Lottie options={defaultOptions} height={300} width={300} />
                          <p className="normal-text-14" style={{ textAlign: 'center' }}>ไม่สามารถโหลดข้อมูลได้หรืออาจจะไม่มีข้อมูลนี้อยู่ในระบบ</p>
                          <p className="normal-text-14" style={{ textAlign: 'center' }}>กรุณาลองเปลี่ยนคำค้นหาเป็นคำอื่นๆ</p>
                        </div>
                      </div>
                    }
                    {this.state.content_data.length > 0 ?
                      <div className="col-lg-12 text-center">
                        <div className="tp-pagination text-center d-inline-block mt-4">
                          <ul>
                            {this.state.page_index === 0 ?
                              null
                              :
                              <li onClick={() => { this.pagination_left() }}><a href="!#" className="prev page-numbers" ><i className="la la-long-arrow-left" /></a></li>
                            }
                            {this.state.count_content.map((e, i) => {
                              if (i > this.state.pagination_min && i < this.state.pagination_max) {
                                if (e.onSelected === true) {
                                  return (
                                    <li key={i} onClick={() => { this.pageSelected(i) }}><span className="page-numbers current">{e.page}</span></li>
                                  )
                                }
                                else {
                                  return (
                                    <li key={i} onClick={() => { this.pageSelected(i) }}><span className="page-numbers">{e.page}</span></li>
                                  )
                                }
                              }
                              else {
                                return (
                                  null
                                )
                              }
                            })}
                            {this.state.page_index === this.state.count_content.length - 1 ?
                              null
                              :
                              <li onClick={() => { this.pagination_right() }}><a href="!#" className="next page-numbers" ><i className="la la-long-arrow-right" /></a></li>
                            }
                          </ul>
                        </div>
                      </div>
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2" id="processing">
              <div className="row" style={{ marginBottom: 20 }}>
                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12">
                  <div className="new-breadcrumb">
                    <BreadcrumbItem><a hrefLang={this.state.lang} href={"/" + this.state.lang} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                    <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/campaigns"} className="normal-text cl-black">{this.props.t('Campaign')}</a></BreadcrumbItem>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="row style-breadcrumb">
                    <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                    <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                      <div className="small-text viewpoint-list">
                        <i className="fa fa-list-ul viewpoint-list-size " aria-hidden="true" ></i>
                        <span data-nosnippet>{this.props.t('list')}</span>
                      </div>
                    </div>
                    <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                      <div className="small-text viewpoint-list cl-blue">
                        <i className="fa fa-map-o viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                        <span data-nosnippet>{this.props.t('Maps')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                { /* Search Box*/}
                <div className="col-xl-3 col-lg-4 order-lg-1">
                  <div className="sidebar-area sidebar-area-inner-page">
                    <div className="widget tour-list-widget">
                      <div className="single-widget-search-input-title">{this.props.t('Search for Campaign page')}</div>
                      <div className="widget-tour-list-search">
                        <div className="search-form">
                          <div className="form-group">
                            <input type="text" placeholder={this.props.t('Campaign')} value={this.state.search} onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                console.log('do validate');
                                this.search_content()
                              }
                            }} onChange={(e) => { this.searchInput(e) }} />
                          </div>
                          <button className="submit-btn" type="button" onClick={() => { this.search_content() }}><i className="ti-search" /></button>
                        </div>
                      </div>
                      <Link className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 order-lg-12">
                  <div className="row">
                    <div id="gmaps-markers" className="gmaps" style={{ height: 750, width: '100%' }} >
                      <Map
                        google={this.props.google}
                        zoom={6}
                        containerStyle={containerStyle}
                        initialCenter={
                          {
                            // lat: 10.730483283967,
                            // lng: 99.322113990784
                            lat: 13.75,
                            lng: 100.517
                          }
                        }
                      >
                        {this.state.content_data.map((e, i) => {
                          let content_name = ""
                          if (e.Title[this.props.i18n.language] !== undefined && e.Title[this.props.i18n.language] !== "" && e.Title[this.props.i18n.language] !== null) {
                            content_name = e.Title[this.props.i18n.language]
                          }
                          else {
                            content_name = e.Title.th === undefined ? e.Title : e.Title.th
                          }
                          return (
                            <Marker key={i}
                              text="My Marker"
                              onClick={this.onMarkerClick}
                              image={e.IntroImage}
                              name={content_name.length > 15 ? content_name.slice(0, 15) + "..." : content_name}
                              province={e.Province[this.state.lang]}
                              position={{ lat: e.Latitude, lng: e.Longitude }}
                              time={e.IsOpen === 1 ?
                                <div className="normal-text-14" style={{ color: '#339933' }}><span data-nosnippet>{this.props.t('Open')}</span></div>
                                :
                                <div className="normal-text-14" style={{ color: 'gray' }}><span data-nosnippet>{this.props.t('Closed')}</span></div>
                              }
                              detail={this.renderMarker(e)} />
                          )
                        })}
                        <InfoWindow
                          marker={this.state.activeMarker}
                          visible={this.state.showingInfoWindow}
                          onClose={this.onClose}
                        >
                          <div className="row" style={{ marginLeft: 0 }}>
                            <img style={{ height: 75, width: 75, borderRadius: 7, objectFit: 'cover' }} src={this.state.selectedPlace.image} alt="" />
                            <div className="row" style={{ display: 'inline-block', marginLeft: 10 }}>
                              <p className="normal-text-18 cl-black" style={{ lineHeight: '19px' }}>{this.state.selectedPlace.name}</p>
                              <p className="normal-text-14" style={{ color: '#173865' }}><img style={{ marginRight: 8 }} src={publicUrl + "assets/img/icons/location.png"} alt="" />{this.state.selectedPlace.province}</p>
                              <p>{this.state.selectedPlace.time}</p>
                            </div>
                          </div>
                          <div className="row">
                            <p>{this.state.selectedPlace.detail}</p>
                          </div>
                        </InfoWindow>
                      </Map>
                    </div>
                    {this.state.content_data.length > 0 ?
                      <div className="col-lg-12 text-center">
                        <div className="tp-pagination text-center d-inline-block mt-4">
                          <ul>
                            {this.state.page_index === 0 ?
                              null
                              :
                              <li onClick={() => { this.pagination_left() }}><a href="!#" className="prev page-numbers" ><i className="la la-long-arrow-left" /></a></li>
                            }

                            {this.state.count_content.map((e, i) => {
                              if (i > this.state.pagination_min && i < this.state.pagination_max) {
                                if (e.onSelected === true) {
                                  return (
                                    <li key={i} onClick={() => { this.pageSelected(i) }}><span className="page-numbers current">{e.page}</span></li>
                                  )
                                }
                                else {
                                  return (
                                    <li key={i} onClick={() => { this.pageSelected(i) }}><span className="page-numbers">{e.page}</span></li>
                                  )
                                }
                              }
                              else {
                                return (
                                  null
                                )
                              }
                            })}
                            {this.state.page_index === this.state.count_content.length - 1 ?
                              null
                              :
                              <li onClick={() => { this.pagination_right() }}><a href="!#" className="next page-numbers" ><i className="la la-long-arrow-right" /></a></li>
                            }
                          </ul>
                        </div>
                      </div>
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent >
        </div>
      </div>
    }
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
  language: 'th',
})(withTranslation()(Campaign_Main));
