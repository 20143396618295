import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';
class Intro extends Component {

	render() {

		// let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="intro-area pd-top-112">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<div className="single-intro wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
							<h4 className="intro-title">
								<span className="intro-count light-blue">01</span>
								<Link to="#" className="intro-cat cl-black" style={{ cursor: 'default' }}>{this.props.t('Accurate information')}</Link>
							</h4>
							<p className="large-text light-gray">{this.props.t('up-to-date, Trusted information source from government organizations and verified.')}</p>
						</div>
					</div>
					<div className="col-md-4">
						<div className="single-intro wow animated fadeInUp" data-wow-duration="1.0s" data-wow-delay="0.2s">
							<h4 className="intro-title">
								<span className="intro-count light-blue">02</span>
								<Link to="#" className="intro-cat cl-black" style={{ cursor: 'default' }}>{this.props.t('New experience')}</Link>
							</h4>
							<p className="large-text light-gray">{this.props.t('Many tourism activities that are ready to give you a new experience of doing activities.')}</p>
						</div>
					</div>
					<div className="col-md-4">
						<div className="single-intro wow animated fadeInUp" data-wow-duration="1.6s" data-wow-delay="0.3s">
							<h4 className="intro-title">
								<span className="intro-count light-blue">03</span>
								<Link to="#" className="intro-cat cl-black" style={{ cursor: 'default' }}>{this.props.t('Up-to-date')}</Link>
							</h4>
							<p className="large-text light-gray">{this.props.t('There is a ranking of popular tourist spots and recommended places for you as you want.')}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	}
}

export default withTranslation()(Intro);