import React, { Component } from 'react';

class PageHeaderCampaign extends Component {

	render() {

		let HeaderTitle = this.props.headertitle;
		let publicUrl = process.env.PUBLIC_URL + '/'
		// let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle
		// let imagealt = 'image'

		return (
			<div className="breadcrumb-area jarallax " style={{ backgroundImage: 'url(' + publicUrl + "../../assets/img/bg/campaign.png", backgroundPositionY: 'bottom' }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcrumb-inner">
								<h1 className="page-title">{HeaderTitle}</h1>
								<ul className="page-list">
									{/* <li><Link to="/">Home</Link></li> */}
									{/* <li>{Subheader}</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			//  <div className="breadcrumb-area jarallax" style={{backgroundImage: 'url('+publicUrl+'assets/img/bg/hotel.jpg)'}}>
			// 	  <div className="container">
			// 	    <div className="row">
			// 	      <div className="col-lg-12">
			// 	        <div className="breadcrumb-inner">
			// 	          <h1 className="page-title">{ HeaderTitle }</h1>
			// 	          <ul className="page-list">
			// 	          </ul>
			// 	        </div>
			// 	      </div>
			// 	    </div>
			// 	  </div>
			// 	</div>
			// <div className="banner-area viaje-go-top jarallax">
			// 	<div className="banner-slider">
			// 		<div className="banner-slider-item hg banner-bg-hotel">
			// 			<div className="container">
			// 				<div className="row">
			// 					<div className="col-lg-12">
			// 						<div className="banner-inner">
			// 							<h1 className="banner-title " style={{ fontSize: 64, padding: 20, margin: 75, textAlign: 'center', color: 'white' }}>{HeaderTitle}</h1>
			// 						</div>
			// 					</div>
			// 				</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>



		)
	}
}


export default PageHeaderCampaign