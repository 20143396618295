import React, { Component } from 'react'
import Navbar from './global-components/navbar';
import PageHeaderTourOperator from './global-components/page-header-tour-operator';
import TourOperatorMap from './section-components/tour-operator-map';
// import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';
import { withTranslation } from 'react-i18next';
import { api_endpoint } from '../global-config'
import ReactGA from 'react-ga4';

class TourOperatorPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            page: 0,
            region_id: 0,
            province_id: 0,
            province_selected: "",
            page_name: ""
        }
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        this.lkup_provinces()
    }

    //เรียกข้อมูลจังหวัด
    async lkup_provinces() {
        const response = await fetch(
            api_endpoint + `/lkup/provinces`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Lang: this.state.lang,
                    RegionID: [],
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        for (let province of _result) {
            if (province.Details[this.props.i18n.language]["Code"] === this.state.province_id) {
                this.setState({
                    province_selected: province.Details[this.props.i18n.language]["Name"]
                })
                break
            }
        }
    }

    render() {
        this.state.search = new URLSearchParams(this.props.location.search).get("search")
        this.state.page = new URLSearchParams(this.props.location.search).get("page")
        this.state.region_id = new URLSearchParams(this.props.location.search).get("region_id")
        this.state.province_id = new URLSearchParams(this.props.location.search).get("province_id")
        this.state.page_name = this.props.t('Tour Operators') + (this.state.province_id !== null && this.state.province_id !== 0 ? this.props.t(' ') + this.props.t('in') + this.props.t(' ') + this.state.province_selected : '')
        return (
            <div>
                <Navbar />
                <PageHeaderTourOperator headertitle={this.state.page_name} />

                <TourOperatorMap search={this.state.search}
                    page={this.state.page}
                    region_id={this.state.region_id}
                    province_id={this.state.province_id}
                />
                {/* <Subscribe /> */}
                <Footer />
            </div>
        )
    }
}
export default withTranslation()(TourOperatorPage);