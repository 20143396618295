import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Accommodation extends Component {
	constructor(props) {
		super(props)
		this.state = {


		}
	}

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return <div className="upcomming-tour upcomming-tour-bg pd-top-75 pd-bottom-120 viaje-go-top" >
			<div className="container">
				<div className="row">
					<div className="col-xl-4 col-lg-4">
						<div className="section-title">
							<h2 className="title large-text-48 cl-black">{this.props.t('Accommodations')}</h2>
							<p className="large-text light-gray">{this.props.t('Rooms and services that will blow your mind')}</p>
							<Link hrefLang={this.props.i18n.language} className="btn btn-gray" to={"/" + this.props.i18n.language + "/accommodations"} style={{ fontSize: 24, marginTop: 30, borderRadius: 39 }} target="_blank" rel="noopener noreferrer">{this.props.t('View more')}</Link>
						</div>
						<div className="row">
							<div className="col-lg-8">
								<img className="style-img-bag opacity" src={publicUrl + "assets/img/bag.png"} alt="bag" />
								<img className="style-img-map opacity" src={publicUrl + "assets/img/thai-map.png"} alt="Map" />
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-lg-8">
						<div className="upcomming-card-slider upcomming-card-slider-2 tp-common-slider-style">
							<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/accommodation/" + this.props.accommodation[0].ID, params: this.props.accommodation[0].ID !== undefined ? this.props.accommodation[0].ID : null }}>
								<div className="single-upconing-card">
									<div className="shadow">
										<img className="province-img-hotel" src={this.props.accommodation.length < 1 ? null : this.props.accommodation[0].IntroImage} alt="" />
									</div>

									<div className="details">
										<h3 className="title" style={{ color: 'white', fontSize: 24 }}>{this.props.accommodation[0].Name.th}</h3>
										<p><i className="fa fa-map-marker" /> {this.props.accommodation[0].Province[this.props.i18n.language]}</p>
										<div className="tp-review-meta">
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<span>4.0</span>
										</div>
									</div>
								</div>
							</Link>
							<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/accommodation/" + this.props.accommodation[1].ID, params: this.props.accommodation[1].ID !== undefined ? this.props.accommodation[1].ID : null }}>
								<div className="single-upconing-card">
									<div className="shadow">
										<img className="province-img-hotel" src={this.props.accommodation[1].IntroImage} alt="" />
									</div>
									<div className="details">
										<h3 className="title" style={{ color: 'white', fontSize: 24 }}>{this.props.accommodation[1].Name.th}</h3>
										<p><i className="fa fa-map-marker" /> {this.props.accommodation[1].Province[this.props.i18n.language]}</p>
										<div className="tp-review-meta">
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<span>4.0</span>
										</div>
									</div>
								</div>
							</Link>
							<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/accommodation/" + this.props.accommodation[2].ID, params: this.props.accommodation[2].ID !== undefined ? this.props.accommodation[2].ID : null }}>
								<div className="single-upconing-card">
									<div className="shadow" >
										<img className="province-img-hotel" src={this.props.accommodation[2].IntroImage} alt="" />
									</div>
									<div className="details">
										<h3 className="title" style={{ color: 'white', fontSize: 24 }}>{this.props.accommodation[2].Name.th}</h3>
										<p><i className="fa fa-map-marker" /> {this.props.accommodation[2].Province[this.props.i18n.language]}</p>
										<div className="tp-review-meta">
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<span>4.0</span>
										</div>
									</div>
								</div>
							</Link>
							<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/accommodation/" + this.props.accommodation[3].ID, params: this.props.accommodation[3].ID !== undefined ? this.props.accommodation[3].ID : null }}>
								<div className="single-upconing-card">
									<div className="shadow" >
										<img className="province-img-hotel" src={this.props.accommodation[3].IntroImage} alt="" />
									</div>
									<div className="details">
										<h3 className="title" style={{ color: 'white', fontSize: 24 }}>{this.props.accommodation.length < 1 ? null : this.props.accommodation[3].Name.th}</h3>
										<p><i className="fa fa-map-marker" /> {this.props.accommodation.length < 1 ? null : this.props.accommodation[3].Province[this.props.i18n.language]}</p>
										<div className="tp-review-meta">
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<i className="ic-yellow fa fa-star" />
											<span>4.0</span>
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default withTranslation()(Accommodation);