import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import { api_endpoint } from '../../global-config'
import * as loadingData from "./loandtdd.json";
import Lottie from "react-lottie";
import { TabContent, TabPane, BreadcrumbItem } from "reactstrap";
import classnames from 'classnames';
import { GoogleApiWrapper } from "google-maps-react";
import { withTranslation } from 'react-i18next';
import drop from "../../assets/images/icon/drop.svg"
// import { Helmet } from "react-helmet";
// import { relativeTimeThreshold } from 'moment';
// import { composeInitialProps } from 'react-i18next';
// import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
// const number_of_days = [
//   { id: 1, value: this.props.t('1 Day') },
//   { id: 2, value: this.props.t('2 Days') },
//   { id: 3, value: this.props.t('3 Days') },
//   { id: 4, value: this.props.t('4 Days') },
//   { id: 5, value: this.props.t('5 Days') }

// ]
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
// const containerStyle = {
//   position: 'relative',
//   width: '100%',
//   height: '100%',
//   borderRadius: '10px'
// }

class TravelRouteCommunity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      days: [
        { id: 1, value: this.props.t('1 Day') },
        { id: 2, value: this.props.t('2 Days') },
        { id: 3, value: this.props.t('3 Days') },
        { id: 4, value: this.props.t('4 Days') },
        { id: 5, value: this.props.t('5 Days') }
      ],
      days_selected: this.props.t('Please select number of days'),
      days_id: 0,
      places_data: [],
      count_places: [],
      provinces: [],
      province_id: 0,
      regions: [],
      region_id: 0,
      region_selected: '',
      province_selected: this.props.t('Please select a Province'),
      province_name: "",
      regions_loading: true,
      provinces_loading: true,
      isLoading: false,
      activeTab: '1',
      page_index: 1,
      totalResult: 0,
      pageNumber: 1,
      maxPage: 9,
      pagination_min: -1,
      pagination_max: 5,
      lang: this.props.i18n.language,
      path: "community_routes",
      page_name: this.props.t('Community-Based Tourism Routes'),
      sentence: ""
    }
    if (props.page !== null && !isNaN(props.page)) {
      this.state.page_index = parseInt(props.page)
    }
    if (props.days_id !== null && !isNaN(props.days_id)) {
      this.state.days_id = parseInt(props.days_id)
    }
    if (props.region_id !== null && !isNaN(props.region_id)) {
      this.state.region_id = parseInt(props.region_id)
    }
    if (props.province_id !== null && !isNaN(props.province_id)) {
      this.state.province_id = parseInt(props.province_id)
    }
    if (props.province_name !== "") {
      this.state.province_name = props.province_name
    }
    if (props.types !== undefined) {
      this.state.types = props.types.split(",").map(x => +x);
    }
    if (props.path !== undefined) {
      this.state.path = props.path;
    }
    if (props.page_name !== undefined) {
      this.state.page_name = props.page_name;
    }
  }

  //มุมมอง
  toggleTab(tab) {
    if (tab === '1') {
      this.search_reload("/" + this.state.lang + "/community_routes")
    } else if (tab === '2') {
      this.search_reload("/" + this.state.lang + "/community_routes_map")
    }
  }

  async get_places() {
    const response = await fetch(
      api_endpoint + `/cbt/route_get`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          provinceName: this.state.province_name === "null" ? "" : this.state.province_name,
          numberOfDay: this.state.days_id,
          pageNumber: this.state.page_index,
          numberOfResult: this.state.maxPage,
          language: this.state.lang
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    // console.log(_result)
    if (_result.result.length > 0) {
      this.setState({
        places_data: _result.result,
        totalResult: _result.page.totalResult,
      }, () => {
        this.get_places_count()
      })
    }
  }
  //จำนวนข้อมูลทั้งหมด
  async get_places_count() {
    let arr = []
    for (let i = this.state.page_index; i < (this.state.totalResult / 9) + 1; i++) {
      if (i === this.state.page_index) {
        arr.push({ page: i, onSelected: true })
        continue
      } else {
        arr.push({ page: i, onSelected: false })
        continue
      }
    }
    this.setState({
      count_places: arr,
      isLoading: false
    }, () => {
      // console.log(this.state.count_places)
    })
  }
  async search_reload(path) {
    window.location.href = path + "?page=" + (this.state.page_index)
      + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
      + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")
      + ((this.state.province_name !== null) ? "&province_name=" + this.state.province_name : "")
      + ((this.state.days_id !== 0 ? "&days_id=" + this.state.days_id : ""))
  }

  //เรียกข้อมูลภูมิภาค
  async lkup_regions() {
    const response = await fetch(
      api_endpoint + `/lkup/regions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Lang: this.state.lang,
          // RegionID: this.state.region_id,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    var regions = []
    regions.push(
      {
        "ID": 0,
        "Details": {
          [this.state.lang]: {
            "Name": this.props.t('All regions')
          }
        }
      },
    )
    regions = regions.concat(_result)
    for (let region of regions) {
      if (region.ID === this.state.region_id) {
        this.state.region_selected = region.Details[this.state.lang]["Name"]
      }
    }
    this.setState({
      regions: regions,
      regions_loading: false
    })
  }

  //เรียกข้อมูลจังหวัด
  async lkup_provinces() {
    const response = await fetch(
      api_endpoint + `/lkup/provinces`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Lang: this.state.lang,
          RegionID: this.state.region_id !== 0 ? [this.state.region_id] : [],
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    for (let province of _result) {
      if (province.Details[this.state.lang]["Code"] === this.state.province_id) {
        this.state.province_selected = province.Details[this.state.lang]["Name"]
      }
    }
    this.setState({
      provinces: _result,
      provinces_loading: false
    })
  }
  //จำนวนวัน
  async number_of_days_submit(value) {
    this.state.page_index = 1
    this.state.days_id = value.id
    this.search_reload(window.location.pathname)
  }
  //เลือกภูมิภาค
  async region_selected_submit(value) {
    this.state.page_index = 1
    this.state.region_id = value.ID
    this.state.province_id = 0
    this.lkup_provinces()
    this.state.region_selected = value.Details[this.state.lang].Name
    if (this.state.region_selected) {
      this.setState({
        sentence: this.props.t('Continue selecting the province topic to search')
      })
    }
  }

  //เลือกจังหวัด
  async province_selected_submit(value) {
    this.state.page_index = 1
    this.state.region_id = 0
    this.state.province_id = value.Code.toString()
    this.state.province_name = value.Details[this.state.lang].Name
    this.search_reload(window.location.pathname)
  }
  async componentDidMount() {
    const language = localStorage.getItem("I18N_LANGUAGE")
    if (!language) {
      localStorage.setItem("I18N_LANGUAGE", "th")
    }
    // console.log(language)
    await this.get_places()
    this.get_places_count()
    this.lkup_provinces()
    this.lkup_regions()
    if (this.state.days_id) {
      this.setState({
        days_selected: this.state.days[this.state.days_id - 1]["value"]
      }, () => { return })
    }
    if (this.state.province_id) {
      this.setState({
        province_selected: this.state.province_name
      }, () => { return })
    }
  }
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    this.setState({
      isLoading: true,
      lang: nextProps.i18n.language
    }, () => {
      this.get_places()
      this.get_places_count()
      this.lkup_provinces()
      this.lkup_regions()
    })
  }

  render() {
    // let publicUrl = process.env.PUBLIC_URL + '/'
    // let imagealt = 'image'
    if (this.state.isLoading === true || this.state.provinces_loading === true || this.state.regions_loading === true) {
      // console.log(this.state.lang)
      return (
        <div className="tour-list-area pd-top-80 viaje-go-top">
          <div className="container">
            <div className="row" style={{ marginBottom: 20 }}>
              <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                <div className="new-breadcrumb">
                  <BreadcrumbItem><a hrefLang={this.state.lang} href={"/"} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                  <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path} className="normal-text cl-black">{this.state.page_name}</a></BreadcrumbItem>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="row style-breadcrumb">
                  <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                  <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                    <div className="small-text viewpoint-list cl-blue">
                      <i className="fa fa-list-ul viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                      <span data-nosnippet>{this.props.t('list')}</span>
                    </div>
                  </div>
                  <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                    <div className="small-text viewpoint-list">
                      <i className="fa fa-map-o viewpoint-list-size" aria-hidden="true" ></i>
                      <span data-nosnippet>{this.props.t('Maps')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 order-lg-1">
                <div className="sidebar-area sidebar-area-inner-page">
                  <div className="widget tour-list-widget">
                    <div className="single-widget-search-input-title">{this.props.t('Search for Community-Based Tourism Routes')}</div>
                    <div className="single-widget-search-input-title">{this.props.t('Number of days')}</div>
                    <div className="single-widget-search-input">
                      <div className="dropdown">
                        <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.days_selected}</span></p>
                            <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                          </div>
                        </div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                          {this.state.days.map((e, i) => {
                            return (
                              <div key={i} className="dropdown-item" onClick={() => { this.number_of_days_submit(e) }} style={{ maxHeight: '200px', minWidth: '100%' }}><span data-nosnippet>{e.value}</span></div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="single-widget-search-input-title">{this.props.t('Region')}</div>
                    {this.state.lang === 'en' ?
                      <div className="single-widget-search-input-title" style={{ fontSize: 12, color: 'red' }}>{this.state.sentence}</div>
                      :
                      <div className="single-widget-search-input-title" style={{ color: 'red' }}>{this.state.sentence}</div>
                    }
                    <div className="single-widget-search-input">
                      <div className="dropdown">
                        <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity"><span data-nosnippet>ทุกภูมิภาค</span></p>}
                            {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity"><span data-nosnippet>All regions</span></p>}
                            {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity"><span data-nosnippet>所有地区</span></p>}
                            <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                          </div>
                        </div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                          {this.state.regions.map((e, i) => {
                            return (
                              <div key={i} className="dropdown-item" onClick={() => { this.region_selected_submit(e) }} style={{ maxHeight: '200px', minWidth: '100%' }}><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="single-widget-search-input-title">{this.props.t('Province')}</div>
                    <div className="single-widget-search-input">
                      <div className="dropdown" >
                        <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.province_selected}</span></p>
                            <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                          </div>
                        </div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                          {this.state.provinces.map((e, i) => {
                            return (
                              <div key={i} className="dropdown-item" onClick={() => { this.province_selected_submit(e) }} ><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <Link to="#" className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-4 order-lg-12">
                <div style={{ justifyContent: 'center', alignSelf: 'center' }}>
                  <Lottie options={defaultOptions} height={300} width={300} />
                  <p className="normal-text-18 cl-black" style={{ textAlign: 'center' }}>{this.props.t('Loading information from the system')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      let publicUrl = process.env.PUBLIC_URL + '/'
      // console.log(this.state.places_data)
      // console.log(this.state.province_id)
      return (
        <div className="tour-list-area pd-top-80 viaje-go-top">
          <div className="container">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1" id="all-order">
                <div className="row" style={{ marginBottom: 20 }}>
                  <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                    <div className="new-breadcrumb">
                      <BreadcrumbItem><a hrefLang={this.state.lang} href={"/"} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                      <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path} className="normal-text cl-black">{this.state.page_name}</a></BreadcrumbItem>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="row style-breadcrumb">
                      <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                      <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                        <div className="small-text viewpoint-list cl-blue">
                          <i className="fa fa-list-ul viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                          <span data-nosnippet>{this.props.t('list')}</span>
                        </div>
                      </div>
                      <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                        <div className="small-text viewpoint-list">
                          <i className="fa fa-map-o viewpoint-list-size" aria-hidden="true" ></i>
                          <span data-nosnippet>{this.props.t('Maps')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  { /* Search Box*/}
                  <div className="col-xl-3 col-lg-4 order-lg-1">
                    <div className="sidebar-area sidebar-area-inner-page">
                      <div className="widget tour-list-widget">
                        <div className="single-widget-search-input-title">{this.props.t('Search for Community-Based Tourism Routes')}</div>
                        <div className="single-widget-search-input-title">{this.props.t('Number of days')}</div>
                        <div className="single-widget-search-input">
                          <div className="dropdown">
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.days_selected}</span></p>
                                <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                              </div>
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                              {this.state.days.map((e, i) => {
                                return (
                                  <div key={i} className="dropdown-item" onClick={() => { this.number_of_days_submit(e) }} style={{ maxHeight: '200px', minWidth: '100%' }}><span data-nosnippet>{e.value}</span></div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="single-widget-search-input-title">{this.props.t('Region')}</div>
                        <div className="single-widget-search-input">
                          <div className="dropdown">
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p className="cl-gray normal-text line-opacity"><span data-nosnippet>{this.state.region_selected}</span></p>
                                <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                              </div>
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                              {this.state.regions.map((e, i) => {
                                return (
                                  <div key={i} className="dropdown-item" onClick={() => { this.region_selected_submit(e) }} style={{ maxHeight: '200px', minWidth: '100%' }}><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        {this.state.lang === 'en' ?
                          <div className="single-widget-search-input-title" style={{ fontSize: 12, color: 'red' }}>{this.state.sentence}</div>
                          :
                          <div className="single-widget-search-input-title" style={{ color: 'red' }}>{this.state.sentence}</div>
                        }
                        <div className="single-widget-search-input-title">{this.props.t('Province')}</div>
                        <div className="single-widget-search-input">
                          <div className="dropdown" >
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.province_selected}</span></p>
                                <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                              </div>
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                              {this.state.provinces.map((e, i) => {
                                return (
                                  <div key={i} className="dropdown-item" onClick={() => { this.province_selected_submit(e) }} ><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-8 order-lg-12">
                    <div>
                      {this.state.places_data.length > 0 ?
                        <div className="row justify-content-center">
                          {this.state.places_data.map((e, i) => {
                            const Day = this.props.t('day')
                            return (
                              <div key={i} className="col-xl-4 col-lg-6 col-sm-12" style={{ marginBottom: 30 }}>
                                <div className="single-destinations-list style-two animated slideInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
                                  <Link hrefLang={this.state.lang} to={{ pathname: "/" + this.state.lang + "/community_routes_details/" + e.route_id, params: e.route_id }}>
                                    <div className="thumb">
                                      <img style={{ height: 220, width: '100%' }} src={e.image} onError={(event) => event.target.setAttribute("src", "https://thailandtourismdirectory.go.th/assets/upload/icons/no-image.jpg")} alt={e.route_name} />
                                    </div>
                                  </Link>
                                  <div className="details" style={{ padding: 5, borderBottom: '1px solid #F0F0F0' }}>
                                    <p className="location" style={{ color: '#173865' }}><img src={publicUrl + "assets/img/icons/location.png"} alt="" />{e.province}</p>
                                    <p className="normal-text-18 weight-700 cl-black">{e.route_name.length > 40 ? e.route_name.slice(0, 36) + "..." : e.route_name}</p>
                                    <p className="normal-text-16 weight-700 cl-black">{e.number_of_days === null ? <div></div> : e.number_of_days + ` ${Day}`}</p>
                                    <div className="row" style={{ justifyContent: 'space-between', margin: 2 }}>
                                      <div className="content">
                                        <p className="normal-text-14" style={{ color: '#166936' }}><span data-nosnippet>{this.props.t('อพท. | CBT Thailand')}</span></p>
                                      </div>
                                      <div className="content">
                                        <p className="normal-text-14" style={{ color: 'gray' }}>
                                          <Link hrefLang={this.state.lang} to={{ pathname: "/" + this.state.lang + "/community_routes_details/" + e.route_id, params: e.route_id }}>
                                            <span data-nosnippet>{this.props.t('Details')}</span>
                                          </Link>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        : this.state.isLoading === false &&
                        <div>
                          <div className="col-xl-12 col-lg-12 order-lg-12">
                            <div style={{ justifyContent: 'center', alignSelf: 'center', textAlign: 'center' }}>
                              <img className='notFound' src={publicUrl + "assets/img/browser1.svg"} alt="" />
                              <p className="normal-text-18 cl-black" style={{ textAlign: 'center' }}>{this.props.t('This information was not found in the system')}</p>
                            </div>
                          </div>
                        </div>
                      }
                      {this.state.places_data.length > 0 ?
                        <div className="col-lg-12 text-center">
                          <div className="tp-pagination text-center d-inline-block mt-4">
                            <ul>
                              {this.state.page_index === 1 ?
                                null
                                :
                                <li>
                                  <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + (this.state.page_index - 1)
                                    + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                    + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")} className="prev page-numbers" >
                                    <i className="la la-long-arrow-left" /></a>
                                </li>
                              }
                              {this.state.count_places.map((e, i) => {
                                if (i > this.state.pagination_min && i < this.state.pagination_max) {
                                  if (e.onSelected === true) {
                                    return (
                                      <li key={i}>
                                        <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + e.page
                                          + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                          + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")}>
                                          <span className="page-numbers current">{e.page}</span></a>
                                      </li>
                                    )
                                  }
                                  else {
                                    return (
                                      <li key={i}>
                                        <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + e.page
                                          + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                          + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")}>
                                          <span className="page-numbers">{e.page}</span>
                                        </a></li>
                                    )
                                  }
                                }
                                else {
                                  return (
                                    null
                                  )
                                }
                              })}
                              {this.state.count_places.length <= 5 ?
                                null
                                :
                                <li><a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + (this.state.page_index + 1)
                                  + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                  + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")} className="prev page-numbers" >
                                  <i className="la la-long-arrow-right" /></a>
                                </li>
                              }
                            </ul>
                          </div>
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2" id="processing">
                <div className="row" style={{ marginBottom: 20 }}>
                  <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                    <div className="new-breadcrumb">
                      <BreadcrumbItem><a hrefLang={this.state.lang} href={"/"} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                      <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path} className="normal-text cl-black">{this.state.page_name}</a></BreadcrumbItem>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="row style-breadcrumb">
                      <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                      <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                        <div className="small-text viewpoint-list">
                          <i className="fa fa-list-ul viewpoint-list-size " aria-hidden="true" ></i>
                          <span data-nosnippet>{this.props.t('list')}</span>
                        </div>
                      </div>
                      <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                        <div className="small-text viewpoint-list cl-blue">
                          <i className="fa fa-map-o viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                          <span data-nosnippet>{this.props.t('Maps')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  { /* Search Box*/}
                  <div className="col-xl-3 col-lg-4 order-lg-1">
                    <div className="sidebar-area sidebar-area-inner-page">
                      <div className="widget tour-list-widget">
                        <div className="single-widget-search-input-title">{this.props.t('Search for Community-Based Tourism Routes')}</div>
                        <div className="single-widget-search-input-title">{this.props.t('Number of days')}</div>
                        <div className="single-widget-search-input">
                          <div className="dropdown">
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.days_selected}</span></p>
                                <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                              </div>
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                              {this.state.days.map((e, i) => {
                                return (
                                  <div key={i} className="dropdown-item" onClick={() => { this.number_of_days_submit(e) }} style={{ maxHeight: '200px', minWidth: '100%' }}><span data-nosnippet>{e.value}</span></div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="single-widget-search-input-title">{this.props.t('Region')}</div>
                        <div className="single-widget-search-input">
                          <div className="dropdown">
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.region_selected}</span></p>}
                                {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>Please Select Region</span></p>}
                                {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>请选择一个地区</span></p>}
                                <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                              </div>
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                              {this.state.regions.map((e, i) => {
                                return (
                                  <div key={i} className="dropdown-item" onClick={() => { this.region_selected_submit(e) }} style={{ maxHeight: '200px', minWidth: '100%' }}><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="single-widget-search-input-title">{this.props.t('Province')}</div>
                        <div className="single-widget-search-input">
                          <div className="dropdown" >
                            <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.province_selected}</span></p>}
                                {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>Please Select Province</span></p>}
                                {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>请选择一个省</span></p>}
                                <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                              </div>
                            </div>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                              {this.state.provinces.map((e, i) => {
                                return (
                                  <div key={i} className="dropdown-item" onClick={() => { this.province_selected_submit(e) }} ><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <Link to="#" className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent >
          </div>
        </div >
      )
    }

  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
  language: 'th',
})(withTranslation()(TravelRouteCommunity));
