import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
class Counter extends Component {
	constructor(props) {
		super(props)
		this.state = {


		}
	}
	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="counter-area bg-gray">
			<div className="container" style={{ marginTop: 70 }}>
				<ul className="row">
					<li className="col-lg-3 col-sm-6">
						<div className="single-counting text-center">
							<h2><img src={publicUrl + "assets/img/icons/10.png"} style={{ marginTop: -10 }} alt="" /> <span className="count-nums">{this.props.count_attractions}</span></h2>
							<span className="large-text cl-black">{this.props.t('Attraction')}</span>
						</div>
					</li>
					<li className="col-lg-3 col-sm-6">
						<div className="single-counting text-center">
							<h2><img src={publicUrl + "assets/img/icons/10.png"} style={{ marginTop: -10 }} alt="" /> <span className="count-nums">{this.props.count_activities}</span></h2>
							<span className="large-text cl-black">{this.props.t('Thing to do')}</span>
						</div>
					</li>
					<li className="col-lg-3 col-sm-6">
						<div className="single-counting text-center">
							<h2><img src={publicUrl + "assets/img/icons/10.png"} style={{ marginTop: -10 }} alt="" /><span className="count-nums" style={{ marginLeft: 18 }}>{this.props.count_routes}</span></h2>
							<span className="large-text cl-black" >{this.props.t('Tourist Routes')}</span>
						</div>
					</li>
					<li className="col-lg-3 col-sm-6">
						<div className="single-counting text-center">
							<h2><img src={publicUrl + "assets/img/icons/10.png"} style={{ marginTop: -10 }} alt="" /> <span className="count-nums">{this.props.count_touroperators}</span></h2>
							<span className="large-text cl-black">{this.props.t('Tour Operators')}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>


	}
}

export default withTranslation()(Counter);