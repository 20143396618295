import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Community extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}
		// console.log(props)
	}

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		// let imagealt = 'image'

		return <div className="holiday-plan-area tp-holiday-plan-area mg-top-96" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/BG1.jpg)' }}>
			<div className="container">
				<div className="row">
					<div className="col-xl-12 col-lg-12" style={{ marginTop: 50 }}>
						<div className="section-title">
							<h2 className="title wow animated fadeInUp large-text-48 cl-black" data-wow-duration="0.6s" data-wow-delay="0.1s">{this.props.t('Community-Based Tourism Routes')}</h2>
							<div className="row">
								<div className="col-lg-9">
									<p className="wow animated fadeInUp large-text light-gray" data-wow-duration="0.6s" data-wow-delay="0.2s">{this.props.t('Designated Areas for Sustainable Tourism Administration (Public Organization)')}</p>
								</div>
								<div className="col-lg-3" style={{ textAlign: 'end' }}>
									<Link className="btn btn-gray " to={"/" + this.props.lang + "/community_routes"} style={{ fontSize: 24, marginTop: 0, borderRadius: 39 }} target="_blank" rel="noopener noreferrer">{this.props.t('View more')}</Link>
								</div>
							</div>

						</div>
					</div>
				</div>
				{this.props.community.length > 0 ?
					<div className="row">
						{this.props.community.map((e, i) => {
							console.log(e)
							if (i < 4) {
								return (
									<div key={i} className="col-lg-3 col-sm-6">
										<div className="single-destinations-list style-two animated slideInUp border-solid" data-wow-duration="0.4s" data-wow-delay="0.1s">
											<Link to={{ pathname: "/" + this.props.lang + "/community_routes_details/" + e.route_id, params: e.route_id }}>
												<div className="thumb">
													<img style={{ height: 220, width: '100%' }} src={e.image} alt="" />
												</div>
											</Link>
											<div className="details" style={{ padding: 5, borderBottom: '1px solid #F0F0F0' }}>
												<p className="location" style={{ color: '#173865' }}><img src={publicUrl + "assets/img/icons/location.png"} alt="map" />{e.province}</p>
												<p className="normal-text-18 weight-700 cl-black">{e.route_name.length > 40 ? e.route_name.slice(0, 36) + "..." : e.route_name}</p>
												<div className="row" style={{ justifyContent: 'space-between', margin: 2 }}>
													<div className="content">
														<p className="normal-text-14" style={{ color: '#166936' }}><span data-nosnippet>{this.props.t('CBT. | CBT Thailand')}</span></p>
													</div>
													<div className="content">
														<p className="normal-text-14" style={{ color: 'gray' }}>
															<Link to={{ pathname: "/" + this.props.lang + "/community_routes_details/" + e.route_id, params: e.route_id }}>
																<span data-nosnippet>{this.props.t('Details')}</span>
															</Link>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							}
							else {
								return null
							}

						})}
					</div>
					:
					<div>

						ไม่พบข้อมูล
					</div>
				}
			</div>
		</div>
	}
}

export default withTranslation()(Community);