import React, { Component } from 'react';
// import { ProgressBar } from 'react-bootstrap';
import Navbar from './global-components/navbar';
import PageHeaderGuide from './global-components/page-header-guide';
import { withTranslation } from 'react-i18next';
import Footer from './global-components/footer';
import { api_endpoint } from '../global-config'
// import vecterlocation from '../assets/images/icon/location.svg'
// import vecterlove from '../assets/images/icon/love.svg'
// import clock from "../assets/images/icon/bi_clock.svg"
// import check from "../assets/images/icon/check.svg"
// import User from "../assets/images/icon/User.svg"
// import User1 from "../assets/images/icon/User1.png"
// import edit from "../assets/images/icon/edit.svg"
// import feather_edit from "../assets/images/icon/feather_edit.svg"
import '../components/cssnew.css'
import facebook from "../assets/images/icon/facebook1.svg"
import locations from "../assets/images/icon/mark.svg"
import phone from "../assets/images/icon/telephone.svg"
import mail from "../assets/images/icon/email.svg"
import line from "../assets/images/icon/line1.svg"
import Twitter from "../assets/images/icon/twitter.svg"
import YouTube from "../assets/images/icon/youtube.svg"
import ContactName from "../assets/images/icon/name.svg"
import Map from "../assets/images/icon/map.svg"
import website from "../assets/images/icon/website.svg"
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
// import star from "../assets/images/icon/star.svg"
import FadeIn from "react-fade-in";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
// import ReactStars from "react-rating-stars-component";
import './cssnew.css'
import * as loadingData from "../loadingttd.json";
import Lottie from "react-lottie";
// import { Link } from 'react-router-dom'
import { GoogleApiWrapper } from "google-maps-react";
// import Share from "../assets/images/icon/Share.svg"
// import Vectorgologin from "../assets/images/icon/Vectorgologin.svg"
// import dataver from "../assets/images/icon/dataver.svg"
// import dataofof from "../assets/images/icon/dataofof.svg"
// import dataof from "../assets/images/icon/dataof.svg"
// import Parser from 'html-react-parser';
import moment from 'moment';
import Interweave, { Node } from 'interweave';


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


class GuideDetailsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            place_id: props.match.params.id,
            place_details: {},
            isLoading: true,
            lang: this.props.i18n.language,
            original: [],
            scroll: "",
            modal_control: false,
            matches: window.matchMedia("(min-width: 992px)").matches,
            isLogin: localStorage.getItem('user_data'),

        }
        this.login_check = this.login_check.bind(this);
        this.copy = this.copy.bind(this);
    }

    showMap() {
        this.setState({ showLightbox: true })
    }

    copy() {
        this.elRef.select();
        document.execCommand("copy");
    }

    //เรียกข้อมูลสถานที่
    async get_place_detail() {
        const response = await fetch(
            api_endpoint + `/maininfo/readone`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ID: this.state.place_id,
                    // Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        let arr = []
        console.log(_result.result.Images)
        _result.result.Images.map((e, i) => {
            return arr.push({ original: e, thumbnail: e, originalAlt: _result.result.Name[this.state.lang] })
        })
        console.log(arr)
        this.setState({
            place_details: _result.result,
            original: arr,
            isLoading: false
        })

    }

    modal_control() {
        this.setState({
            modal_control: true
        }, () => {
            console.log(this.state.modal_control)
            return this.state.modal_control
        })
    }

    login_check() {
        this.setState({
            isLogin: localStorage.getItem("user_data")
        })
    }

    componentDidMount() {
        if (this.state.place_id === undefined) {
            this.props.history.goBack()
        }
        else {
            const language = localStorage.getItem("I18N_LANGUAGE")
            if (!language) {
                localStorage.setItem("I18N_LANGUAGE", "th")
            }
            console.log(language)
            this.get_place_detail()
            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        }

    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        this.setState({
            isLoading: true,
            lang: nextProps.i18n.language
        }, () => {

            this.get_place_detail()

            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        })


    }
    render() {
        const images = this.state.original;
        console.log(this.state.place_details)
        console.log(this.state.comment_data)
        if (this.state.isLoading === true) {
            return (
                <div style={{ marginTop: 200 }} className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div className="card">
                                <div className="cardbody">
                                    <Lottie options={defaultOptions} height={300} width={300} />
                                    <div>
                                        <h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </FadeIn>
                </div>
            )
        }
        else {
            let publicUrl = process.env.PUBLIC_URL + '/'
            const Moredetails = this.state.place_details.MoreDetail[this.state.lang] === "" ? this.state.place_details.MoreDetail.th : this.state.place_details.MoreDetail[this.state.lang]
            // close js 'types' can only be used in a .ts file - Visual Studio Code using @ts-check
            function transform(node: HTMLElement, children: Node[]): React.ReactNode {
                if (node.tagName.toLowerCase() === 'p') {
                    return <p className="normal-text cl-black" style={{ margin: 0, textAlign: 'justify' }}>{children}</p>;
                }
            }
            return <div>
                <Navbar modal_control={this.state.modal_control} login_check={this.login_check} />
                <PageHeaderGuide headertitle={this.state.place_details.Name[this.state.lang] === "" || this.state.place_details.Name[this.state.lang] === undefined ?
                    this.state.place_details.Name.th : this.state.place_details.Name[this.state.lang]} />
                <div>
                    {/* แถบตอนย่อจอ */}
                    {!this.state.matches && (
                        <div style={{ padding: 20 }}>
                            <div className="row pd-bt-30" style={{ display: 'flex', justifyContent: 'center', marginTop: -90 }}>
                                <div className="box-style-img">
                                    <img className="img-guide" src={this.state.place_details.Images[0] !== undefined ? this.state.place_details.Images[0] : null} alt="list"></img>
                                </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                {this.state.place_details.Name[this.state.lang] === undefined || this.state.place_details.Name[this.state.lang] === "" ?
                                    <div style={{ marginBottom: 15 }}>
                                        <p className="large-text-36 pd-l-10 cl-black dp">{this.state.place_details.Name.th} </p>
                                    </div>
                                    :
                                    <div style={{ marginBottom: 15 }}>
                                        <p className="large-text-36 pd-l-10 cl-black dp">{this.state.place_details.Name[this.state.lang]} </p>
                                    </div>
                                }
                                {this.state.place_details.Detail[this.state.lang] === undefined || this.state.place_details.Detail[this.state.lang] === "" ?
                                    <div style={{ marginBottom: 30 }}>
                                        <p className="large-text cl-black dp"><span>(</span>{this.state.place_details.Detail.th}<span>)</span></p>
                                    </div>
                                    :
                                    <div style={{ marginBottom: 30 }}>
                                        <p className="large-text cl-black dp"><span>(</span>{this.state.place_details.Detail.th}<span>)</span></p>
                                    </div>
                                }
                            </div>
                            <div className="card card-color" style={{ borderRadius: 15 }}>
                                <div className="card-body">
                                    <h6 className="card-subtitle topic-text pd-bt-15" >{this.props.t('Contact us')}</h6>
                                    {this.state.place_details.ContactName[this.state.lang] !== "" ?
                                        <div>
                                            <p className="normal-text pd-bt-15">
                                                <img src={ContactName} style={{ paddingRight: 17, height: 17 }} alt="" />
                                                {/* {this.props.t('Contact Name')} {this.state.place_details.ContactName[this.state.lang]} */}
                                                {this.state.place_details.ContactName[this.state.lang]}
                                                {this.state.place_details.ContactPosition[this.state.lang] !== "" ?
                                                    <span style={{ marginLeft: 5 }}>
                                                        <span>(</span>{this.state.place_details.ContactPosition[this.state.lang]}<span>)</span>
                                                    </span>
                                                    : this.state.place_details.ContactPosition.th !== "" ?
                                                        <span>
                                                            <span>(</span>{this.state.place_details.ContactPosition.th}<span>)</span>
                                                        </span>
                                                        :
                                                        <span></span>
                                                }
                                            </p>
                                        </div>
                                        : this.state.place_details.ContactName.th !== "" ?
                                            <div>
                                                <p className="normal-text pd-bt-15">
                                                    <img src={ContactName} style={{ paddingRight: 17, height: 17 }} alt="" />
                                                    {/* {this.props.t('Contact Name')} {this.state.place_details.ContactName.th} */}
                                                    {this.state.place_details.ContactName.th}
                                                    {this.state.place_details.ContactPosition[this.state.lang] !== "" ?
                                                        <span style={{ marginLeft: 5 }}>
                                                            <span>(</span>{this.state.place_details.ContactPosition[this.state.lang]}<span>)</span>
                                                        </span>
                                                        : this.state.place_details.ContactPosition.th !== "" ?
                                                            <span>
                                                                <span>(</span>{this.state.place_details.ContactPosition.th}<span>)</span>
                                                            </span>
                                                            :
                                                            <span></span>
                                                    }
                                                </p>
                                            </div>
                                            :
                                            <div>

                                            </div>
                                    }
                                    {this.state.place_details.Address[this.state.lang] !== "" ?
                                        <div>
                                            <p className="normal-text pd-bt-15">
                                                <img src={locations} style={{ paddingRight: 17, height: 27 }} alt="" />
                                                {/* {this.props.t('Address')} {this.state.place_details.Address[this.state.lang]} */}
                                                {this.state.place_details.Address[this.state.lang]}
                                            </p>
                                        </div>
                                        : this.state.place_details.Address.th !== "" ?
                                            <div>
                                                <p className="normal-text pd-bt-15">
                                                    <img src={locations} style={{ paddingRight: 17, height: 27 }} alt="" />
                                                    {/* {this.props.t('Address')} {this.state.place_details.Address.th} */}
                                                    {this.state.place_details.Address.th}
                                                </p>
                                            </div>
                                            :
                                            <div>

                                            </div>
                                    }
                                    {this.state.place_details.Mobile.length < 1 ?
                                        <div>

                                        </div>
                                        :
                                        <div>
                                            {/* <p className="normal-text pd-bt-15"><img src={phone} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Phone number')} {this.state.place_details.Mobile}</p> */}
                                            <p className="normal-text pd-bt-15">
                                                <img src={phone} style={{ paddingRight: 18, height: 19 }} alt="" />
                                                {this.state.place_details.Mobile}
                                            </p>
                                        </div>
                                    }
                                    {this.state.place_details.LineID.length < 1 ?
                                        <div>

                                        </div>
                                        :
                                        <div>
                                            {/* <p className="normal-text pd-bt-15"><img src={line} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Line')} {this.state.place_details.LineID}</p> */}
                                            <p className="normal-text pd-bt-15">
                                                <img src={line} style={{ paddingRight: 13, height: 24, marginLeft: -1 }} alt="" />
                                                {this.state.place_details.LineID}
                                            </p>
                                        </div>
                                    }
                                    {this.state.place_details.Email.length < 1 ?
                                        <div>

                                        </div>
                                        :
                                        <div>
                                            {/* <p className="normal-text pd-bt-15"><img src={mail} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Email')} {this.state.place_details.Email}</p> */}
                                            <p className="normal-text pd-bt-15">
                                                <img src={mail} style={{ paddingRight: 15, height: 20 }} alt="" />
                                                {this.state.place_details.Email}
                                            </p>
                                        </div>
                                    }
                                    {this.state.place_details.Website === null || this.state.place_details.Website === "" ?
                                        <div>

                                        </div>
                                        :
                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                            {/* <p className="normal-text"><img src={website} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Website')}</p> */}
                                            <p className="normal-text pd-bt-15">
                                                <img src={website} style={{ paddingRight: 11, height: 24 }} alt="" />
                                            </p>
                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.Website) }}>
                                                <span className="normal-text">{this.props.t('Visit Website')}</span>
                                            </button>
                                        </div>
                                    }
                                    {this.state.place_details.FacebookUrl === null || this.state.place_details.FacebookUrl === "" ?
                                        <div>

                                        </div>
                                        :
                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                            {/* <p className="normal-text"><img src={facebook} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Facebook')}</p> */}
                                            <p className="pd-bt-15">
                                                <img src={facebook} style={{ paddingRight: 11, height: 24 }} alt="" />
                                            </p>
                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.FacebookUrl) }}>
                                                <span className="normal-text">{this.props.t('Facebook Page')}</span>
                                            </button>
                                        </div>
                                    }
                                    {this.state.place_details.Twitter === null || this.state.place_details.Twitter === "" ?
                                        <div>

                                        </div>
                                        :
                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                            {/* <p className="normal-text"><img src={Twitter} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Twitter')}</p> */}
                                            <p className="pd-bt-15">
                                                <img src={Twitter} style={{ paddingRight: 11, height: 24 }} alt="" />
                                            </p>
                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.Twitter) }}>
                                                <span className="normal-text">{this.props.t('Twitter')}</span>
                                            </button>
                                        </div>
                                    }
                                    {this.state.place_details.VdoYoutubeUrl === null || this.state.place_details.VdoYoutubeUrl === "" ?
                                        <div
                                        ></div>
                                        :
                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                            {/* <p className="normal-text"><img src={YouTube} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Video Introduction')}</p> */}
                                            <p className="pd-bt-15">
                                                <img src={YouTube} style={{ paddingRight: 7, marginLeft: -1, height: 30 }} alt="" />
                                            </p>
                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.VdoYoutubeUrl) }}>
                                                <span className="normal-text">{this.props.t('Video Introduction')}</span>
                                            </button>
                                        </div>
                                    }
                                    {this.state.place_details.MapImage === null || this.state.place_details.MapImage === "" ?
                                        <div>

                                        </div>
                                        :
                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                            {/* <p className="normal-text"><img src={Map} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Map Image')}</p> */}
                                            <p className="pd-bt-15">
                                                <img src={Map} style={{ paddingRight: 12, paddingLeft: 5, height: 24 }} alt="" />
                                            </p>
                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { this.showMap() }} >
                                                <span className="normal-text">{this.props.t('Click to view image')}</span>
                                            </button>
                                            {this.state.showLightbox ?
                                                <Lightbox image={this.state.place_details.MapImage} title={this.state.place_details.Name[this.state.lang]} onClose={() => this.setState({ showLightbox: false })} /> : null}
                                        </div>
                                    }
                                    <hr className=""></hr>
                                    <div style={{ marginTop: 20 }}>
                                        <h6 className="card-subtitle topic-text pd-bt-15">{this.props.t('Map')}</h6>
                                        <div className="map-style">
                                            <img className="map" src={publicUrl + "assets/img/map2.png"} alt=""
                                                onClick={() => { window.open("https://maps.google.com/?q=" + this.state.place_details.Latitude + "," + this.state.place_details.Longitude) }}>
                                            </img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ margin: "20px 0px 0px 10px" }}>
                                <h5 className="topic-text ">{this.props.t('ข้อมูลการขึ้นทะเบียนและข้อมูลใบอนุญาต')}</h5>
                            </div>
                            <hr></hr>
                            {this.state.place_details.MoreDetail[this.state.lang] !== "" ?
                                <div>
                                    <h5 className="topic-text pd-bt-15">{this.props.t('More information')}</h5>
                                    <Interweave content={Moredetails} transform={transform} />
                                    {/* <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.MoreDetail[this.state.lang]}</p> */}
                                    <div><hr></hr></div>
                                </div>
                                : this.state.place_details.MoreDetail.th !== "" ?
                                    <div>
                                        <h5 className="topic-text pd-bt-15">{this.props.t('More information')}</h5>
                                        <Interweave content={Moredetails} transform={transform} />
                                        {/* <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.MoreDetail.th}</p> */}
                                        <div><hr></hr></div>
                                    </div>
                                    :
                                    <div>

                                    </div>
                            }
                            {this.state.place_details.Guide.Info.GuideRegistrationType[this.state.lang] === undefined || this.state.place_details.Guide.Info.GuideRegistrationType[this.state.lang] === null ?
                                <div className="row" style={{ display: 'grid', margin: "20px 0px 0px 10px" }}>
                                    <p className="normal-text-18-bold cl-black dp">{this.props.t('ประเภทการขึ้นทะเบียน')}</p>
                                    <p className="normal-text-18-detail cl-black dp">{this.state.place_details.Guide.Info.GuideRegistrationType.th} </p>
                                </div>
                                :
                                <div className="row" style={{ display: 'grid', margin: "20px 0px 0px 10px" }}>
                                    <p className="normal-text-18-bold cl-black dp">{this.props.t('ประเภทการขึ้นทะเบียน')}</p>
                                    <p className="normal-text-18-detail cl-black dp">{this.state.place_details.Guide.Info.GuideRegistrationType[this.state.lang]} </p>
                                </div>}

                            {this.state.place_details.Guide.Info.LicenseNo === undefined || this.state.place_details.Guide.Info.LicenseNo === null || this.state.place_details.Guide.Info.LicenseNo === "" ?
                                <div className="row" style={{ display: 'grid', margin: "20px 0px 0px 10px" }}>
                                    <p className="normal-text-18-bold cl-black dp">{this.props.t('เลขที่ใบอนุญาต')}</p>
                                    <p className="normal-text-18-detail cl-black dp">-</p>
                                </div>
                                :
                                <div className="row" style={{ display: 'grid', margin: "20px 0px 0px 10px" }}>
                                    <p className="normal-text-18-bold cl-black dp">{this.props.t('เลขที่ใบอนุญาต')}</p>
                                    <p className="normal-text-18-detail cl-black dp">{this.state.place_details.Guide.Info.LicenseNo}</p>
                                </div>
                            }
                            <div className="row" style={{ margin: "20px 0px 30px -5px" }}>
                                {this.state.place_details.Guide.Info.LicenseDate === undefined || this.state.place_details.Guide.Info.LicenseDate === null ?
                                    <div className="col-md-6" style={{ display: 'grid' }}>
                                        <p className="normal-text-18-bold cl-black dp">{this.props.t('วันที่อนุุญาต')}</p>
                                        <p className="normal-text-18-detail cl-black dp">-</p>
                                    </div>
                                    :
                                    <div className="col-md-6" style={{ display: 'grid' }}>
                                        <p className="normal-text-18-bold cl-black dp">{this.props.t('วันที่อนุุญาต')}</p>
                                        <p className="normal-text-18-detail cl-black dp">{moment(this.state.place_details.Guide.Info.LicenseExpireDate).add(543, 'y').format("DD/MM/YYYY")}</p>
                                    </div>
                                }
                                {this.state.place_details.Guide.Info.LicenseExpireDate === undefined || this.state.place_details.Guide.Info.LicenseExpireDate === null ?
                                    <div className="col-md-6" style={{ display: 'grid' }}>
                                        <p className="normal-text-18-bold cl-black dp">{this.props.t('วันที่หมดอายุ')}</p>
                                        <p className="normal-text-18-detail cl-black dp">-</p>
                                    </div>
                                    :
                                    <div className="col-md-6" style={{ display: 'grid' }}>
                                        <p className="normal-text-18-bold cl-black dp">{this.props.t('วันที่หมดอายุ')}</p>
                                        <p className="normal-text-18-detail cl-black dp">{moment(this.state.place_details.Guide.Info.LicenseDate).add(543, 'y').format("DD/MM/YYYY")}</p>
                                    </div>
                                }
                            </div>
                            <hr></hr>
                            <div className="row" style={{ margin: "20px 0px 30px 10px" }}>
                                <h5 className="topic-text ">{this.props.t('รูปภาพแกลอรี่')}</h5>
                            </div>
                            <div className="row justify-content-center" style={{ margin: 10 }}>
                                <ImageGallery items={images}
                                    showThumbnails={false}
                                    infinite={true}
                                    showIndex={true}
                                />
                            </div >
                            <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: 12 }}>
                                <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                <div className="border-yellow"></div>
                            </div>
                            <div className="style-img-website">
                                <img className="img-website" src={publicUrl + "assets/img/bg/ttd.png"} alt="img"></img>
                                <div className="row row-flex">
                                    <img className="img-logo" src={publicUrl + "assets/img/logo_ttd.png"} alt="img"></img>
                                    <div className="" style={{ display: 'block', marginLeft: '0.5rem' }}>
                                        <div className="large-text cl-white">กระทรวงการท่องเที่ยวและกีฬา</div>
                                        <div className="normal-text-18 cl-white">MINISTRY OF TOURISM & SPORT</div>
                                    </div>
                                </div>
                                <div className="row" style={{ justifyContent: 'center' }}>
                                    <a className="btn btn-website-blue" href="https://www.mots.go.th/" target="_blank" rel="noopener noreferrer"><span className="normal-text-18">{this.props.t('Visit Website')}</span></a>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="tour-details-area mg-top--70s">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 col-12">
                                    {/* ปกติ */}
                                    {this.state.matches && (
                                        <div>
                                            <div className="card card-color" style={{ borderRadius: 15 }}>
                                                <div className="card-body">
                                                    <div className="row pd-bt-30" style={{ display: 'flex', justifyContent: 'center', marginTop: -135 }}>
                                                        <div className="box-style-img">
                                                            <img className="img-guide" src={this.state.place_details.Images[0] !== undefined ? this.state.place_details.Images[0] : null} alt="list"></img>
                                                        </div>
                                                    </div>
                                                    <h6 className="card-subtitle topic-text pd-bt-15" >{this.props.t('Contact us')}</h6>
                                                    {this.state.place_details.ContactName[this.state.lang] !== "" ?
                                                        <div>
                                                            <p className="normal-text pd-bt-15">
                                                                <img src={ContactName} style={{ paddingRight: 13, height: 17 }} alt="" />
                                                                {/* {this.props.t('Contact Name')} {this.state.place_details.ContactName[this.state.lang]} */}
                                                                {this.state.place_details.ContactName[this.state.lang]}
                                                                {this.state.place_details.ContactPosition[this.state.lang] !== "" ?
                                                                    <span style={{ marginLeft: 5 }}>
                                                                        <span>(</span>{this.state.place_details.ContactPosition[this.state.lang]}<span>)</span>
                                                                    </span>
                                                                    : this.state.place_details.ContactPosition.th !== "" ?
                                                                        <span>
                                                                            <span>(</span>{this.state.place_details.ContactPosition.th}<span>)</span>
                                                                        </span>
                                                                        :
                                                                        <span></span>
                                                                }
                                                            </p>
                                                        </div>
                                                        : this.state.place_details.ContactName.th !== "" ?
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={ContactName} style={{ paddingRight: 13, height: 17 }} alt="" />
                                                                    {/* {this.props.t('Contact Name')} {this.state.place_details.ContactName.th} */}
                                                                    {this.state.place_details.ContactName.th}
                                                                    {this.state.place_details.ContactPosition[this.state.lang] !== "" ?
                                                                        <span style={{ marginLeft: 5 }}>
                                                                            <span>(</span>{this.state.place_details.ContactPosition[this.state.lang]}<span>)</span>
                                                                        </span>
                                                                        : this.state.place_details.ContactPosition.th !== "" ?
                                                                            <span>
                                                                                <span>(</span>{this.state.place_details.ContactPosition.th}<span>)</span>
                                                                            </span>
                                                                            :
                                                                            <span></span>
                                                                    }
                                                                </p>
                                                            </div>
                                                            :
                                                            <div>

                                                            </div>
                                                    }
                                                    {this.state.place_details.Address[this.state.lang] !== "" ?
                                                        <div>
                                                            <p className="normal-text pd-bt-15">
                                                                <img src={locations} style={{ paddingRight: 17, height: 27 }} alt="" />
                                                                {/* {this.props.t('Address')} {this.state.place_details.Address[this.state.lang]} */}
                                                                {this.state.place_details.Address[this.state.lang]}
                                                            </p>
                                                        </div>
                                                        : this.state.place_details.Address.th !== "" ?
                                                            <div>
                                                                <p className="normal-text pd-bt-15">
                                                                    <img src={locations} style={{ paddingRight: 17, height: 27 }} alt="" />
                                                                    {/* {this.props.t('Address')} {this.state.place_details.Address.th} */}
                                                                    {this.state.place_details.Address.th}
                                                                </p>
                                                            </div>
                                                            :
                                                            <div>

                                                            </div>
                                                    }
                                                    {this.state.place_details.Mobile.length < 1 ?
                                                        <div>

                                                        </div>
                                                        :
                                                        <div>
                                                            {/* <p className="normal-text pd-bt-15"><img src={phone} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Phone number')} {this.state.place_details.Mobile}</p> */}
                                                            <p className="normal-text pd-bt-15">
                                                                <img src={phone} style={{ paddingRight: 18, height: 19 }} alt="" />
                                                                {this.state.place_details.Mobile}
                                                            </p>
                                                        </div>
                                                    }
                                                    {this.state.place_details.LineID.length < 1 ?
                                                        <div>

                                                        </div>
                                                        :
                                                        <div>
                                                            {/* <p className="normal-text pd-bt-15"><img src={line} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Line')} {this.state.place_details.LineID}</p> */}
                                                            <p className="normal-text pd-bt-15">
                                                                <img src={line} style={{ paddingRight: 13, height: 24, marginLeft: -1 }} alt="" />
                                                                {this.state.place_details.LineID}
                                                            </p>
                                                        </div>
                                                    }
                                                    {this.state.place_details.Email.length < 1 ?
                                                        <div>

                                                        </div>
                                                        :
                                                        <div>
                                                            {/* <p className="normal-text pd-bt-15"><img src={mail} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Email')} {this.state.place_details.Email}</p> */}
                                                            <p className="normal-text pd-bt-15">
                                                                <img src={mail} style={{ paddingRight: 15, height: 20 }} alt="" />
                                                                {this.state.place_details.Email}
                                                            </p>
                                                        </div>
                                                    }
                                                    {this.state.place_details.Website === null || this.state.place_details.Website === "" ?
                                                        <div>

                                                        </div>
                                                        :
                                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                            {/* <p className="normal-text"><img src={website} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Website')}</p> */}
                                                            <p className="normal-text pd-bt-15">
                                                                <img src={website} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                            </p>
                                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.Website) }}>
                                                                <span className="normal-text">{this.props.t('Visit Website')}</span>
                                                            </button>
                                                        </div>
                                                    }
                                                    {this.state.place_details.FacebookUrl === null || this.state.place_details.FacebookUrl === "" ?
                                                        <div>

                                                        </div>
                                                        :
                                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                            {/* <p className="normal-text"><img src={facebook} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Facebook')}</p> */}
                                                            <p className="pd-bt-15">
                                                                <img src={facebook} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                            </p>
                                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.FacebookUrl) }}>
                                                                <span className="normal-text">{this.props.t('Facebook Page')}</span>
                                                            </button>
                                                        </div>
                                                    }
                                                    {this.state.place_details.Twitter === null || this.state.place_details.Twitter === "" ?
                                                        <div>

                                                        </div>
                                                        :
                                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                            {/* <p className="normal-text"><img src={Twitter} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Twitter')}</p> */}
                                                            <p className="pd-bt-15">
                                                                <img src={Twitter} style={{ paddingRight: 11, height: 24 }} alt="" />
                                                            </p>
                                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.Twitter) }}>
                                                                <span className="normal-text">{this.props.t('Twitter')}</span>
                                                            </button>
                                                        </div>
                                                    }
                                                    {this.state.place_details.VdoYoutubeUrl === null || this.state.place_details.VdoYoutubeUrl === "" ?
                                                        <div
                                                        ></div>
                                                        :
                                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                            {/* <p className="normal-text"><img src={YouTube} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Video Introduction')}</p> */}
                                                            <p className="pd-bt-15">
                                                                <img src={YouTube} style={{ paddingRight: 7, marginLeft: -1, height: 30 }} alt="" />
                                                            </p>
                                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { window.open(this.state.place_details.VdoYoutubeUrl) }}>
                                                                <span className="normal-text">{this.props.t('Video Introduction')}</span>
                                                            </button>
                                                        </div>
                                                    }
                                                    {this.state.place_details.MapImage === null || this.state.place_details.MapImage === "" ?
                                                        <div>

                                                        </div>
                                                        :
                                                        <div className="row" style={{ display: 'flex', margin: 0 }}>
                                                            {/* <p className="normal-text"><img src={Map} style={{ paddingRight: 10, height: '15px' }} alt="" />{this.props.t('Map Image')}</p> */}
                                                            <p className="pd-bt-15">
                                                                <img src={Map} style={{ paddingRight: 12, paddingLeft: 5, height: 24 }} alt="" />
                                                            </p>
                                                            <button className="btn-style" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => { this.showMap() }} >
                                                                <span className="normal-text">{this.props.t('Click to view image')}</span>
                                                            </button>
                                                            {this.state.showLightbox ?
                                                                <Lightbox image={this.state.place_details.MapImage} title={this.state.place_details.Name[this.state.lang]} onClose={() => this.setState({ showLightbox: false })} /> : null}
                                                        </div>
                                                    }
                                                    <hr className="pd-bt-15"></hr>
                                                    <div style={{ marginTop: 20 }}>
                                                        <h6 className="card-subtitle topic-text pd-bt-15">{this.props.t('Map')}</h6>
                                                        <div className="map-style">
                                                            <img className="map" src={publicUrl + "assets/img/map2.png"} alt=""
                                                                onClick={() => { window.open("https://maps.google.com/?q=" + this.state.place_details.Latitude + "," + this.state.place_details.Longitude) }}>
                                                            </img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.lang === 'zh' ?
                                                <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: 12 }}>
                                                    <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                                    <div className="border-yellow zh"></div>
                                                </div>
                                                :
                                                <div className="row hidden-row" style={{ display: 'flex', justifyContent: 'space-around', marginTop: 12 }}>
                                                    <div className="large-text cl-black">{this.props.t('Related Agencies')}</div>
                                                    <div className="border-yellow"></div>
                                                </div>
                                            }
                                            <div className="style-img-website">
                                                <img className="img-website" src={publicUrl + "assets/img/bg/ttd.png"} alt="img"></img>
                                                <div className="row row-flex">
                                                    <img className="img-logo" src={publicUrl + "assets/img/logo_ttd.png"} alt="img"></img>
                                                    <div className="" style={{ display: 'block', marginLeft: '0.5rem' }}>
                                                        <div className="large-text cl-white">กระทรวงการท่องเที่ยวและกีฬา</div>
                                                        <div className="normal-text-18 cl-white">MINISTRY OF TOURISM & SPORT</div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: 'center' }}>
                                                    <a className="btn btn-website-blue" href="https://www.mots.go.th/" target="_blank" style={{ width: '40%' }} rel="noopener noreferrer"><span className="normal-text-18">{this.props.t('Visit Website')}</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                                {this.state.matches && (
                                    <div className="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-12 mb-12" >
                                        {this.state.place_details.Name[this.state.lang] === undefined || this.state.place_details.Name[this.state.lang] === "" ?
                                            <div className="row" style={{ margin: "80px 0px 0px 10px" }}>
                                                <p className="large-text-36 cl-black dp">{this.state.place_details.Name.th} </p>
                                            </div>
                                            :
                                            <div className="row" style={{ margin: "80px 0px 0px 10px" }}>
                                                <p className="large-text-36 cl-black dp">{this.state.place_details.Name[this.state.lang]} </p>
                                            </div>
                                        }
                                        {this.state.place_details.Detail[this.state.lang] === undefined || this.state.place_details.Detail[this.state.lang] === "" ?
                                            <div className="row" style={{ margin: "15px 0px 0px 10px" }}>
                                                <p className="large-text cl-black dp"><span>(</span>{this.state.place_details.Detail.th}<span>)</span></p>
                                            </div>
                                            :
                                            <div className="row" style={{ margin: "15px 0px 0px 10px" }}>
                                                <p className="large-text cl-black dp"><span>(</span>{this.state.place_details.Detail.th}<span>)</span></p>
                                            </div>
                                        }
                                        <div className="row" style={{ margin: "20px 0px 0px 10px" }}>
                                            <h5 className="topic-text ">{this.props.t('ข้อมูลการขึ้นทะเบียนและข้อมูลใบอนุญาต')}</h5>
                                        </div>
                                        <hr></hr>
                                        {this.state.place_details.Guide.Info.GuideRegistrationType[this.state.lang] === undefined || this.state.place_details.Guide.Info.GuideRegistrationType[this.state.lang] === null ?
                                            <div className="row" style={{ display: 'grid', margin: "20px 0px 0px 10px" }}>
                                                <p className="normal-text-18-bold cl-black dp">{this.props.t('ประเภทการขึ้นทะเบียน')}</p>
                                                <p className="normal-text-18-detail cl-black dp">{this.state.place_details.Guide.Info.GuideRegistrationType.th} </p>
                                            </div>
                                            :
                                            <div className="row" style={{ display: 'grid', margin: "20px 0px 0px 10px" }}>
                                                <p className="normal-text-18-bold cl-black dp">{this.props.t('ประเภทการขึ้นทะเบียน')}</p>
                                                <p className="normal-text-18-detail cl-black dp">{this.state.place_details.Guide.Info.GuideRegistrationType[this.state.lang]} </p>
                                            </div>
                                        }
                                        {this.state.place_details.Guide.Info.LicenseNo === undefined || this.state.place_details.Guide.Info.LicenseNo === null || this.state.place_details.Guide.Info.LicenseNo === "" ?
                                            <div className="row" style={{ display: 'grid', margin: "20px 0px 0px 10px" }}>
                                                <p className="normal-text-18-bold cl-black dp">{this.props.t('เลขที่ใบอนุญาต')}</p>
                                                <p className="normal-text-18-detail cl-black dp">-</p>
                                            </div>
                                            :
                                            <div className="row" style={{ display: 'grid', margin: "20px 0px 0px 10px" }}>
                                                <p className="normal-text-18-bold cl-black dp">{this.props.t('เลขที่ใบอนุญาต')}</p>
                                                <p className="normal-text-18-detail cl-black dp">{this.state.place_details.Guide.Info.LicenseNo}</p>
                                            </div>
                                        }
                                        <div className="row" style={{ margin: "20px 0px 30px -5px" }}>
                                            {this.state.place_details.Guide.Info.LicenseDate === undefined || this.state.place_details.Guide.Info.LicenseDate === null ?
                                                <div className="col-lg-6" style={{ display: 'grid' }}>
                                                    <p className="normal-text-18-bold cl-black dp">{this.props.t('วันที่อนุุญาต')}</p>
                                                    <p className="normal-text-18-detail cl-black dp">-</p>
                                                </div>
                                                :
                                                <div className="col-lg-6" style={{ display: 'grid' }}>
                                                    <p className="normal-text-18-bold cl-black dp">{this.props.t('วันที่อนุุญาต')}</p>
                                                    <p className="normal-text-18-detail cl-black dp">{moment(this.state.place_details.Guide.Info.LicenseExpireDate).add(543, 'y').format("DD/MM/YYYY")}</p>
                                                </div>
                                            }
                                            {this.state.place_details.Guide.Info.LicenseExpireDate === undefined || this.state.place_details.Guide.Info.LicenseExpireDate === null ?
                                                <div className="col-lg-6" style={{ display: 'grid' }}>
                                                    <p className="normal-text-18-bold cl-black dp">{this.props.t('วันที่หมดอายุ')}</p>
                                                    <p className="normal-text-18-detail cl-black dp">-</p>
                                                </div>
                                                :
                                                <div className="col-lg-6" style={{ display: 'grid' }}>
                                                    <p className="normal-text-18-bold cl-black dp">{this.props.t('วันที่หมดอายุ')}</p>
                                                    <p className="normal-text-18-detail cl-black dp">{moment(this.state.place_details.Guide.Info.LicenseDate).add(543, 'y').format("DD/MM/YYYY")}</p>
                                                </div>
                                            }
                                        </div>
                                        <hr></hr>
                                        {this.state.place_details.MoreDetail[this.state.lang] !== "" ?
                                            <div>
                                                <h5 className="topic-text pd-bt-15">{this.props.t('More information')}</h5>
                                                <Interweave content={Moredetails} transform={transform} />
                                                {/* <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.MoreDetail[this.state.lang]}</p> */}
                                                <div><hr></hr></div>
                                            </div>
                                            : this.state.place_details.MoreDetail.th !== "" ?
                                                <div>
                                                    <h5 className="topic-text pd-bt-15">{this.props.t('More information')}</h5>
                                                    <Interweave content={Moredetails} transform={transform} />
                                                    {/* <p className="normal-text pd-bt-15" style={{ textAlign: 'justify', paddingBottom: 15 }}>{this.state.place_details.MoreDetail.th}</p> */}
                                                    <div><hr></hr></div>
                                                </div>
                                                :
                                                <div>

                                                </div>
                                        }
                                        <div className="row" style={{ margin: "20px 0px 30px 10px" }}>
                                            <h5 className="topic-text ">{this.props.t('รูปภาพแกลอรี่')}</h5>
                                        </div>
                                        <div className="mg-left-20" >
                                            <ImageGallery items={images}
                                                showThumbnails={false}
                                                infinite={true}
                                                showIndex={true}
                                            />
                                        </div >
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-130 ">
                    <Footer />
                </div>
            </div >
        }
    }
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
    language: 'en',
})(withTranslation()(GuideDetailsPage));


