import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import PageHeaderCampaign from './global-components/page-header-campaign';
import Footer from './global-components/footer';
import { api_endpoint } from '../global-config'
import FadeIn from "react-fade-in";
import * as loadingData from "../loadingttd.json";
import Lottie from "react-lottie";
import { BreadcrumbItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { GoogleApiWrapper } from "google-maps-react";
import { Link } from 'react-router-dom';
// import Interweave, { Node } from 'interweave';
// import ModalComment from "./section-components/modal-comment"
// import Parser from 'html-react-parser';
// const containerStyle = {
//     position: 'relative',
//     width: '100%',
//     height: '100%',
//     borderRadius: '10px'
// }

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
class CampaignPageDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            campaign_id: props.match.params.id,
            campaign_details: {},
            isLoading: true,
            lang: this.props.i18n.language,
            original: [],
            visible: 4,
            visible_Mobile: 2,
        }
        console.log(this.props)
        this.loadMore = this.loadMore.bind(this);
        this.loadMore_Mobile = this.loadMore_Mobile.bind(this);
    }
    loadMore() {
        this.setState((prev) => {
            return { visible: prev.visible + 3 };
        }, () => {
            this.get_campaign_detail()
        });
    }
    loadMore_Mobile() {
        this.setState((prev) => {
            return { visible_Mobile: prev.visible_Mobile + 2 };
        });
    }

    //เรียกข้อมูลสถานที่
    async get_campaign_detail() {
        const response = await fetch(
            api_endpoint + `/contentinfo/readone`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ID: this.state.campaign_id,
                    Lang: this.state.lang,
                }),
            }
        );
        var _result = await response.json();
        let arr = []
        console.log(_result.result.Images)
        _result.result.Images.map((e, i) => {
            return arr.push({ original: e, thumbnail: e })
        })
        this.setState({
            campaign_details: _result.result,
            original: arr,
            isLoading: false
        })
    }

    getMainTypeID(mainTypeID) {
        if (mainTypeID === 1) {
            return '/attraction/'
        }
        else if (mainTypeID === 2) {
            return '/activity/'
        }
        else if (mainTypeID === 3) {
            return '/touroperator/'
        }
        else if (mainTypeID === 4) {
            return '/accommodation/'
        }
        else if (mainTypeID === 5) {
            return '/spa/'
        }
        else if (mainTypeID === 6) {
            return '/restaurant/'
        }
        else if (mainTypeID === 7) {
            return '/store/'
        }
        // else if (mainTypeID === 8) {
        // 	return '/guide-details/'
        // }
        // else if (mainTypeID === 9) {
        // 	return '/route/'
        // }
    }

    componentDidMount() {
        if (this.state.campaign_id === undefined) {
            this.props.history.goBack()
        }
        else {
            const language = localStorage.getItem("I18N_LANGUAGE")
            if (!language) {
                localStorage.setItem("I18N_LANGUAGE", "th")
            }
            this.get_campaign_detail()
        }
    }
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        this.setState({
            isLoading: true,
            lang: nextProps.i18n.language
        }, () => {
            this.get_campaign_detail()
        })
    }

    render() {
        // console.log(Object(this.state.original))
        console.log(this.state.campaign_details)
        if (this.state.isLoading === true) {
            return (
                <div style={{ marginTop: 200 }} className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div className="card">
                                <div className="cardbody">
                                    <Lottie options={defaultOptions} height={300} width={300} />
                                    <div>
                                        <h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            )
        }
        else {
            let publicUrl = process.env.PUBLIC_URL + '/'
            return (
                <div>
                    <Navbar />
                    <PageHeaderCampaign headertitle={this.state.campaign_details.Title[this.state.lang]} />
                    <div className="blog-details-area pd-top-80 viaje-go-top">
                        <div className="container">
                            <div style={{ marginBottom: 20 }}>
                                <div className="new-breadcrumb">
                                    <BreadcrumbItem><a hrefLang={this.state.lang} href="/" className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                                    <BreadcrumbItem ><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/campaigns"} className="normal-text cl-gray">{this.props.t('Campaign')}</a></BreadcrumbItem>
                                    <BreadcrumbItem active><span className="normal-text cl-black">{this.state.campaign_details.Title[this.state.lang]}</span></BreadcrumbItem>
                                </div>
                            </div>

                            <p className="large-text cl-black">{this.state.campaign_details.Title[this.state.lang]}</p>

                            <div style={{ border: '2px solid #173865', marginBottom: 30 }}></div>
                            <div style={{ textAlign: 'justify' }}>
                                <p className="normal-text">{this.state.campaign_details.IntroText[this.state.lang]}</p>
                            </div>
                            {/* <hr></hr> */}

                            {/* <h5 className="topic-text pd-bt-15">{this.props.t('Map')}</h5>
                            <div id="gmaps-markers" className="gmaps" style={{ height: 430, width: '100%' }} >
                                <Map
                                    containerStyle={containerStyle}
                                    google={window.google}
                                    zoom={5.5}
                                    initialCenter={{
                                        lat: 13.75,
                                        lng: 100.517
                                    }}                               
                                >
                                    {this.state.campaign_details.MainInfo.slice(0, this.state.visible).map((e, i) => {
                                        return (
                                            <Marker key={i}
                                                name={'Your position'}
                                                position={{ lat: e.Latitude, lng: e.Longitude }}

                                            />
                                        )
                                    })}
                                </Map>
                            </div> */}
                            <div className="campaign">
                                <span className="double large-text cl-black">Campaign รายการท่องเที่ยวแนะนำ</span>
                            </div>
                            <div className="row justify-content-center">
                                {this.state.campaign_details.MainInfo.slice(0, this.state.visible).map((e, i) => {
                                    let place_name = ""
                                    if (e.Name[this.props.i18n.language] !== undefined && e.Name[this.props.i18n.language] !== "" && e.Name[this.props.i18n.language] !== null) {
                                        place_name = e.Name[this.props.i18n.language]
                                    }
                                    else {
                                        place_name = e.Name.th === undefined ? e.Name : e.Name.th
                                    }
                                    return (
                                        <div key={i} className="col-lg-3 col-sm-6">
                                            <div className="single-destinations-list style-two animated slideInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
                                                <Link to={{ pathname: "/" + this.state.lang + this.getMainTypeID(e.MainTypeID) + e.ID, params: e.ID }}>
                                                    <div className="thumb">
                                                        <img style={{ height: 220, width: '100%' }} src={e.IntroImage} onError={(event) => event.target.setAttribute("src", "https://thailandtourismdirectory.go.th/assets/upload/icons/no-image.jpg")} alt={place_name} />
                                                    </div>
                                                </Link>
                                                <div className="details" style={{ padding: 10 }}>
                                                    <p className="location"><img src={publicUrl + "assets/img/icons/location.png"} alt="map" /><span className="cl-blue">{e.Province.th === undefined ? e.Province : e.Province.th}</span></p>
                                                    <h4 className="normal-text-18 weight-700 cl-black">{place_name.length > 25 ? place_name.slice(0, 25) + "..." : place_name}</h4>
                                                    <div className="row" style={{ justifyContent: 'space-between', margin: 2 }}>
                                                        <div className="content">
                                                            <p className="normal-text-14" style={{ color: 'gold' }}><span data-nosnippet>{this.props.t('Official Verify')}</span></p>
                                                        </div>
                                                        <div className="content">
                                                            <p className="normal-text-14" style={{ color: 'gray' }}>
                                                                <Link to={{ pathname: "/" + this.state.lang + this.getMainTypeID(e.MainTypeID) + e.ID, params: e.ID }}>
                                                                    <span data-nosnippet>{this.props.t('Details')}</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                                    {this.state.visible < this.state.campaign_details.MainInfo.length &&
                                        <div className="btn btn-transparent cl-black" style={{ cursor: 'pointer' }} onClick={this.loadMore}>{this.props.t('โหลดรายการท่องเที่ยวเพิ่มเติม')}</div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div >
            )
        }
    }

}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
    language: 'th',
})(withTranslation()(CampaignPageDetails));


