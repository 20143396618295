import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { api_endpoint } from '../../global-config'
import { withTranslation } from 'react-i18next';
class Recommend extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}

	}

	render() {
		// let publicUrl = process.env.PUBLIC_URL + '/'
		// let imagealt = 'image'

		return <div className="blog-area pd-top-120 viaje-go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="row">
							<div className="box-item col-lg-6 col-sm-12">
								<div className="box-title">
									<div className="box-title-inner">
										<div className="title">{this.props.t('What to Know About Thailand')}</div>
										<div className="subtitle">{this.props.t('Travel Tips in Thailand')}</div>
									</div>
								</div>
							</div>
							{this.props.content_page.length > 0 ?
								<div className="col-lg-3">
									<div className="single-blog style-three">
										<div className="thumb recommended">
											<img src={this.props.content_page[9].IntroImage} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<h4 className="title">{this.props.t('Religion')}</h4>
												<Link hrefLang={this.props.i18n.language} className="btn btn-yellow" to={{ pathname: "/" + this.props.i18n.language + "/contentpage/" + this.props.content_page[9].ID, params: this.props.content_page[9] !== undefined ? this.props.content_page[9].ID : null }}>
													<span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								:
								<div>
									ไม่พบข้อมูล
								</div>
							}
							{this.props.content_page.length > 0 ?
								<div className="col-lg-3">
									<div className="single-blog style-three">
										<div className="thumb recommended">
											<img src={this.props.content_page[6].IntroImage} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<h4 className="title">{this.props.t('Foods')}</h4>
												<Link hrefLang={this.props.i18n.language} className="btn btn-yellow" to={{ pathname: "/" + this.props.i18n.language + "/contentpage/" + this.props.content_page[6].ID, params: this.props.content_page[6] !== undefined ? this.props.content_page[6].ID : null }}>
													<span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								:
								<div>
									ไม่พบข้อมูล
								</div>
							}
							{this.props.content_page.length > 0 ?
								<div className="col-lg-3">
									<div className="single-blog style-three">
										<div className="thumb recommended">
											<img src={this.props.content_page[8].IntroImage} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<h4 className="title">{this.props.t('Travel to Thailand')}</h4>
												<Link hrefLang={this.props.i18n.language} className="btn btn-yellow" to={{ pathname: "/" + this.props.i18n.language + "/contentpage/" + this.props.content_page[8].ID, params: this.props.content_page[8] !== undefined ? this.props.content_page[8].ID : null }}>
													<span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								:
								<div>
									ไม่พบข้อมูล
								</div>
							}
							{this.props.content_page.length > 0 ?
								<div className="col-lg-3">
									<div className="single-blog style-three">
										<div className="thumb recommended">
											<img src={this.props.content_page[4].IntroImage} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<h4 className="title">{this.props.t('Jobs')}</h4>
												<Link hrefLang={this.props.i18n.language} className="btn btn-yellow" to={{ pathname: "/" + this.props.i18n.language + "/contentpage/" + this.props.content_page[4].ID, params: this.props.content_page[4] !== undefined ? this.props.content_page[4].ID : null }}>
													<span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								:
								<div>
									ไม่พบข้อมูล
								</div>
							}
							{this.props.content_page.length > 0 ?
								<div className="col-lg-3">
									<div className="single-blog style-three">
										<div className="thumb recommended">
											<img src={this.props.content_page[5].IntroImage} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<h4 className="title">{this.props.t('Regions')}</h4>
												<Link hrefLang={this.props.i18n.language} className="btn btn-yellow" to={{ pathname: "/" + this.props.i18n.language + "/contentpage/" + this.props.content_page[5].ID, params: this.props.content_page[5] !== undefined ? this.props.content_page[5].ID : null }}>
													<span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								:
								<div>
									ไม่พบข้อมูล
								</div>
							}
							{this.props.content_page.length > 0 ?
								<div className="col-lg-3">
									<div className="single-blog style-three">
										<div className="thumb recommended">
											<img src={this.props.content_page[7].IntroImage} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<h4 className="title">{this.props.t('Transportations')}</h4>
												<Link hrefLang={this.props.i18n.language} className="btn btn-yellow" to={{ pathname: "/" + this.props.i18n.language + "/contentpage/" + this.props.content_page[7].ID, params: this.props.content_page[7] !== undefined ? this.props.content_page[7].ID : null }}>
													<span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								:
								<div>
									ไม่พบข้อมูล
								</div>
							}
						</div>
					</div>
				</div>
				{/* <div className="col-sm-3">
									<div className="single-blog style-three">
										<div className="thumb">
											<img src={publicUrl + "assets/img/blog/12.png"} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<Link className="tag" to="/blog-details">Europe</Link>
												<p className="date">19 September 2019</p>
												<h4 className="title">ศาสนา</h4>
												<Link className="btn btn-yellow" to="/blog-details"><span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="single-blog style-three">
										<div className="thumb">
											<img src={publicUrl + "assets/img/blog/12.png"} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<Link className="tag" to="/blog-details">Europe</Link>
												<p className="date">19 September 2019</p>
												<h4 className="title">อาหาร</h4>
												<Link className="btn btn-yellow" to="/blog-details"><span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="single-blog style-three">
										<div className="thumb">
											<img src={publicUrl + "assets/img/blog/13.png"} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<Link className="tag" to="/blog-details">Europe</Link>
												<p className="date">19 September 2019</p>
												<h4 className="title">การเดินทางเข้าในประเทศ</h4>
												<Link className="btn btn-yellow" to="/blog-details"><span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="single-blog style-three">
										<div className="thumb">
											<img src={publicUrl + "assets/img/blog/14.png"} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<Link className="tag" to="/blog-details">Europe</Link>
												<p className="date">19 September 2019</p>
												<h4 className="title">สกุลเงิน</h4>
												<Link className="btn btn-yellow" to="/blog-details"><span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="single-blog style-three">
										<div className="thumb">
											<img src={publicUrl + "assets/img/blog/14.png"} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<Link className="tag" to="/blog-details">Europe</Link>
												<p className="date">19 September 2019</p>
												<h4 className="title">ภูมิภาค</h4>
												<Link className="btn btn-yellow" to="/blog-details"><span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="single-blog style-three">
										<div className="thumb">
											<img src={publicUrl + "assets/img/blog/14.png"} alt="blog" />
										</div>
										<div className="single-blog-details-wrap">
											<div className="single-blog-details text-center">
												<Link className="tag" to="/blog-details">Europe</Link>
												<p className="date">19 September 2019</p>
												<h4 className="title">การเดินทาง</h4>
												<Link className="btn btn-yellow" to="/blog-details"><span>{this.props.t('Read more')}<i className="la la-arrow-right" /></span></Link>
											</div>
										</div>
									</div>
								</div> */}

				{/* <div className="row justify-content-center" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
						<div className="thumb">
							<img src={publicUrl + "assets/img/others/1.png"} alt="ads" />
						</div>
					</div> */}
			</div>
		</div >

	}
}

export default withTranslation()(Recommend);
