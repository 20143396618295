import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import PageHeaderTourOperator from './global-components/page-header-tour-operator';
import TourOperator from './section-components/tour-operator';
// import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';


// const TourOperatorPage = () => {
//     return <div>
//         <Navbar />
//         <PageHeaderTourOperator headertitle={this.props.t('Travel Agency')} />
//         <TourOperator />
//         {/* <Subscribe /> */}
//         <Footer />
//     </div>
// }

// export default TourOperatorPage

class TourOperatorPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            page: 0,
            region_id: 0,
            province_id: 0
        }
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }

    render() {
        this.state.search = new URLSearchParams(this.props.location.search).get("search")
        this.state.page = new URLSearchParams(this.props.location.search).get("page")
        this.state.region_id = new URLSearchParams(this.props.location.search).get("region_id")
        this.state.province_id = new URLSearchParams(this.props.location.search).get("province_id")
        return (
            <div>
                <Navbar />
                <PageHeaderTourOperator headertitle={this.props.t('Tour Operators')} />
                <TourOperator search={this.state.search} page={this.state.page} region_id={this.state.region_id} province_id={this.state.province_id} />
                {/* <Subscribe /> */}
                <Footer />
            </div>
        )
    }
}
export default withTranslation()(TourOperatorPage);
