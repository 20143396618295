import React, { Component } from 'react';

class Page_header extends Component {

	render() {

		let HeaderTitle = this.props.headertitle;
		let publicUrl = process.env.PUBLIC_URL + '/'

		return (
			//WebkitTransition: 'all 9s ease', MozTransition: 'all 9s ease', OTransition: 'all 9s ease', transition: 'all 9s ease' 
			<div className="breadcrumb-area jarallax " style={{ backgroundImage: 'url(' + publicUrl + "../../assets/img/bg/homebg1.jpg", backgroundPositionY: '60%' }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcrumb-inner">
								<h1 className="page-title">{HeaderTitle}</h1>
								<ul className="page-list">
									{/* <li><Link to="/">Home</Link></li> */}
									{/* <li>{Subheader}</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			// <div className="banner-area viaje-go-top jarallax">
			// 	<div className="banner-slider">
			// 		<div className="banner-slider-item hg banner-bg-header">
			// 			<div className="container">
			// 				<div className="row">
			// 					<div className="col-lg-12">
			// 						<div className="banner-inner">
			// 							<h1 className="banner-title" style={{ fontSize: 64, padding: 20, margin: 75, textAlign: 'center', color: 'white' }}>{HeaderTitle}</h1>
			// 						</div>
			// 					</div>
			// 				</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>


		)
	}
}


export default Page_header