import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';


class Activities extends Component {
	constructor(props) {
		super(props)
		this.state = {
			lang: this.props.i18n.language,
		}
	}
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		let ba = this.props.place[0].IntroImage
		let ba1 = this.props.place[1].IntroImage
		let ba2 = this.props.place[2].IntroImage
		if (this.props.i18n.language === 'th') {
			require('moment/locale/th');
			var StartDate_Activity_0 = moment(this.props.place[0].Activity.Info.StartDate).add(543, 'y').format("DD MMM YYYY")
			var EndDate_Activity_0 = moment(this.props.place[0].Activity.Info.EndDate).add(543, 'y').format("DD MMM YYYY")
			var StartDate_Activity_1 = moment(this.props.place[1].Activity.Info.StartDate).add(543, 'y').format("DD MMM YYYY")
			var EndDate_Activity_1 = moment(this.props.place[1].Activity.Info.EndDate).add(543, 'y').format("DD MMM YYYY")
			var StartDate_Activity_2 = moment(this.props.place[2].Activity.Info.StartDate).add(543, 'y').format("DD MMM YYYY")
			var EndDate_Activity_2 = moment(this.props.place[2].Activity.Info.EndDate).add(543, 'y').format("DD MMM YYYY")
		} else {
			require('moment/locale/en-gb');
			var StartDate_Activity_0 = moment(this.props.place[0].Activity.Info.StartDate).format("DD MMM YYYY")
			var EndDate_Activity_0 = moment(this.props.place[0].Activity.Info.EndDate).format("DD MMM YYYY")
			var StartDate_Activity_1 = moment(this.props.place[1].Activity.Info.StartDate).format("DD MMM YYYY")
			var EndDate_Activity_1 = moment(this.props.place[1].Activity.Info.EndDate).format("DD MMM YYYY")
			var StartDate_Activity_2 = moment(this.props.place[2].Activity.Info.StartDate).format("DD MMM YYYY")
			var EndDate_Activity_2 = moment(this.props.place[2].Activity.Info.EndDate).format("DD MMM YYYY")
		}

		return <div className="client-area pd-top-108 pd-bottom-120 jarallax" style={{ backgroundImage: 'url(' + publicUrl + 'assets/img/bg/bg1.png)', backgroundSize: 'cover' }}>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-4 col-lg-8">
						<div className="section-title text-center style-two">
							<h2 className="title large-text-48" style={{ fontSize: 52 }}>{this.props.t('Tourism Activities')}</h2>
						</div>
					</div>
				</div>
				<div className="swiper-container client-slider-two">
					<div className="swiper-wrapper">
						{/* item */}
						<div className="swiper-slide">
							<div className="client-slider-item">
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/activity/" + this.props.place[0].ID, params: this.props.place[0] !== undefined ? this.props.place[0].ID : null }}>
									<div className="row">
										<div className="col-lg-5 thumb" style={{ backgroundImage: `url(${ba})` }}>
										</div>
										<div className="col-lg-7">
											<div className="details">
												<h4 className="normal-text-18-bold">{this.props.place[0].Name.th.slice(0, 40) + "..."}</h4>
												<p className="location"><img className="province-location" style={{ marginRight: 10 }} src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-18" style={{ color: '#245AA3' }}>{this.props.place[0].Province[this.props.i18n.language]}</span></p>
												<div className="row">
													<p className="location"><img className="province-location" style={{ marginRight: 10 }} src={publicUrl + "assets/img/icons/calendar.png"} alt="calendar" />
														<span className="normal-text-18" style={{ color: '#339933' }}>{StartDate_Activity_0} - {EndDate_Activity_0}</span></p>
													<p className="location"><img className="province-location" style={{ marginLeft: 50, width: 85 }} src={publicUrl + "assets/img/icons/verified.png"} alt="calendar" /></p>
												</div>
												<p>{this.props.place[0].Detail.th.slice(0, 400) + "..."}</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						{/* item */}
						<div className="swiper-slide">
							<div className="client-slider-item">
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/activity/" + this.props.place[1].ID, params: this.props.place[1] !== undefined ? this.props.place[1].ID : null }}>
									<div className="row">
										<div className="col-lg-5 thumb" style={{ backgroundImage: `url(${ba1})` }}>
										</div>
										<div className="col-lg-7">
											<div className="details">
												<h4 className="normal-text-18-bold">{this.props.place[1].Name.th.slice(0, 40) + "..."}</h4>
												<p className="location"><img className="province-location" style={{ marginRight: 10 }} src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-18" style={{ color: '#245AA3' }}>{this.props.place[1].Province[this.props.i18n.language]}</span></p>
												<div className="row">
													<p className="location"><img className="province-location" style={{ marginRight: 10 }} src={publicUrl + "assets/img/icons/calendar.png"} alt="calendar" />
														<span className="normal-text-18" style={{ color: '#339933' }}>{StartDate_Activity_1} - {EndDate_Activity_1}</span></p>
													<p className="location"><img className="province-location" style={{ marginLeft: 50, width: 85 }} src={publicUrl + "assets/img/icons/verified.png"} alt="calendar" /></p>
												</div>
												<p>{this.props.place[1].Detail.th.slice(0, 400) + "..."}</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
						{/* item */}
						<div className="swiper-slide">
							<div className="client-slider-item">
								<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/activity/" + this.props.place[2].ID, params: this.props.place[2] !== undefined ? this.props.place[2].ID : null }}>
									<div className="row">
										<div className="col-lg-5 thumb" style={{ backgroundImage: `url(${ba2})` }}>
										</div>
										<div className="col-lg-7">
											<div className="details">
												<h4 className="normal-text-18-bold">{this.props.place[2].Name.th.slice(0, 40) + "..."}</h4>
												<p className="location"><img className="province-location" style={{ marginRight: 10 }} src={publicUrl + "assets/img/icons/location.png"} alt="map" />
													<span className="normal-text-18" style={{ color: '#245AA3' }}>{this.props.place[2].Province[this.props.i18n.language]}</span></p>
												<div className="row">
													<p className="location"><img className="province-location" style={{ marginRight: 10 }} src={publicUrl + "assets/img/icons/calendar.png"} alt="calendar" />
														<span className="normal-text-18" style={{ color: '#339933' }}>{StartDate_Activity_2} - {EndDate_Activity_2}</span></p>
													<p className="location"><img className="province-location" style={{ marginLeft: 50, width: 85 }} src={publicUrl + "assets/img/icons/verified.png"} alt="calendar" /></p>
												</div>
												<p>{this.props.place[2].Detail.th}</p>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>

					</div>
					{/* Add Pagination */}
					<div className="tp-control-nav text-center">
						<div className="slick-arrow swiper-buttons-prev"><i className="la la-long-arrow-left" /></div>
						<div className="slick-arrow swiper-buttons-next"><i className="la la-long-arrow-right" /></div>
					</div>
					{/* /.end carousel */}
				</div>
			</div>
		</div>
	}
}

export default withTranslation()(Activities);