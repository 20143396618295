import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

//i18n
import i18n from '../../../src/i18n';
import { withTranslation } from 'react-i18next';
import languages from "../../components/Common/languages"
import { map } from "lodash"

// // falgs
// import usFlag from "../../../assets/images/flags/us.jpg";
// import spain from "../../../assets/images/flags/spain.jpg";
// import germany from "../../../assets/images/flags/germany.jpg";
// import italy from "../../../assets/images/flags/italy.jpg";
// import russia from "../../../assets/images/flags/russia.jpg";
// import thailand from "../../../assets/images/flags/tha.jpg";
class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      selectedLang: "th",
      path: ""
    }
    console.log(this.props.i18n.language)
  }

  componentDidMount() {
    console.log(this.props.i18n)
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    this.setState({ selectedLang: currentLanguage })
  }


  changeLanguageAction = lang => {
    //set language as i18n
    // i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    // this.setState({ selectedLang: lang })
    const path = window.location.pathname.split("/");
    if (/th|en|zh/.test(path[1])) {
      this.setState({
        path: window.location.pathname.substring(3) + window.location.search
      }
      )
      // window.location.href = '/' + lang + window.location.pathname.substring(3) + window.location.search
    } else {
      this.setState({
        path: window.location.pathname + window.location.search
      }
      )
      // window.location.href = lang + window.location.pathname + window.location.search
    }
  }


  toggle = () => {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  render() {

    const { selectedLang, menu } = this.state
    return (
      <div style={{ display: 'inline-flex' }}>
        {this.props.i18n.language === 'en' || this.props.i18n.language === 'zh' ?
          <Dropdown isOpen={menu} toggle={this.toggle} className="d-inline-block width-dropdown-lang style-dropdown-moblie" style={{ paddingTop: 4 }}>
            <DropdownToggle className="bt-change-color" tag="ul" >
              <img
                src={languages[selectedLang].flag}
                height="16"
                className="mr-1"
                alt=""
              />
              <li className="align-middle">
                {languages[selectedLang].label}

              </li>

            </DropdownToggle>

            <DropdownMenu className="language-switch" right>
              {map(Object.keys(languages), key => (
                <DropdownItem
                  key={key}
                  // onClick={() => this.changeLanguageAction(key)}
                  className={`notify-item ${selectedLang === key ? "active" : "none"
                    }`}
                >
                  <a hrefLang={key} href={"/" + key +
                    ((/th|en|zh/.test(window.location.pathname.split("/")[1])) ? window.location.pathname.substring(3) : window.location.pathname) +
                    window.location.search}>
                    <img
                      src={languages[key].flag}
                      className="mr-1"
                      height="12"
                      alt=""
                    />
                    <span className="align-middle">{languages[key].label}</span>
                  </a>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          :
          <Dropdown isOpen={menu} toggle={this.toggle} className="d-inline-block width-dropdown style-dropdown-moblie" style={{ paddingTop: 4 }}>
            <DropdownToggle className="bt-change-color" tag="ul" >
              <img
                src={languages[selectedLang].flag}
                height="16"
                className="mr-1"
                alt=""
              />
              <li className="align-middle">
                {languages[selectedLang].label}

              </li>

            </DropdownToggle>

            <DropdownMenu className="language-switch" right>
              {map(Object.keys(languages), key => (
                <DropdownItem
                  key={key}
                  // onClick={() => this.changeLanguageAction(key)}
                  className={`notify-item ${selectedLang === key ? "active" : "none"
                    }`}
                >
                  <a hrefLang={key} href={"/" + key +
                    ((/th|en|zh/.test(window.location.pathname.split("/")[1])) ? window.location.pathname.substring(3) : window.location.pathname) +
                    window.location.search}>
                    <img
                      src={languages[key].flag}
                      className="mr-1"
                      height="12"
                      alt=""
                    />
                    <span className="align-middle">{languages[key].label}</span>
                  </a>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        }

      </div>
    )
  }
}

export default withTranslation()(LanguageDropdown);
