import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import PageHeaderTravelRoute from './global-components/page-header-travel-route';
import Footer from './global-components/footer';
import { api_endpoint } from '../global-config'
import { withTranslation } from 'react-i18next';
import { Container, BreadcrumbItem } from 'reactstrap';
import '../components/cssnew.css'
import './cssnew.css'
import FadeIn from "react-fade-in";
import "react-image-gallery/styles/css/image-gallery.css";
import * as loadingData from "../loadingttd.json";
import Lottie from "react-lottie";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import ReactGA from 'react-ga4';
// import { Helmet } from "react-helmet";
// import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '10px'
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
class TravelRouteCommunityDetailsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            route_id: props.match.params.id,
            place_details: {},
            days: {},
            place_stop: {},
            isLoading: true,
            lang: this.props.i18n.language,
            modal_control: false,
            matches: window.matchMedia("(min-width: 992px)").matches,
            isLogin: localStorage.getItem('user_data'),
            lat_map: 0,
            lng_map: 0,
            showingInfoWindow: false,
            activeMarker: {},          // Shows the active marker upon click
            selectedPlace: {},          // Shows the InfoWindow to the selected place upon a marker
        }
        console.log(props)
    }
    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };
    renderMarker(e) {
        var newURL = window.location.origin + "/" + this.state.lang + "/attraction_community/" + e.attractionId
        return (
            <div>
                <p className="normal-text-14" style={{ color: 'gray', marginLeft: 81 }}><a hrefLang={this.state.lang} href={newURL} target="_blank" rel="noopener noreferrer">{this.props.t('See more details')}</a></p>
            </div>
        )
    }
    //เรียกข้อมูลสถานที่
    async get_place_detail() {
        const response = await fetch(
            api_endpoint + `/cbt/route_getdetails`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    routeId: this.state.route_id,
                    language: this.state.lang
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        _result.result.days.map((e) => {
            this.state.days = e.placeStop
            this.state.lat_map = e.placeStop[0].latitude
            this.state.lng_map = e.placeStop[0].longitude
            return e
        })
        this.setState({
            place_details: _result.result,
            place_stop: _result.result.days,
            isLoading: false,
        })
    }

    modal_control() {
        this.setState({
            modal_control: true
        }, () => {
            console.log(this.state.modal_control)
            return this.state.modal_control
        })
    }

    login_check() {
        this.setState({
            isLogin: localStorage.getItem("user_data")
        })
    }

    componentDidMount() {
        if (this.state.route_id === undefined) {
            this.props.history.goBack()
        }
        else {
            const language = localStorage.getItem("I18N_LANGUAGE")
            if (!language) {
                localStorage.setItem("I18N_LANGUAGE", "th")
            }
            // if ()
            this.get_place_detail()
            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        }
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        this.setState({
            isLoading: true,
            lang: nextProps.i18n.language
        }, () => {
            this.get_place_detail()
            const handler = e => this.setState({ matches: e.matches });
            window.matchMedia("(min-width: 992px)").addListener(handler);
        })
    }

    render() {
        if (this.state.isLoading === true) {
            return (
                <div style={{ marginTop: 200 }} className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div className="card">
                                <div className="cardbody">
                                    <Lottie options={defaultOptions} height={300} width={300} />
                                    <div>
                                        <h4 style={{ color: 'black', padding: 30 }}>{this.props.t('Preparing data, please wait...')}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            )
        }
        else {
            let publicUrl = process.env.PUBLIC_URL + '/'
            const mark = this.props.t('The point')
            const dt = this.props.t('Distance')
            const km = this.props.t('km')
            return (
                <div>
                    <div className="menu-back-page animated fadeIn">
                        <span className="back-top">
                            <a hrefLang={this.state.lang} href={"/" + this.state.lang + "/community_routes"} className="normal-text-18-detail"><i className="fa fa-angle-left" style={{ fontSize: 22 }}><span className="large-text-20" style={{ padding: "0px 0px 0px 10px" }}>{this.props.t('Back')}</span></i></a>
                        </span>
                    </div>
                    <Navbar modal_control={this.state.modal_control} login_check={this.login_check} />
                    <PageHeaderTravelRoute headertitle={this.state.place_details.nameOfRoute} />
                    <div className="pd-top-80 viaje-go-top">
                        <Container>
                            <div className="row" style={{ marginBottom: 20 }}>
                                <div className="new-breadcrumb">
                                    <BreadcrumbItem><a hrefLang={this.state.lang} href={"/" + this.state.lang} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                                    <BreadcrumbItem ><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/community_routes"} className="normal-text cl-gray">{this.props.t('Community-Based Tourism Routes')}</a></BreadcrumbItem>
                                    <BreadcrumbItem active><span className="normal-text cl-black">{this.state.place_details.nameOfRoute}</span></BreadcrumbItem>
                                </div>
                            </div>
                            <p className="large-text-36 pd-t-30 cl-black" >{this.state.place_details.nameOfRoute}</p>
                            <p className="large-text cl-black" >{this.state.place_details.nameOfCommunity}</p>
                            <hr style={{ height: 2, borderWidth: 5, backgroundColor: '#173865' }}></hr>

                            {this.state.place_details.days.map((e, i) => {
                                return (
                                    <div key={i}>
                                        <h1>{this.props.t("Day") + " " + e.day}</h1>
                                        <div className="container">
                                            <div className="row justify-content-center" >
                                                {e.placeStop.map((j, h) => {
                                                    const formatNumber = (num) => {
                                                        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                                                    }
                                                    // console.log(j)
                                                    return (
                                                        <div className="col-lg-6 col-md-12" key={h}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                <div style={{ textAlign: '-webkit-center' }}>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <img src={publicUrl + "assets/img/icons/location-pin.svg"} alt="" />
                                                                        <div className="style-text" >
                                                                            <p className="large-text-20 cl-black line-h">{j.stopOrder}</p>
                                                                        </div>
                                                                        <div className="location" style={{ color: '#173865' }}>
                                                                            {this.props.t("Distance") + " " + formatNumber(j.distance) + " " + this.props.t("km")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="single-blog style" style={{ borderBottom: '1px solid rgb(240, 240, 240)' }}>
                                                                    <div className="thumb">
                                                                        <img src={j.image} alt={""} className="img-detail-cbt" onError={(event) => event.target.setAttribute("src", "https://thailandtourismdirectory.go.th/assets/upload/icons/no-image.jpg")} />
                                                                    </div>
                                                                    <div className="single-blog-details">
                                                                        <h4 className="title">{j.nameOfAttraction}</h4>
                                                                        <p className="content" style={{ textAlign: "justify" }}>{j.description}</p>
                                                                        <Link className="btn-read-more" hrefLang={this.state.lang} to={{ pathname: "/" + this.state.lang + "/attraction_community/" + j.attractionId, params: j.attractionId }} target="_blank"><span>{this.props.t("See more details")}<i className="la la-arrow-right" /></span></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        // <div key={h} style={{ width: "100%", border: '2px solid gray', marginBottom: 10 }}>
                                                        //     <div style={{ width: "100%", display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box', alignItems: 'center' }}>
                                                        //         <img src={j.image} alt={""} style={{ width: 350, height: 200 }} />
                                                        //         <div style={{ display: 'flex', marginLeft: '2rem' }}>
                                                        //             <div>
                                                        //                 <h3>{j.nameOfAttraction}</h3>
                                                        //             </div>
                                                        //             <Link hrefLang={this.state.lang} to={{ pathname: "/" + this.state.lang + "/attraction_community/" + j.attractionId, params: j.attractionId }}>
                                                        //                 <div className="next-to-detail">
                                                        //                     <i className="fa fa-arrow-right"></i>
                                                        //                 </div>
                                                        //             </Link>
                                                        //         </div>
                                                        //     </div>
                                                        // </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <hr></hr>
                            <h5 className="topic-text pd-bt-15">{this.props.t('Data Owner Agencies')}</h5>
                            <div className="row margin-0" >
                                <img className="" src={publicUrl + "assets/img/cbt.jpg"} style={{ width: 250, height: 150 }} alt="" />
                                <img className="" src={publicUrl + "assets/img/DASTA_LOGO.png"} style={{ width: 215, height: 70, margin: '2.4rem 2rem 0 0' }} alt="" />
                                <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="btn-center">
                                        <div className="large-text cl-black">{this.props.t("Designated Areas for Sustainable Tourism Administration (Public Organization)")}</div>
                                        <a className="btn btn-website-green" href={"https://cbtthailand.dasta.or.th/webapp/recommendedRoute/content/" + this.state.route_id + "/"} target="_blank" rel="noopener noreferrer">
                                            <span className="normal-text-18">{this.props.t('Visit Website')}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            {/* แผนที่ */}
                            <h5 className="topic-text pd-bt-15">{this.props.t('Map')}</h5>
                            <div id="gmaps-markers" className="gmaps" style={{ height: 430, width: '100%' }} >
                                <Map
                                    containerStyle={containerStyle}
                                    google={window.google}
                                    zoom={12}
                                    initialCenter={{
                                        lat: this.state.lat_map,
                                        lng: this.state.lng_map
                                    }}
                                    center={{
                                        lat: this.state.lat_map,
                                        lng: this.state.lng_map
                                    }}
                                >
                                    {this.state.days.map((e, i) => {
                                        console.log(e)
                                        const index = i + 1
                                        return (
                                            <Marker
                                                key={i}
                                                label={{ text: index.toString(), color: "white" }}
                                                position={{ lat: Number(e.latitude), lng: Number(e.longitude) }}
                                                onClick={this.onMarkerClick}
                                                image={e.image === null || e.image === "" ? "https://thailandtourismdirectory.go.th/assets/upload/icons/no-image.jpg" : e.image}
                                                name={e.nameOfAttraction.length > 15 ? e.nameOfAttraction.slice(0, 15) + "..." : e.nameOfAttraction}
                                                detail={this.renderMarker(e)}
                                                stop={e.stopOrder}
                                                dis_length={e.distance / 1000}
                                            // icon={publicUrl + "assets/img/icons/marker-style.png"}
                                            />
                                        )
                                    })}
                                    <InfoWindow
                                        marker={this.state.activeMarker}
                                        visible={this.state.showingInfoWindow}
                                        onClose={this.onClose}
                                    >
                                        <>
                                            <div className="row" style={{ marginLeft: 0 }}>
                                                <img style={{ height: 75, width: 75, borderRadius: 7, objectFit: 'cover' }} src={this.state.selectedPlace.image} alt="" />
                                                <div className="row" style={{ display: 'inline-block', marginLeft: 10 }}>
                                                    <p className="normal-text-18 cl-black" style={{ lineHeight: '19px' }}>{this.state.selectedPlace.name}</p>
                                                    <p className="normal-text-14" style={{ color: '#173865' }}><img style={{ marginRight: 8 }} src={publicUrl + "assets/img/icons/location.png"} alt="" />{`${mark} ` + this.state.selectedPlace.stop}</p>
                                                    <p className="normal-text-14">{`${dt} ` + this.state.selectedPlace.dis_length + ` ${km}`}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <p>{this.state.selectedPlace.detail}</p>
                                            </div>
                                        </>
                                    </InfoWindow>
                                </Map>
                            </div>
                        </Container>
                    </div>
                    <Footer />
                </div>
            )
        }
    }

}

export default GoogleApiWrapper((props) => {
    // console.log(props)
    const lang = props.match.params.lng
    return ({
        apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
        language: lang
    })
})(withTranslation()(TravelRouteCommunityDetailsPage));


