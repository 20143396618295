import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import { api_endpoint } from '../../global-config'
import * as loadingData from "./loandtdd.json";
import Lottie from "react-lottie";
import { TabContent, TabPane, BreadcrumbItem } from "reactstrap";
import classnames from 'classnames';
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { withTranslation } from 'react-i18next';
import drop from "../../assets/images/icon/drop.svg"
import { Helmet } from "react-helmet";
// import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

class TravelRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      places_data: [],
      count_places: [],
      count: 0,
      skip: 0,
      limit: 9,
      subtype: [],
      lang: this.props.i18n.language,
      search: props.search,
      category: [],
      page_index: 1,
      pagination_min: -1,
      pagination_max: 5,
      searching: true,
      main_id_type: [9],
      provinces: [],
      province_id: 0,
      regions: [],
      region_id: 0,
      region_selected: '',
      province_selected: 'กรุณาเลือกจังหวัด',
      isLoading: false,
      selectedLang: "th",
      activeTab: '1',
      regions_loading: true,
      provinces_loading: true,
      showingInfoWindow: false,
      activeMarker: {},          // Shows the active marker upon click
      selectedPlace: {},          // Shows the InfoWindow to the selected place upon a marker
      types: [],
      path: "routes",
      page_name: this.props.t('Tourist Routes')
    }
    if (props.page !== null && !isNaN(props.page)) {
      this.state.page_index = parseInt(props.page)
    }
    if (props.region_id !== null && !isNaN(props.region_id)) {
      this.state.region_id = parseInt(props.region_id)
    }
    if (props.province_id !== null && !isNaN(props.province_id)) {
      this.state.province_id = parseInt(props.province_id)
    }
    if (props.types !== undefined) {
      this.state.types = props.types.split(",").map(x => +x);
    }
    if (props.path !== undefined) {
      this.state.path = props.path;
    }
    if (props.page_name !== undefined) {
      this.state.page_name = props.page_name;
    }
  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  //คุมภาษา
  change_lang_callback() {

  }
  //มุมมอง
  toggleTab(tab) {
    if (tab === '1') {
      this.search_reload("/" + this.state.lang + "/routes")
    } else if (tab === '2') {
      this.search_reload("/" + this.state.lang + "/routes_map")
    }
  }
  //ช่อง search
  searchInput(value) {
    this.setState({
      search: value.target.value
    })
  }

  //ค้นหาสถานที่
  async search_places() {
    setTimeout(this.setState({
      isLoading: true
    }), 250)
    const response = await fetch(
      api_endpoint + `/maininfo/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Search: this.state.search,
          Skip: (this.state.page_index - 1) * 9,
          Limit: this.state.limit,
          SubType: this.state.subtype,
          Lang: this.state.lang,
          MainTypeID: this.state.main_id_type,
          ProvinceCode: this.state.province_id !== 0 ? [String(this.state.province_id)] : [],
          RegionID: this.state.region_id !== 0 ? [this.state.region_id] : [],
          SortBy: [
            { "field": "updated_at", "sort": 1 }
          ]
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    if (_result.sortedResults.length > 0) {
      if (this.state.searching === false) {
        this.setState({
          places_data: _result.sortedResults,
          count: _result.count,
          pagination_min: -1,
          pagination_max: 5,
          Skip: (this.state.page_index - 1) * 9,
          searching: true,
          isLoading: false
        }, () => {
          this.get_places_count()
        })
      }
      else {
        this.setState({
          places_data: _result.sortedResults,
          count: _result.count,
          // pagination_min: -1,
          // pagination_max: 5,
          // page_index: 0,
          searching: true,
          isLoading: false
        }, () => {
          this.get_places_count()
        })
      }
    }
    else {
      this.setState({
        places_data: _result.sortedResults,
        count: _result.count,
        // pagination_min: -1,
        // pagination_max: 5,
        // page_index: 0,
        searching: true,
        isLoading: false
      }, () => {
        this.get_places_count()
      })
    }
  }


  //เรียกประเภทสถานที่
  // async get_places_types() {
  //   const response = await fetch(
  //     api_endpoint + `/maininfo/subtypes`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({
  //         MainTypeID: 5,
  //         Lang: this.state.lang,
  //       }), // body data type must match "Content-Type" header
  //     }
  //   );
  //   var _result = await response.json();
  //   console.log(_result)
  //   let arr = []
  //   for (const [key, value] of Object.entries(_result)) {
  //     arr.push({ key: key, value: value, selected: false })
  //   }
  //   this.setState({
  //     category: arr
  //   }, () => {
  //     console.log(arr)
  //   })

  // }


  //เรียกสถานที่
  async get_places() {
    const response = await fetch(
      api_endpoint + `/maininfo/read`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Skip: (this.state.page_index - 1) * 9,
          Limit: this.state.limit,
          Subtype: this.state.subtype,
          Lang: this.state.lang,
          MainTypeID: this.state.main_id_type,
          ProvinceCode: this.state.province_id !== 0 ? [String(this.state.province_id)] : [],
          RegionID: this.state.region_id !== 0 ? [this.state.region_id] : [],
          SortBy: [
            { "field": "updated_at", "sort": 1 }
          ]
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    if (_result.results.length > 0) {
      this.setState({
        places_data: _result.results,
        count: _result.count,
      }, () => {
        this.get_places_count()
      })
    }
    else {
      this.setState({
        places_data: _result.results,
        count: _result.count,
      }, () => {
        this.get_places_count()
      })
    }
  }


  //จำนวนข้อมูลทั้งหมด
  async get_places_count() {
    // const response = await fetch(
    //   `https://api.ttd.dodeep.work/api/v1/attraction/count`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({
    //       Subtype: this.state.subtype,
    //       Lang: this.state.lang
    //     }), // body data type must match "Content-Type" header
    //   }
    // );
    // var _result = await response.json();

    let arr = []
    for (let i = this.state.page_index, j = 0; i < (this.state.count / 9) + 1; i++) {
      if (i === this.state.page_index) {
        if (this.state.page_index === 1) {
          arr.push({ page: i, onSelected: true, skip: 0, limit: 9 })
          continue
        }
        else {
          arr.push({ page: i, onSelected: true, skip: this.state.page_index * 9, limit: 9 })
          j = this.state.page_index * 9
          continue
        }
      }
      else {
        j = j + 9
        arr.push({ page: i, onSelected: false, skip: j, limit: 9 })
        continue
      }
    }
    this.setState({
      count_places: arr,
      isLoading: false
    }, () => {
      console.log(this.state.count_places)
    })
  }


  //เรียกข้อมูลภูมิภาค
  async lkup_regions() {
    const response = await fetch(
      api_endpoint + `/lkup/regions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Lang: this.state.lang,
          // RegionID: this.state.region_id,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    var regions = []
    regions.push(
      {
        "ID": 0,
        "Details": {
          [this.state.lang]: {
            "Name": this.props.t('All regions')
          }
        }
      },
    )
    regions = regions.concat(_result)
    for (let region of regions) {
      if (region.ID === this.state.region_id) {
        this.state.region_selected = region.Details[this.state.lang]["Name"]
      }
    }
    this.setState({
      regions: regions,
      regions_loading: false
    })
  }

  //เรียกข้อมูลจังหวัด
  async lkup_provinces() {
    const response = await fetch(
      api_endpoint + `/lkup/provinces`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Lang: this.state.lang,
          RegionID: this.state.region_id !== 0 ? [this.state.region_id] : [],
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    for (let province of _result) {
      if (province.Details[this.state.lang]["Code"] == this.state.province_id) {
        this.state.province_selected = province.Details[this.state.lang]["Name"]
      }
    }
    this.setState({
      provinces: _result,
      provinces_loading: false
    })
  }
  //เลือกภูมิภาค
  region_selected_submit(value) {
    this.state.page_index = 1
    this.state.region_id = value.ID
    this.state.province_id = 0
    this.search_reload(window.location.pathname)
  }

  //เลือกจังหวัด
  province_selected_submit(value) {
    this.state.page_index = 1
    this.state.region_id = 0
    this.state.province_id = value.Code.toString()
    this.search_reload(window.location.pathname)
  }


  async search_submit() {
    this.state.page_index = 1
    this.state.region_id = 0
    this.state.province_id = 0
    this.search_reload(window.location.pathname)
  }

  async search_reload(path) {
    window.location.href = path + "?page=" + (this.state.page_index)
      + ((this.state.search !== null) ? "&search=" + this.state.search : "")
      + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
      + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")
  }
  renderMarker(e) {
    var newURL = window.location.origin + "/" + this.state.lang + "/route/" + e.ID
    return (
      <div>
        <p className="normal-text-14" style={{ color: 'gray', marginLeft: 81 }}><a hrefLang={this.state.lang} href={newURL}>{this.props.t('See more details')}</a></p>
      </div>
    )
  }


  componentDidMount() {
    const language = localStorage.getItem("I18N_LANGUAGE")
    if (!language) {
      localStorage.setItem("I18N_LANGUAGE", "th")
    }
    console.log(language)
    this.get_places()
    this.get_places_count()
    this.lkup_provinces()
    this.lkup_regions()
    this.search_places()
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    this.setState({
      isLoading: true,
      lang: nextProps.i18n.language
    }, () => {
      this.get_places()
      this.get_places_count()
      this.lkup_provinces()
      this.lkup_regions()
    })
  }

  render() {
    if (this.state.isLoading === true || this.state.provinces_loading === true || this.state.regions_loading === true) {
      // console.log(this.state.lang)
      return (
        <div className="tour-list-area pd-top-80 viaje-go-top">
          <div className="container">
            <div className="row" style={{ marginBottom: 20 }}>
              <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                <div className="new-breadcrumb">
                  <BreadcrumbItem><a hrefLang={this.state.lang} href={"/"} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                  <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path} className="normal-text cl-black">{this.state.page_name}</a></BreadcrumbItem>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="row style-breadcrumb">
                  <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                  <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                    <div className="small-text viewpoint-list cl-blue">
                      <i className="fa fa-list-ul viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                      <span data-nosnippet>{this.props.t('list')}</span>
                    </div>
                  </div>
                  <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                    <div className="small-text viewpoint-list">
                      <i className="fa fa-map-o viewpoint-list-size" aria-hidden="true" ></i>
                      <span data-nosnippet>{this.props.t('Maps')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 order-lg-1">
                <div className="sidebar-area sidebar-area-inner-page">
                  <div className="widget tour-list-widget">
                    <div className="single-widget-search-input-title">{this.props.t('Search for Tourist Routes')}</div>
                    <div className="widget-tour-list-search">
                      <div className="search-form">
                        <div className="form-group">
                          <input type="text" placeholder={this.props.t('Tourist Routes')} value={this.state.search} onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                              this.search_places()
                            }
                          }} onChange={(e) => { this.searchInput(e) }} />
                        </div>
                        <button className="submit-btn" type="button" onClick={() => { this.search_places() }}><i className="ti-search" /></button>
                      </div>
                    </div>
                    <div className="single-widget-search-input-title">{this.props.t('Region')}</div>
                    <div className="single-widget-search-input">
                      <div className="dropdown">
                        <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                          {/* <p className="cl-gray normal-text line-opacity" >{this.state.region_selected}<img src={drop} className="select-img" /></p> */}
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.region_selected}</span></p>}
                            {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>Please Select Region</span></p>}
                            {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>请选择一个地区</span></p>}
                            <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                          </div>
                        </div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                          {this.state.regions.map((e, i) => {
                            return (
                              <div key={i} className="dropdown-item" onClick={() => { this.region_selected_submit(e) }}><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="single-widget-search-input-title">{this.props.t('Province')}</div>
                    <div className="single-widget-search-input">
                      <div className="dropdown" >
                        <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.province_selected}</span></p>}
                            {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>Please Select Province</span></p>}
                            {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>请选择一个省</span></p>}
                            <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                          </div>
                        </div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                          {this.state.provinces.map((e, i) => {
                            return (
                              <div key={i} className="dropdown-item" onClick={() => { this.province_selected_submit(e) }} ><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <Link className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-4 order-lg-12">
                <div style={{ justifyContent: 'center', alignSelf: 'center' }}>
                  <Lottie options={defaultOptions} height={300} width={300} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      let publicUrl = process.env.PUBLIC_URL + '/'
      // let imagealt = 'image'
      console.log(this.state.places_data)
      return <div className="tour-list-area pd-top-80 viaje-go-top">
        <Helmet>
          <title>{this.state.page_name}{(this.state.province_id !== 0 ? this.props.t(' ') + this.props.t('in') + this.props.t(' ') + this.state.province_selected : '')}</title>
          <meta name="description" content={this.props.t('Tourist Routes') + (this.state.province_id !== 0 ? this.props.t(' ') + this.props.t('in') + this.props.t(' ') + this.state.province_selected : '')} />
          <meta property="og:url" content={this.props.location} />
          <meta property="og:title" content="Thailand Tourism Directory - Digital Tourism" />
          <meta property="og:description" content={this.props.t('up-to-date, Trusted information source from government organizations') + " " +
            this.props.t('Complete, Accurate') + " " + this.props.t('New Standard of Thailand Tourism Information')} />
          <meta property="og:image" content="https://thailandtourismdirectory.go.th/assets/img/ttd-facebook-image.png" />
          <meta itemprop="name" content="Thailand Tourism Directory - Digital Tourism" />
          <meta itemprop="description" content={this.props.t('up-to-date, Trusted information source from government organizations') + " " +
            this.props.t('Complete, Accurate') + " " + this.props.t('New Standard of Thailand Tourism Information')} />
          <meta itemprop="image" content="https://thailandtourismdirectory.go.th/assets/img/ttd-facebook-image.png" />
        </Helmet>
        <div className="container">
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1" id="all-order">
              <div className="row" style={{ marginBottom: 20 }}>
                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                  {this.state.province_id === 0 ?
                    <div className="new-breadcrumb">
                      <BreadcrumbItem><a hrefLang={this.state.lang} href={"/"} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                      <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path} className="normal-text cl-black">{this.state.page_name}</a></BreadcrumbItem>
                    </div>
                    :
                    <div className="new-breadcrumb">
                      <BreadcrumbItem><a hrefLang={this.state.lang} href={"/"} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                      <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path} className="normal-text cl-gray">{this.state.page_name}</a></BreadcrumbItem>
                      <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path + "?province_id=" + this.state.province_id} className="normal-text cl-black">{this.state.province_selected}</a></BreadcrumbItem>
                    </div>
                  }
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="row style-breadcrumb">
                    <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                    <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                      <div className="small-text viewpoint-list cl-blue">
                        <i className="fa fa-list-ul viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                        <span data-nosnippet>{this.props.t('list')}</span>
                      </div>
                    </div>
                    <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                      <div className="small-text viewpoint-list">
                        <i className="fa fa-map-o viewpoint-list-size" aria-hidden="true" ></i>
                        <span data-nosnippet>{this.props.t('Maps')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                { /* Search Box*/}
                <div className="col-xl-3 col-lg-4 order-lg-1">
                  <div className="sidebar-area sidebar-area-inner-page">
                    <div className="widget tour-list-widget">
                      <div className="single-widget-search-input-title">{this.props.t('Search for Tourist Routes')}</div>
                      <div className="widget-tour-list-search">
                        <div className="search-form">
                          <div className="form-group">
                            <input type="text" placeholder={this.props.t('Tourist Routes')} value={this.state.search} onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                this.search_submit()
                              }
                            }} onChange={(e) => { this.searchInput(e) }} />
                          </div>
                          <button className="submit-btn" type="button" onClick={() => { this.search_submit() }}><i className="ti-search" /></button>
                        </div>
                      </div>
                      <div className="single-widget-search-input-title">{this.props.t('Region')}</div>
                      <div className="single-widget-search-input">
                        <div className="dropdown">
                          <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.region_selected}</span></p>}
                              {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>Please Select Region</span></p>}
                              {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>请选择一个地区</span></p>}
                              <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                            </div>
                          </div>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                            {this.state.regions.map((e, i) => {
                              return (
                                <div key={i} className="dropdown-item" onClick={() => { this.region_selected_submit(e) }}><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="single-widget-search-input-title">{this.props.t('Province')}</div>
                      <div className="single-widget-search-input">
                        <div className="dropdown" >
                          <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.province_selected}</span></p>}
                              {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>Please Select Province</span></p>}
                              {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>请选择一个省</span></p>}
                              <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                            </div>
                          </div>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                            {this.state.provinces.map((e, i) => {
                              return (
                                <div key={i} className="dropdown-item" onClick={() => { this.province_selected_submit(e) }} ><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <Link className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 order-lg-12">
                  <div>
                    {this.state.places_data.length > 0 ?
                      <div className="row justify-content-center">
                        {this.state.places_data.map((e, i) => {
                          console.log(e)
                          let place_name = ""
                          if (e.Name[this.props.i18n.language] !== undefined && e.Name[this.props.i18n.language] !== "" && e.Name[this.props.i18n.language] !== null) {
                            place_name = e.Name[this.props.i18n.language]
                          }
                          else {
                            place_name = e.Name.th === undefined ? e.Name : e.Name.th
                          }
                          return (
                            <div key={i} className="col-xl-4 col-lg-6 col-sm-12" style={{ marginBottom: 30 }}>
                              <div className="single-destinations-list style-two animated slideInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
                                <Link hrefLang={this.state.lang} to={{ pathname: "/" + this.state.lang + "/route/" + e.ID, params: e.ID }}>
                                  <div className="thumb">
                                    <img style={{ height: 220, width: '100%' }} src={e.IntroImage} onError={(event) => event.target.setAttribute("src", "https://thailandtourismdirectory.go.th/assets/upload/icons/no-image.jpg")} alt={place_name} />
                                  </div>
                                </Link>
                                <div className="details" style={{ padding: 5, borderBottom: '1px solid #F0F0F0' }}>
                                  <p className="location" style={{ color: '#173865' }}><img src={publicUrl + "assets/img/icons/location.png"} alt="" />{e.Province[this.state.lang] === undefined ? e.Province : e.Province[this.state.lang]}</p>
                                  <p className="normal-text-18 weight-700 cl-black">{place_name.length > 40 ? place_name.slice(0, 36) + "..." : place_name}</p>
                                  {/* <p className="normal-text-18 weight-700 cl-black">{place_name.length > 25 ? place_name.slice(0, 25) + "..." : place_name}</p> */}
                                  <div className="row" style={{ margin: 2 }}>
                                    <div className="content">
                                      <p className="normal-text-14" style={{ color: 'gray' }}><Link hrefLang={this.state.lang} to={{ pathname: "/" + this.state.lang + "/route/" + e.ID, params: e.ID }}><span data-nosnippet>{this.props.t('Details')}</span></Link></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      :
                      <div>
                        <div className="col-xl-12 col-lg-12 order-lg-12">
                          <div style={{ justifyContent: 'center', alignSelf: 'center' }}>
                            <Lottie options={defaultOptions} height={300} width={300} />
                            <p className="normal-text-14" style={{ textAlign: 'center' }}>ไม่สามารถโหลดข้อมูลได้หรืออาจจะไม่มีข้อมูลนี้อยู่ในระบบ</p>
                            <p className="normal-text-14" style={{ textAlign: 'center' }}>กรุณาลองเปลี่ยนคำค้นหาเป็นคำอื่นๆ</p>
                          </div>
                        </div>
                      </div>
                    }
                    {this.state.places_data.length > 0 ?
                      <div className="col-lg-12 text-center">
                        <div className="tp-pagination text-center d-inline-block mt-4">
                          <ul>
                            {this.state.page_index === 1 ?
                              null
                              :
                              // <li onClick={() => { this.pagination_left() }}><a className="prev page-numbers" ><i className="la la-long-arrow-left" /></a></li>
                              <li>
                                <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + (this.state.page_index - 1)
                                  + ((this.state.search !== null) ? "&search=" + this.state.search : "")
                                  + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                  + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")} className="prev page-numbers" >
                                  <i className="la la-long-arrow-left" /></a>
                              </li>
                            }
                            {this.state.count_places.map((e, i) => {
                              if (i > this.state.pagination_min && i < this.state.pagination_max) {
                                if (e.onSelected === true) {
                                  return (
                                    <li>
                                      <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + e.page
                                        + ((this.state.search !== null) ? "&search=" + this.state.search : "")
                                        + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                        + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")}>
                                        <span className="page-numbers current">{e.page}</span></a>
                                    </li>
                                  )
                                }
                                else {
                                  return (
                                    <li>
                                      <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + e.page
                                        + ((this.state.search !== null) ? "&search=" + this.state.search : "")
                                        + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                        + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")}>
                                        <span className="page-numbers">{e.page}</span>
                                      </a></li>
                                  )
                                }
                              }
                              else {
                                return (
                                  null
                                )
                              }
                            })}
                            {this.state.count_places.length <= 5 ?
                              null
                              :
                              // <li onClick={() => { this.pagination_right() }}><a className="next page-numbers" ><i className="la la-long-arrow-right" /></a></li>
                              <li><a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + (this.state.page_index + 1)
                                + ((this.state.search !== null) ? "&search=" + this.state.search : "")
                                + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")} className="prev page-numbers" >
                                <i className="la la-long-arrow-right" /></a>
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2" id="processing">
              <div className="row" style={{ marginBottom: 20 }}>
                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                  {this.state.province_id === 0 ?
                    <div className="new-breadcrumb">
                      <BreadcrumbItem><a hrefLang={this.state.lang} href={"/"} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                      <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path} className="normal-text cl-black">{this.state.page_name}</a></BreadcrumbItem>
                    </div>
                    :
                    <div className="new-breadcrumb">
                      <BreadcrumbItem><a hrefLang={this.state.lang} href={"/"} className="normal-text cl-gray">{this.props.t('Home')}</a></BreadcrumbItem>
                      <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path} className="normal-text cl-gray">{this.state.page_name}</a></BreadcrumbItem>
                      <BreadcrumbItem active><a hrefLang={this.state.lang} href={"/" + this.state.lang + "/" + this.state.path + "?province_id=" + this.state.province_id} className="normal-text cl-black">{this.state.province_selected}</a></BreadcrumbItem>
                    </div>
                  }
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="row style-breadcrumb">
                    <div className="normal-text cl-gray viewpoint-mg ">{this.props.t('Viewpoint')}</div>
                    <div className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} style={{ cursor: 'pointer', marginRight: 25 }}>
                      <div className="small-text viewpoint-list">
                        <i className="fa fa-list-ul viewpoint-list-size " aria-hidden="true" ></i>
                        <span data-nosnippet>{this.props.t('list')}</span>
                      </div>
                    </div>
                    <div className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} style={{ cursor: 'pointer' }}>
                      <div className="small-text viewpoint-list cl-blue">
                        <i className="fa fa-map-o viewpoint-list-size cl-blue" aria-hidden="true" ></i>
                        <span data-nosnippet>{this.props.t('Maps')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                { /* Search Box*/}
                <div className="col-xl-3 col-lg-4 order-lg-1">
                  <div className="sidebar-area sidebar-area-inner-page">
                    <div className="widget tour-list-widget">
                      <div className="single-widget-search-input-title">{this.props.t('Search for Tourist Routes')}</div>
                      <div className="widget-tour-list-search">
                        <div className="search-form">
                          <div className="form-group">
                            <input type="text" placeholder={this.props.t('Tourist Routes')} value={this.state.search} onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                this.search_submit()
                              }
                            }} onChange={(e) => { this.searchInput(e) }} />
                          </div>
                          <button className="submit-btn" type="button" onClick={() => { this.search_submit() }}><i className="ti-search" /></button>
                        </div>
                      </div>
                      <div className="single-widget-search-input-title">{this.props.t('Region')}</div>
                      <div className="single-widget-search-input">
                        <div className="dropdown">
                          <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.region_selected}</span></p>}
                              {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>Please Select Region</span></p>}
                              {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>请选择一个地区</span></p>}
                              <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                            </div>
                          </div>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                            {this.state.regions.map((e, i) => {
                              return (
                                <div key={i} className="dropdown-item" onClick={() => { this.region_selected_submit(e) }}><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="single-widget-search-input-title">{this.props.t('Province')}</div>
                      <div className="single-widget-search-input">
                        <div className="dropdown" >
                          <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="font-style-dropdown">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              {this.state.lang === 'th' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>{this.state.province_selected}</span></p>}
                              {this.state.lang === 'en' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>Please Select Province</span></p>}
                              {this.state.lang === 'zh' && <p className="cl-gray normal-text line-opacity" ><span data-nosnippet>请选择一个省</span></p>}
                              <img src={drop} className="cl-gray normal-text drop-img" alt="" />
                            </div>
                          </div>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'scroll', maxHeight: '200px', minWidth: '100%' }}>
                            {this.state.provinces.map((e, i) => {
                              return (
                                <div key={i} className="dropdown-item" onClick={() => { this.province_selected_submit(e) }} ><span data-nosnippet>{e.Details[this.state.lang] === undefined ? e.Details[Object.keys(e.Details)[0]].Name : e.Details[this.state.lang].Name}</span></div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <Link className="btn btn-gray" onClick={() => { window.location.href = window.location.pathname }}><span>{this.props.t('Clear')}<i className="la la-times" /></span></Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-9 col-lg-8 order-lg-12">
                  <div className="row">
                    <div id="gmaps-markers" className="gmaps" style={{ height: 750, width: '100%' }} >
                      <Map
                        google={this.props.google}
                        zoom={6}
                        containerStyle={containerStyle}
                        initialCenter={
                          {
                            // lat: 10.730483283967,
                            // lng: 99.322113990784
                            lat: 13.75,
                            lng: 100.517
                          }
                        }
                      >
                        {this.state.places_data.map((e, i) => {
                          let place_name = ""
                          // if (e.Name[this.props.i18n.language] !== undefined) {
                          //   place_name = e.Name[this.props.i18n.language]
                          // }
                          if (e.Name[this.props.i18n.language] !== undefined && e.Name[this.props.i18n.language] !== "" && e.Name[this.props.i18n.language] !== null) {
                            place_name = e.Name[this.props.i18n.language]
                          }
                          else {
                            place_name = e.Name.th === undefined ? e.Name : e.Name.th
                          }
                          return (
                            <Marker key={i}
                              onClick={this.onMarkerClick}
                              image={e.IntroImage}
                              name={place_name.length > 15 ? place_name.slice(0, 15) + "..." : place_name}
                              province={e.Province[this.state.lang]}
                              position={{ lat: e.Route[0].Latitude, lng: e.Route[0].Longitude }}
                              time={e.IsOpen === 1 ?
                                <div className="normal-text-14" style={{ Color: '#339933' }}><span data-nosnippet>{this.props.t('Open')}</span></div>
                                :
                                <div className="normal-text-14" style={{ Color: 'gray' }}><span data-nosnippet>{this.props.t('Closed')}</span></div>
                              }
                              detail={this.renderMarker(e)}
                              icon={publicUrl + "assets/img/icons/marker-style.png"} />

                          )
                        })}
                        <InfoWindow
                          marker={this.state.activeMarker}
                          visible={this.state.showingInfoWindow}
                          onClose={this.onClose}
                        >
                          <div className="row" style={{ marginLeft: 0 }}>
                            <img style={{ height: 75, width: 75, borderRadius: 7, objectFit: 'cover' }} src={this.state.selectedPlace.image} alt="" />
                            <div className="row" style={{ display: 'inline-block', marginLeft: 10 }}>
                              <p className="normal-text-18 cl-black" style={{ lineHeight: '19px' }}>{this.state.selectedPlace.name}</p>
                              <p className="normal-text-14" style={{ color: '#173865' }}><img style={{ marginRight: 8 }} src={publicUrl + "assets/img/icons/location.png"} alt="" />{this.state.selectedPlace.province}</p>
                              <p>{this.state.selectedPlace.time}</p>
                            </div>
                          </div>
                          <div className="row">
                            <p>{this.state.selectedPlace.detail}</p>
                          </div>
                        </InfoWindow>
                      </Map>
                    </div>
                    {this.state.places_data.length > 0 ?
                      <div className="col-lg-12 text-center">
                        <div className="tp-pagination text-center d-inline-block mt-4">
                          <ul>
                            {this.state.page_index === 1 ?
                              null
                              :
                              // <li onClick={() => { this.pagination_left() }}><a className="prev page-numbers" ><i className="la la-long-arrow-left" /></a></li>
                              <li>
                                <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + (this.state.page_index - 1)
                                  + ((this.state.search !== null) ? "&search=" + this.state.search : "")
                                  + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                  + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")} className="prev page-numbers" >
                                  <i className="la la-long-arrow-left" /></a>
                              </li>
                            }
                            {this.state.count_places.map((e, i) => {
                              if (i > this.state.pagination_min && i < this.state.pagination_max) {
                                if (e.onSelected === true) {
                                  return (
                                    <li>
                                      <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + e.page
                                        + ((this.state.search !== null) ? "&search=" + this.state.search : "")
                                        + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                        + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")}>
                                        <span className="page-numbers current">{e.page}</span></a>
                                    </li>
                                  )
                                }
                                else {
                                  return (
                                    <li>
                                      <a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + e.page
                                        + ((this.state.search !== null) ? "&search=" + this.state.search : "")
                                        + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                        + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")}>
                                        <span className="page-numbers">{e.page}</span>
                                      </a></li>
                                  )
                                }
                              }
                              else {
                                return (
                                  null
                                )
                              }
                            })}
                            {this.state.count_places.length <= 5 ?
                              null
                              :
                              // <li onClick={() => { this.pagination_right() }}><a className="next page-numbers" ><i className="la la-long-arrow-right" /></a></li>
                              <li><a hrefLang={this.state.lang} href={window.location.pathname + "?page=" + (this.state.page_index + 1)
                                + ((this.state.search !== null) ? "&search=" + this.state.search : "")
                                + ((this.state.region_id !== 0) ? "&region_id=" + this.state.region_id : "")
                                + ((this.state.province_id !== 0) ? "&province_id=" + this.state.province_id : "")} className="prev page-numbers" >
                                <i className="la la-long-arrow-right" /></a>
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                      :
                      null
                    }
                  </div>
                </div> */}
              </div>
            </TabPane>
          </TabContent >
        </div>
      </div>
    }
  }
}
export default (withTranslation()(TravelRoute));
