import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class News extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}

	render() {
		return <div className="holiday-plan-area tp-holiday-plan-area mg-top-96">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-9 col-lg-9">
						<div className="section-title text-center">
							<h2 className="title wow animated fadeInUp large-text-48 cl-black" data-wow-duration="0.6s" data-wow-delay="0.1s">{this.props.t('News and events')}</h2>
							<p className="wow animated fadeInUp large-text light-gray" data-wow-duration="0.6s" data-wow-delay="0.1s">{this.props.t('Public relations news for tourist attractions throughout Thailand')}</p>
						</div>
					</div>
				</div>
				{this.props.news.length > 0 ?
					<div className="row justify-content-center">
						{this.props.news.map((e, i) => {
							let news_name = ""
							if (e.Title[this.props.lang] !== undefined && e.Title[this.props.lang] !== "" && e.Title[this.props.lang] !== null) {
								news_name = e.Title[this.props.lang]
							}
							else {
								news_name = e.Title.th === undefined ? e.Title : e.Title.th
							}
							if (i < 4) {
								return (
									<div key={i} className="col-lg-3 col-sm-6">
										<div className="single-destinations-list style-two animated slideInUp border-solid" data-wow-duration="0.4s" data-wow-delay="0.1s">
											<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/new/" + e.ID, params: e.ID }}>
												<div className="thumb">
													<img style={{ height: 220, width: '100%' }} src={e.IntroImage} alt={news_name} />
												</div>
											</Link>
											<div className="details" style={{ padding: 5, borderBottom: '1px solid #F0F0F0' }}>
												<p className="normal-text-18 weight-700 cl-black" style={{ marginTop: '1rem' }}>{news_name.length > 40 ? news_name.slice(0, 36) + "..." : news_name}</p>
												<div className="row" style={{ justifyContent: 'flex-end', margin: 2 }}>
													<div className="content">
														<p className="normal-text-14" style={{ color: 'gray' }}>
															<Link hrefLang={this.props.i18n.language} to={{ pathname: "/" + this.props.i18n.language + "/new/" + e.ID, params: e.ID }}><span data-nosnippet>{this.props.t('Details')}</span></Link>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							}
							else {
								return null
							}

						})}
					</div>
					:
					<div>
						ไม่พบข้อมูล
					</div>
				}
				<div className="row" style={{ justifyContent: 'center' }}>
					<Link hrefLang={this.props.i18n.language} className="btn btn-gray " to={"/" + this.props.i18n.language + "/news"} style={{ fontSize: 24, marginTop: 10, borderRadius: 39 }} target="_blank" rel="noopener noreferrer">{this.props.t('View more')}</Link>
				</div>

			</div>
		</div>
	}
}

export default withTranslation()(News);